import React, { useEffect } from 'react';
import './Regulations.scss';

const Regulations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="regulations">
      <h1 className="center">Regulament soluții colaborative pentru comunitate</h1>
      <h2>Despre Soluții colaborative pentru comunitate</h2>
      <p>
        Soluții colaborative pentru comunitate este un proiect prin care prioritizăm, documentăm și rezolvăm cele mai
        apăsătoare probleme ale orașului punând împreună cunoaștere și resurse din comunitate
      </p>
      <div>
        Vom considera proiectul pilot o reușită dacă:
        <ul>
          <li>
            minimum 2000 cetățeni se implică pentru: semnalare probleme, prioritizare probleme (vot), interviuri,
            dezvoltare soluții, feedback soluții, monitorizare și implementare soluții;
          </li>
          <li>minimum 500 probleme sunt înregistrate pe platformă;</li>
          <li>
            realizăm minimum 150 interviuri cu cetățeni, cu funcționari publici și specialiști pentru documentarea
            problemelor prioritizate de comunitate (aproximativ 20 interviuri/problemă);
          </li>
          <li>
            minimum 20 parteneri se implică activ în implementarea proiectului (pentru promovare, expertiză, organizații
            mentor, sponsori etc);
          </li>
          <li>minimum 70 specialiști se implică în dezvoltarea soluțiilor colaborative (hackathon);</li>
          <li>identificăm 7 probleme relevante pentru comunitate;</li>
          <li>
            dezvoltăm 7 soluții inovatoare, centrate pe cetățeni alături de 7 echipe multidisciplinare și 7 organizații
            mentor;
          </li>
          <li>
            testăm, învățăm și conturăm un mecanism coerent și eficient de participare publică și co-creare de soluții
            la probleme publice.
          </li>
        </ul>
      </div>
      <p>
        Proiectul este implementat de Asociația CIVICA, alături de parteneri din mediul academic, de business și ONG, în
        co-finanțat Primăria Municipiului Iași și Open Society Foundations.
      </p>
      <h2>Cine poate fi implicat?</h2>
      <p>
        Se pot implica în Proiectul Soluții colaborative pentru comunitate cetățenii care au domiciliul sau reședința în
        Municipiul Iași sau în zona metropolitană și au împlinit vârsta de 18 ani. Ca utilizator poți invita, prin
        intermediul rețelelor sociale, și alte persoane să viziteze solutiicolaborative.ro și să ne fie aproape pe
        parcursul întregului proces.
      </p>
      <h2>Condiții de aderare la comunitatea proiectului, drepturi și obligații ale utilizatorului </h2>
      <p>
        Platforma solutiicolaborative.ro poate fi accesată în mod gratuit, iar implicarea activă în etapele proiectului
        este condiționată de crearea unui cont de utilizator. Crearea contului se face în baza autentificării cu adresa
        de e-mail sau contul de Facebook. Solicitarea pentru crearea unui cont nou va primi răspuns în termen de maximum
        24 ore.
      </p>
      <p>
        Autentificarea pe platformă vă permite: înscrierea uneia sau mai multor probleme, votarea problemelor înscrise,
        transmiterea de observații cu privire la problemele înscrise de ceilalți utilizatori și, ulterior, transmiterea
        de observații cu privire la soluțiile propuse.
      </p>
      <p>
        Pentru mai multe detalii despre condițiile de acceptare ca utilizator și obligații, te invităm să parcurgi
        Termenii și condițiile de utilizare a platformei. Pentru a ști care sunt datele cu caracter personal pe care le
        colectăm utilizatorilor platformei, consultă Politica de prelucrare a datelor cu caracter personal.
      </p>
      <h2>Calendarul proiectului</h2>
      <p>
        Proiectul presupune parcurgerea a 7 etape dintre care primele 5 se desfășoară în baza unui calendar prestabilit,
        în perioada august 2020 - iulie 2021, iar ultimele două etape vor avea un calendar distinct, definit după
        dezvoltarea soluțiilor colaborative.
      </p>
      <h2>Domeniile de înscriere a problemelor</h2>
      <div>
        Înscrierea problemelor se desfășoară în perioada noiembrie 2020 - ianuarie 2021, în mediul online, pe platforma
        solutiicolaborative.ro; Utilizatorii platformei solutiicolaborative.ro pot înscrie probleme aferente unuia sau
        mai multor domenii dintre următoarele:
        <ul>
          <li>spații publice (trotuare, zone pietonale, spații verzi, locuri de joacă și de recreere);</li>
          <li>mobilitate, accesibilitate şi siguranţa circulaţiei;</li>
          <li>educație;</li>
          <li>cultură;</li>
          <li>sănătate și mediu;</li>
          <li>bună guvernare și digitalizare;</li>
          <li>ordine si siguranță publică.</li>
        </ul>
        Utilizatorii pot alege să înscrie una sau mai multe probleme per domeniu.
      </div>
      <p>
        O variantă tipărită a formularului de înscriere a problemelor va fi disponibilă în Centrele de Cartier și în
        alte spații partenere, care vor fi comunicate publicului larg. Formularele tipărite sunt adresate cetățenilor
        care nu pot avea acces la platforma online sau au dificultăți în a o accesa.
      </p>
      <p>
        Formularele tipărite vor fi colectate în urne și, ulterior colectării acestora, problemele pe care le cuprind
        vor fi și introduse în format electronic pe platformă. Astfel și problemele completate în format analog vor
        intra în etapa de vot electronic pe platforma solutiicolaborative.ro
      </p>
      <h2>Criterii de eligibilitate a problemelor</h2>
      <div>
        Sunt potrivite pentru proiectul Soluții colaborative pentru comunitate problemele care respectă următoarele
        criterii:
        <ul>
          <li>sunt complexe, au nevoie de o înțelegere profundă a nevoilor și dorințelor cetățenilor;</li>
          <li>au impact la nivelul unei comunități extinse (peste 100 de persoane);</li>
          <li>
            implică multă incertitudine și necunoscute, iar informațiile cunoscute din trecut nu ne ajută pentru o
            înțelegere mai bună;
          </li>
          <li>au multe fațete și sunt interconectate;</li>
          <li>
            intră în aria de responsabilitate a Primăriei Municipiului Iași, iar soluționarea lor încurajează
            colaborarea autorităților locale cu alte entități interesate de soluționarea problemei.
          </li>
        </ul>
        Aceste condiții au rolul de criterii obiective în etapa votului public și vor sta la baza grilei de evaluare a
        comisiei tehnice.
      </div>
      <p>
        ! În cazul în care o problemă semnalată nu îndeplinește criteriile de mai sus, dar reprezintă, totuși, o
        problemă reală a unor persoane sau comunități, va fi transmisă Primăriei Iași, pentru a se căuta rezolvarea ei
        prin alte metode.
      </p>
      <h3> Recomandări pentru formularea problemelor</h3>
      <div>
        <ul>
          <li>expune-ți problema cu claritate și concizie!;</li>
          <li>
            în măsura în care ai acces la informații, verifică corectitudinea și actualitatea datelor pe care ni le
            transmiți prin formularul de înscriere a problemei;
          </li>
          <li>
            include în expunerea problemei detalii tehnice, în situația în care acestea conduc la o înțelegere mai bună
            a situației prezentate.
          </li>
        </ul>
      </div>
      <h3>Număr maxim de probleme înscrise</h3>
      <p>
        Utilizatorii pot înscrie oricâte propuneri de probleme, cât timp acestea ilustrează sau fac referire la o
        situație reală, cu efecte negative asupra calității vieții ieșenilor.
      </p>
      <p>
        Utilizatorii care propun o problemă care se regăsește deja pe platformă, vor fi înștiințați și rugați să revină
        cu alte propuneri.
      </p>
      <h3>Condiții de aprobare a problemelor pe platformă</h3>
      <div>
        Problemele înscrise vor trece de un prim filtru de verificare a respectării condițiilor de adecvare a formulării
        problemei, prin observarea următoarelor aspecte:
        <ul>
          <li>
            nu vor fi aprobate probleme în formularea cărora se regăsesc injurii, care descriu orice formă de violență
            sau cruzime, care constituie o încălcare a legii sau prezintă o modalitate prin care aceasta ar putea fi
            încălcată, care instigă la rasism, sexism, homofobie, discriminare etnică sau socială, care pot aduce
            prejudicii unei persoane și/sau încalcă dreptul la imagine al unei persoane, care conțin publicitate sau
            materiale promoționale ale unor terți.
          </li>
          <li>nu vor fi aprobate probleme care conțin anunțuri sau mesaje publicitare;</li>
          <li>nu vor fi aprobate pe platformă răspunsuri lipsite de claritate și concizie în prezentarea problemei.</li>
        </ul>
        Propunerile sunt acceptate sau respinse de echipa solutiicolaborative.ro în cel mult 48 ore de la data
        depunerii.
      </div>
      <h2>Prioritizarea problemelor prin vot public</h2>
      <div>
        <ul>
          <li>
            utilizatorii platformei vor prioritiza problemele înscrise pe platformă, prin vot public, pe parcursul lunii
            februarie 2021;
          </li>
          <li>
            orice ieșean care are un cont creat pe platforma proiectului va putea vota maxim 7 probleme, câte o problemă
            per domeniu;
          </li>
          <li>
            top 10 cele mai votate probleme, pentru fiecare dintre cele 7 domenii, obțin 50 de puncte (50% din punctajul
            final) și vor trece în etapa analizei tehnice;
          </li>
          <li>
            problemele semnalate pe platformă care nu vor fi selectate pentru soluționarea prin design thinking vor fi
            transmise Primăriei Iași pentru analiză și rezolvare.
          </li>
        </ul>
        ! Dacă sunt înscrise probleme diferite, dar care fac referire la aceeași nevoie, numărul de voturi strânse
        individual de utilizatorii care au o nevoie comună vor fi cumulate.
      </div>
      <h2>Evaluarea tehnică a problemelor</h2>
      <div>
        <ul>
          <li>
            etapa evaluării tehnice are loc în luna martie 2021 și este realizată de către o comisie tehnică alcătuită
            din specialiști în design thinking, membrii echipei de proiect ai Asociației CIVICA și specialiști din cele
            7 domenii, inclusiv membri ai comisiilor de specialitate ale Primăriei Iași;
          </li>
          <li>
            comisia tehnică va oferi un punctaj de maximum 50 de puncte (50% din punctajul final) fiecărei probleme în
            baza criteriilor mai sus menționate;
          </li>
          <li>
            punctajul final rezultă prin adunarea celor 50 de puncte oferite de votul public cu punctajul obținut în
            urma evaluării tehnice;
          </li>
          <li>
            top 7 probleme, prima clasată pe fiecare dintre cele 7 domenii, vor intra în etapa de soluționare
            colaborativă prin design thinking.
          </li>
        </ul>
      </div>
      <h2>Dezvoltarea soluțiilor colaborative</h2>
      <div>
        <ul>
          <li>
            etapa de dezvoltare a soluțiilor colaborative va avea loc în perioada martie - mai 2021 și va fi realizată
            prin aplicarea principiilor design thinking;
          </li>
          <li>
            fiecare dintre cele 7 probleme prioritare va avea o echipă multidisciplinară implicată direct în dezvoltarea
            soluțiilor, alcătuită din cetățeni și specialiști din aria problemei;
          </li>
          <li>selecția cetățenilor membri ai echipelor multidisciplinare va fi realizată în baza unui apel public; </li>
          <li>
            etapa de dezvoltare a soluțiilor cuprinde două etape distincte
            <ul>
              <li>documentarea problemei, în intervalul martie - aprilie 2021;</li>
              <li>
                dezvoltarea soluțiilor propriu-zise va avea loc pe parcursul a două zile în luna mai 2021, în cadrul
                unui eveniment de tip hackathon unde echipele multidisciplinare, alături de facilitatorii în design
                thinking vor:
                <ul>
                  <li>defini cât mai bine problemele pentru care dezvoltăm soluții;</li>
                  <li>explora cât mai multe idei de soluții;</li>
                  <li>dezvolta prototipuri pentru soluțiile alese;</li>
                  <li>testa soluțiile și integrăm recomandările de îmbunătățire (iunie 2021).</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            echipa multidisciplinară are în alcătuirea sa și reprezentanți ai unei organizații mentor care va fi
            implicată activ în coordonarea activităților din etapa de documentare a problemei.
          </li>
        </ul>
      </div>
      <h2>Consultarea comunității cu privire la soluțiile dezvoltate </h2>
      <div>
        <ul>
          <li>
            în iunie 2021, cele 7 soluții și prototipurile acestora vor fi publicate pe platforma proiectului și orice
            ieșean va putea transmite observații cu privire la acestea;
          </li>
          <li>
            în baza obervațiilor primite, soluțiile vor fi îmbunătățite și testate a doua oară prin implicarea echipelor
            multidisciplinare;
          </li>
          <li>
            soluțiile finale (cu prototipuri testate de cel puțin două ori) și planurile de acțiune ale acestora vor fi
            transmise Primăriei Iași și organizațiilor posibil interesate de implementarea acestora.
          </li>
        </ul>
      </div>
      <h2>Implementarea soluțiilor </h2>
      <div>
        <ul>
          <li>
            implementarea soluțiilor va începe în iulie 2021 și, precum procesul de găsire a soluției, va avea la bază o
            responsabilitate comună;
          </li>
          <li>
            împreună cu Primăria Municipiului Iași și organizațiile interesate de implementare, definim un calendar
            realist de punere în practică, resursele necesare și propunem realizarea de proiecte de hotărâre de Consiliu
            Local, unde va fi necesar;
          </li>
          <li>
            alături de echipele multidisciplinare și organizațiile mentor vom rămâne în dialog constant cu Primăria Iași
            și organizațiile implicate în implementare pentru a contribui la buna punere în practică a soluțiilor și
            depășirea posibilelor blocaje;
          </li>
        </ul>
      </div>
      <h2>Monitorizarea și evaluarea implementării soluțiilor</h2>
      <div>
        <ul>
          <li>
            monitorizarea și evaluarea implementării soluțiilor va fi realizată de echipa CIVICA, alături de echipele
            multidisciplinare, organizațiile mentor, Consiliul Consultativ, dar și ieșenii interesați să fie implicați
            în această etapă;
          </li>
          <li>
            Echipa CIVICA va comunica constant către publicul larg și partenerii de implementare informații privind
            progresul implementării celor 7 soluții;
          </li>
          <li>
            informațiile vor fi periodic actualizate pe platforma online solutiicolaborative.ro, iar abonații la
            newsletterul CIVICA vor primi noutăți și pe e-mail.
          </li>
        </ul>
      </div>
      <h2>Contact</h2>
      <p>
        Mai multe detalii privind proiectul Soluții colaborative pentru comunitate puteți obține pe pagina web
        www.solutiicolaborative.ro, respectiv la adresa de mail contact@asociatiacivica.ro.
      </p>
    </div>
  );
};

export default Regulations;
