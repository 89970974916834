import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { isUserLoggedIn } from '../../../components/User/UserUtils';
import { getProblemDetailsVote } from '../../../models/actions/problemDetailsVote';

import './ProblemCategoriesVote.scss';
import {
  SpatiiPubliceLogo,
  MobilitateLogo,
  EducatieLogo,
  SanatateLogo,
  GuvernareLogo,
  OrdineSiSigurantaLogo,
  CulturaLogo,
} from '../../../assets/images/images';

class ProblemCategoriesVote extends Component {
  constructor() {
    super();
    this.state = {
      categoryClickedTagId: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.getProblemDetailsVote();
  }

  componentDidUpdate(nextProps) {}

  redirectToProblemDetailsVotePage(category) {
    this.props.history.push(`/vote/${category}`);
  }

  render() {
    const isUserAuthorized = isUserLoggedIn();
    const { voteStatsPerCategory } = this.props.problemDetailsVote;

    return (
      <>
        <div className="force-push-menu-space"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 center"></div>
            <div className="col-md-8 offset-md-2 center my-5 col-sm-12 offset-sm-0">
              <h1>
                <b>Votează pentru fiecare domeniu</b>
              </h1>
              <br />
              {isUserAuthorized ? (
                <p>
                  Votul se desfășoară în perioada 22 februarie - 15 martie 2021. Pentru a putea vota, e nevoie să te
                  loghezi sau să-ți creezi un cont, dacă nu ai deja unul. Nu uita, ai doar un (1) vot pentru fiecare
                  domeniu!
                </p>
              ) : (
                <p>
                  Votul se desfășoară în perioada 22 februarie - 15 martie 2021. Pentru a putea vota, e nevoie să te
                  <NavLink to="/login"> loghezi</NavLink> sau{' '}
                  <NavLink to="/create-account"> să-ți creezi un cont</NavLink>, dacă nu ai deja unul. Nu uita, ai doar
                  un (1) vot pentru fiecare domeniu!
                </p>
              )}
            </div>
          </div>
          <div className="row my-5 problem-cards">
            {voteStatsPerCategory &&
              voteStatsPerCategory.map((category, i) => (
                <div
                  className={`${
                    voteStatsPerCategory.length - 1 !== i
                      ? 'col-md-4 col-sm-12 selectable-card mb-5 p-4'
                      : 'offset-md-4 col-md-4 col-sm-12 selectable-card mb-5 px-5 mb-5'
                  } ${category.hasVotedInCategory && 'disabled'}`}
                  onClick={() =>
                    this.redirectToProblemDetailsVotePage(
                      category.problemTagId === 1
                        ? 'spatii-publice'
                        : category.problemTagId === 2
                        ? 'mobilitate-accesibilitate-si-siguranta-circulatiei'
                        : category.problemTagId === 3
                        ? 'educatie'
                        : category.problemTagId === 4
                        ? 'sanatate-si-mediu'
                        : category.problemTagId === 5
                        ? 'buna-guvernare-si-digitalizare'
                        : category.problemTagId === 6
                        ? 'ordine-si-siguranta-publica'
                        : 'cultura',
                    )
                  }
                  key={category.problemTagId}
                >
                  <div className="left-side float-left">
                    <p>
                      <span>
                        <b>{category.problemsToVoteCount}</b>
                      </span>{' '}
                      teme pentru vot
                    </p>
                    <p className="font-s-13 smaller-font">
                      <span>
                        <b>{category.voteCount}</b>
                      </span>{' '}
                      voturi deja exprimate
                    </p>
                    {category.hasVotedInCategory && <p className="already-voted">&#x2713; Ai votat deja. Mulțumim!</p>}
                    {category.problemTagId === 1 && (
                      <p
                        className={`tag spatii ${
                          category.problemTagId === 1 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Spații publice
                      </p>
                    )}
                    {category.problemTagId === 2 && (
                      <p
                        className={`tag mobilitate ${
                          category.problemTagId === 2 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Mobilitate, accesibilitate și siguranța circulației
                      </p>
                    )}
                    {category.problemTagId === 3 && (
                      <p
                        className={`tag educatie ${
                          category.problemTagId === 3 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Educație
                      </p>
                    )}
                    {category.problemTagId === 4 && (
                      <p
                        className={`tag sanatate ${
                          category.problemTagId === 4 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Sănătate și mediu
                      </p>
                    )}
                    {category.problemTagId === 5 && (
                      <p
                        className={`tag guvernare ${
                          category.problemTagId === 5 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Bună guvernare și digitalizare
                      </p>
                    )}
                    {category.problemTagId === 6 && (
                      <p
                        className={`tag ordine ${
                          category.problemTagId === 6 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Ordine și siguranță publică
                      </p>
                    )}
                    {category.problemTagId === 7 && (
                      <p
                        className={`tag cultura ${
                          category.problemTagId === 7 && !category.hasVotedInCategory && 'mt-3'
                        }`}
                      >
                        Cultură
                      </p>
                    )}
                  </div>
                  <div className="right-side">
                    {category.problemTagId === 1 ? (
                      <SpatiiPubliceLogo />
                    ) : category.problemTagId === 2 ? (
                      <MobilitateLogo />
                    ) : category.problemTagId === 3 ? (
                      <EducatieLogo />
                    ) : category.problemTagId === 4 ? (
                      <SanatateLogo />
                    ) : category.problemTagId === 5 ? (
                      <GuvernareLogo />
                    ) : category.problemTagId === 6 ? (
                      <OrdineSiSigurantaLogo />
                    ) : (
                      <CulturaLogo />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPending: state.problemDetailsVote.isPending,
    problemDetailsVote: state.problemDetailsVote.problemDetailsVote,
    error: state.problemDetailsVote.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProblemDetailsVote: () => dispatch(getProblemDetailsVote()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemCategoriesVote);
