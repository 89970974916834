import React from 'react';
import { connect } from 'react-redux';
import { FacebookLogo } from '../../../assets/images/images';
import { onGoogleLoginAction } from '../../../models/actions/userActions';
import GoogleAuth from '../../User/Login/GoogleAuth';
import './SocialLogin.scss';

const SocialLogin = (props) => {
  const { onGoogleLogin } = props;
  return (
    <>
      <div className="alternative">
        <div className="separator"></div>
        <div className="text">sau</div>
      </div>
      <div className="form-row social">
        <ul>
          <li>
            <button type="button" className="fb-login">
              <FacebookLogo />
              <span>Continuă cu Facebook</span>
            </button>
          </li>
          <li>
            <GoogleAuth onLogin={onGoogleLogin} />
          </li>
        </ul>
      </div>
    </>
  );
};
export default connect(null, (dispatch) => ({
  onGoogleLogin: () => dispatch(onGoogleLoginAction()),
}))(SocialLogin);
