import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  approveProblemAction,
  getProblemDetailsAction,
  rejectProblemAction,
  getUserDetails,
} from '../../../models/actions/admin';
import {
  currentProblemDetails,
  getIsProblemDetailsLoading,
  getUserDetailsSelector,
} from '../../../models/selectors/admin';
import { config } from '../../../models/api/apiConfig';
import './ProblemInfo.scss';

class ProblemInfo extends Component {
  constructor(props) {
    super(props);
    this.problemID = this.props.location.pathname.split('/')[3];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getPoblemDetails(this.problemID);
  }

  componentDidUpdate(prevProps) {
    const { problemDetails } = this.props;
    const { proposerId } = problemDetails;
    if (prevProps.problemDetails !== this.props.problemDetails) {
      this.props.getUserDetails(proposerId);
    }
  }

  updateProblem(problemStatus) {
    const { approveProblem, rejectProblem } = this.props;
    if (problemStatus === 'approve') {
      approveProblem(this.problemID);
    } else {
      rejectProblem(this.problemID);
    }

    this.props.getPoblemDetails(this.problemID);
  }

  render() {
    const { problemDetails, isProblemDetailsLoading, userDetails } = this.props;
    const { title, description, creationDate, proposerId, problemStatus, resources } = problemDetails;

    return (
      !isProblemDetailsLoading && (
        <>
          <table className="table table-lg table-striped problem-info">
            <thead>
              <tr>
                <th>Titlu</th>
                <th>Descriere</th>
                <th>Data adaugare</th>
                <th>Autor</th>
                <th>Status problema</th>
                <th>Imagine</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{title}</td>
                <td>{description}</td>
                <td>{creationDate}</td>
                <td>{proposerId}</td>
                <td>{problemStatus}</td>
                <td>{(resources.length && <img src={`${config.apiUrl}${resources[0].url}`} width="300" />) || ''}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="5">&nbsp;</td>
                <td>
                  <ul>
                    <li>
                      <button type="button" onClick={() => this.updateProblem('approve')}>
                        Aproba
                      </button>
                    </li>
                    <li>
                      <button type="button" onClick={() => this.updateProblem('rejected')}>
                        Respinge
                      </button>
                    </li>
                  </ul>
                </td>
              </tr>
            </tfoot>
          </table>
          <h3>Detalii utilizator</h3>
          <br />
          <p>
            <b>Email: </b>
            <span>{userDetails.email || ''}</span>
          </p>
          <p>
            <b>First Name: </b>
            <span>{userDetails.firstName || ''}</span>
          </p>
          <p>
            <b>Last Name: </b>
            <span>{userDetails.lastName || ''}</span>
          </p>
          <p>
            <b>Usename: </b>
            <span>{userDetails.username || '-'}</span>
          </p>
        </>
      )
    );
  }
}

export default connect(
  (state) => ({
    isProblemDetailsLoading: getIsProblemDetailsLoading(state),
    problemDetails: currentProblemDetails(state),
    userDetails: getUserDetailsSelector(state),
  }),
  (dispatch) => ({
    getPoblemDetails: (problemID) => dispatch(getProblemDetailsAction(problemID)),
    getUserDetails: (userId) => dispatch(getUserDetails(userId)),
    approveProblem: (problemID) => dispatch(approveProblemAction(problemID)),
    rejectProblem: (problemID) => dispatch(rejectProblemAction(problemID)),
  }),
)(ProblemInfo);
