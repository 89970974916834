import { api } from '../api/api';
import {
  GET_PROBLEM_DETAILS_VOTE_LOADING,
  GET_PROBLEM_DETAILS_VOTE_SUCCESS,
  GET_PROBLEM_DETAILS_VOTE_ERROR,
  GET_PROBLEM_TO_VOTE_LOADING,
  GET_PROBLEM_TO_VOTE_SUCCESS,
  GET_PROBLEM_TO_VOTE_ERROR,
  POST_VOTE_PROBLEM_LOADING,
  POST_VOTE_PROBLEM_SUCCESS,
  POST_VOTE_PROBLEM_ERROR,
} from '../constants';

export function getProblemDetailsVote() {
  return (dispatch) => {
    dispatch(getProblemDetailsVotePending(true));
    api
      .get(`voteStats`)
      .then((response) => {
        dispatch(getProblemDetailsVotePending(false));
        dispatch(getProblemDetailsVoteSuccess(response));
      })
      .catch((error) => {
        dispatch(getProblemDetailsVotePending(false));
        dispatch(getProblemDetailsVoteError(error));
      });
  };
}

export function getProblemsToVote() {
  return (dispatch) => {
    dispatch(getProblemsToVotePending(true));
    api
      .get(`problemsToVote`)
      .then((response) => {
        dispatch(getProblemsToVotePending(false));
        dispatch(getProblemsToVoteSuccess(response));
      })
      .catch((error) => {
        dispatch(getProblemsToVotePending(false));
        dispatch(getProblemsToVoteError(error));
      });
  };
}

export function voteCategory(categoryId) {
  return function (dispatch) {
    dispatch(voteProblemPending(true));
    api
      .post(`vote?problemToVoteId=${categoryId}`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          dispatch(voteProblemPending(false));
          dispatch(voteProblemSuccess(response));
        } else {
          dispatch(voteProblemPending(false));
          dispatch(voteProblemError('Error while trying to post vote!'));
        }
      })
      .catch((error) => {
        dispatch(voteProblemPending(false));
        dispatch(voteProblemError(error));
      });
  };
}

const getProblemDetailsVotePending = (status) => ({
  type: GET_PROBLEM_DETAILS_VOTE_LOADING,
  isPending: status,
});

const getProblemDetailsVoteSuccess = (response) => ({
  type: GET_PROBLEM_DETAILS_VOTE_SUCCESS,
  payload: response,
});

const getProblemDetailsVoteError = (error) => ({
  type: GET_PROBLEM_DETAILS_VOTE_ERROR,
  error,
});

const getProblemsToVotePending = (status) => ({
  type: GET_PROBLEM_TO_VOTE_LOADING,
  isPending: status,
});

const getProblemsToVoteSuccess = (response) => ({
  type: GET_PROBLEM_TO_VOTE_SUCCESS,
  payload: response,
});

const getProblemsToVoteError = (error) => ({
  type: GET_PROBLEM_TO_VOTE_ERROR,
  error,
});

const voteProblemPending = (status) => ({
  type: POST_VOTE_PROBLEM_LOADING,
  isPending: status,
});

const voteProblemSuccess = (response) => ({
  type: POST_VOTE_PROBLEM_SUCCESS,
  payload: response,
});

const voteProblemError = (error) => ({
  type: POST_VOTE_PROBLEM_ERROR,
  error,
});
