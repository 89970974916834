import img_about_us from './about/about-us-team.png';
import img_about_01 from './about/about_01.svg';
import img_about_02 from './about/about_02.jpg';
import img_about_03 from './about/about_03.png';

const aboutImages = {
  img_about_us,
  img_about_01,
  img_about_02,
  img_about_03,
};

export default aboutImages;
