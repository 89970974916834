import {
  GET_PROBLEM_DETAILS_LOADING,
  GET_PROBLEM_DETAILS_SUCCESS,
  GET_PROBLEM_DETAILS_ERROR,
  GET_COMMENTS_PROBLEM_DETAILS_LOADING,
  GET_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  GET_COMMENTS_PROBLEM_DETAILS_ERROR,
  LIKE_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  POST_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  EDIT_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  DELETE_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  POST_FOLLOW_ERROR,
  POST_FOLLOW_SUCCESS,
  DELETE_FOLLOW_ERROR,
  DELETE_FOLLOW_SUCCESS,
} from '../constants';

const initialState = {
  isPending: false,
  isPendingComments: false,
  problemDetails: {},
  error: false,
  errorComments: false,
  followStatusSent: 'initial',
  followError: false,
  followStatusDeleteSent: 'initial',
  followDeleteError: false,
  comments: [],
  likeSuccess: false,
  postCommentSuccess: false,
  editCommentSuccess: false,
};

function problemDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROBLEM_DETAILS_SUCCESS:
      return {
        ...state,
        problemDetails: action.payload,
        error: false,
        isPending: false,
      };
    case GET_PROBLEM_DETAILS_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
        problemDetails: {},
      };
    case GET_PROBLEM_DETAILS_LOADING:
      return {
        ...state,
        isPending: action.isPending,
        error: false,
        problemDetails: {},
      };

    case GET_COMMENTS_PROBLEM_DETAILS_SUCCESS:
      return {
        ...state,
        comments: action.comments,
        isPendingComments: false,
      };
    case GET_COMMENTS_PROBLEM_DETAILS_ERROR:
      return {
        ...state,
        errorComments: action.error,
        isPendingComments: false,
      };
    case GET_COMMENTS_PROBLEM_DETAILS_LOADING:
      return {
        ...state,
        isPendingComments: action.isPending,
        errorComments: false,
      };

    case LIKE_COMMENTS_PROBLEM_DETAILS_SUCCESS:
      return {
        ...state,
        likeSuccess: action.likeSuccess,
      };

    case POST_COMMENTS_PROBLEM_DETAILS_SUCCESS:
      return {
        ...state,
        postCommentSuccess: action.postCommentSuccess,
      };

    case EDIT_COMMENTS_PROBLEM_DETAILS_SUCCESS:
      return {
        ...state,
        editCommentSuccess: action.editCommentSuccess,
      };

    case DELETE_COMMENTS_PROBLEM_DETAILS_SUCCESS:
      return {
        ...state,
        deleteCommentSuccess: action.deleteCommentSuccess,
      };

    case POST_FOLLOW_ERROR:
      return {
        ...state,
        followStatusSent: false,
        followError: true,
        followStatusDeleteSent: 'initial',
        followDeleteError: false,
      };

    case POST_FOLLOW_SUCCESS:
      return {
        ...state,
        followStatusSent: true,
        followError: false,
        followStatusDeleteSent: 'initial',
        followDeleteError: false,
      };

    case DELETE_FOLLOW_ERROR:
      return {
        ...state,
        followStatusSent: 'initial',
        followError: true,
        followStatusDeleteSent: false,
        followDeleteError: true,
      };

    case DELETE_FOLLOW_SUCCESS:
      return {
        ...state,
        followStatusSent: true,
        followError: false,
        followStatusDeleteSent: true,
        followDeleteError: true,
      };

    default:
      return state;
  }
}

export default problemDetailsReducer;
