import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { getUserData } from '../../../models/selectors/users';
import AdminRoute from '../../PrivateRoute/AdminRoute';
import ListProblems from '../../Problems/ListProblems/ListProblems';
import ListUsers from '../ListUsers/ListUsers';
import Sidebar from './Sidebar';
import ProblemInfo from '../../Problems/ListProblems/ProblemInfo';
import './Dashboard.scss';

class Dashboard extends Component {
  render() {
    const { userData, location } = this.props;
    if (!userData) {
      return <Redirect to={{ pathname: '/login' }} />;
    }

    return (
      <div className="dash-wrapper ">
        <div className="">
          <div className="row">
            <div className="col-md-2 col-sm-12 center">
              <Sidebar userData={userData} />
            </div>
            <div className="content col-md-10 col-sm-12 mt-5">
              <Switch location={location}>
                <AdminRoute exact path="/dashboard/list-problems" component={ListProblems} />
                <AdminRoute exact path="/dashboard/list-users" component={ListUsers} />
                <AdminRoute exact path="/dashboard/problem-details/:id" component={ProblemInfo} />
              </Switch>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userData: getUserData(state),
  }),
  (dispatch) => ({}),
)(Dashboard);
