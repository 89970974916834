import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeProfile, getProfile, getMyProblems } from '../../../models/actions/profile';
import ModalDialog from '../../Common/ModalDialog';
import ProfileProblems from '../../Static/ProfileProblems/ProfileProblems';

import './MyProfile.scss';

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

class MyProfile extends Component {
  constructor(props) {
    super(props);

    const { userData } = props;

    this.state = {
      myProfileFields: {
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '*****************',
        retypePassword: '',
        isSubscribedToOwnProblemsNotifications: false,
        isSubscribedToCommentsNotifications: false,
      },
      passwordNotStrong: false,
      errorMsg: {},
      errors: {},
      repeatPassword: false,
      changeAccountSuccess: false,
      subMenu: 'accountSettings',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    this.props.getProfile();
    this.props.getMyProblems();
  }

  componentDidUpdate(nextProps) {
    const { profile, changeAccountSuccess } = this.props;
    const { myProfileFields } = this.state;

    if (profile !== nextProps.profile) {
      this.setState({
        myProfileFields: {
          ...myProfileFields,
          firstName: profile.firstName,
          lastName: profile.lastName,
          userName: profile.userName,
          email: profile.email,
          isSubscribedToOwnProblemsNotifications: profile.isSubscribedToOwnProblemsNotifications,
          isSubscribedToCommentsNotifications: profile.isSubscribedToCommentsNotifications,
        },
      });
    }

    if (changeAccountSuccess !== nextProps.changeAccountSuccess) {
      this.setState({
        changeAccountSuccess,
      });
      this.props.getProfile();
    }
  }

  updateField(fieldName, fieldValue) {
    const { myProfileFields } = this.state;

    this.setState({
      myProfileFields: {
        ...myProfileFields,
        [fieldName]: fieldValue,
      },
    });
  }

  showRepeatPassword() {
    const { repeatPassword } = this.state;

    this.setState({
      repeatPassword: !repeatPassword,
    });
  }

  validateFields(fieldName) {
    const { myProfileFields, errorMsg } = this.state;

    switch (fieldName) {
      case fieldName:
        errorMsg[fieldName] = myProfileFields[fieldName] === '' ? true : false;
        break;

      default:
        break;
    }

    this.setState({
      errorMsg: {
        ...errorMsg,
      },
    });
  }

  saveChanges() {
    const { myProfileFields, errorMsg, repeatPassword, passwordNotStrong } = this.state;
    const {
      firstName,
      lastName,
      userName,
      password,
      retypePassword,
      isSubscribedToOwnProblemsNotifications,
      isSubscribedToCommentsNotifications,
    } = myProfileFields;
    const isPasswordStrong = passwordRegex.test(password);

    Object.keys({ firstName, lastName, userName }).forEach((field) => this.validateFields(field));

    const checkFields = (field) => {
      for (let key in field) {
        if (field[key] === false) {
          return true;
        }
        return false;
      }
    };

    const isFormValid = checkFields(errorMsg) === true;

    if (repeatPassword && !isPasswordStrong) {
      this.setState({
        errorMsg: {
          ...errorMsg,
          password: true,
          passwordNotStrong: true,
        },
      });
    } else if (repeatPassword && password !== retypePassword) {
      this.setState({
        errorMsg: {
          ...errorMsg,
          password: true,
          retypePassword: true,
          notIdenticalPasswords: true,
          passwordNotStrong: false,
        },
      });
    } else if (repeatPassword && password === retypePassword) {
      this.setState({
        errorMsg: {
          ...errorMsg,
          password: false,
          retypePassword: false,
          notIdenticalPasswords: false,
          passwordNotStrong: false,
        },
      });
      this.props.changeProfile({
        firstName,
        lastName,
        userName,
        password,
        isSubscribedToOwnProblemsNotifications,
        isSubscribedToCommentsNotifications,
      });
    } else if (isFormValid) {
      this.props.changeProfile({
        firstName,
        lastName,
        userName,
        isSubscribedToOwnProblemsNotifications,
        isSubscribedToCommentsNotifications,
      });
    }
  }

  onAbortModal() {
    this.setState({
      changeAccountSuccess: false,
    });
  }

  changeSubMenu(subMenu) {
    this.setState({ subMenu });
  }

  render() {
    const { errorMsg, repeatPassword, changeAccountSuccess, subMenu } = this.state;
    const {
      firstName,
      lastName,
      userName,
      email,
      password,
      retypePassword,
      isSubscribedToOwnProblemsNotifications,
      isSubscribedToCommentsNotifications,
    } = this.state.myProfileFields;
    const { error, problems } = this.props;
    const loginType = localStorage.getItem('scUserLoginType');
    const modalProps = {
      isOpen: changeAccountSuccess || error,
      modalTitle: 'Modificare profil',
      onAbort: () => this.onAbortModal(),
      hideClose: true,
    };

    return (
      <div className={`login-form container create-account-wrapper`}>
        <ModalDialog {...modalProps}>
          {!error
            ? 'Modificarea profilului s-a efectuat cu succes!'
            : 'A aparut o eroare in salvarea datelor profilului tău'}
        </ModalDialog>

        <div className="row">
          <div className="col-md-2 mt-md-3 mt-sm-0 col-sm-12">
            <ul className="my-profile-menu mt-md-5 mt-sm-0 mb-5">
              <li
                className={`${subMenu === 'accountSettings' && 'active'} mb-2`}
                onClick={() => this.changeSubMenu('accountSettings')}
              >
                Setări cont
              </li>
              <li className={subMenu === 'myProblems' ? 'active' : ''} onClick={() => this.changeSubMenu('myProblems')}>
                Problemele tale ({problems && problems.length})
              </li>
            </ul>
          </div>
          <div className="col-md-10 col-sm-12">
            {subMenu === 'accountSettings' && (
              <>
                <div className="row profile-name">
                  <div className="col-12">
                    <h4 className="font-weight-bold">
                      {' '}
                      {firstName} {lastName}{' '}
                    </h4>
                    <p className="mt-3 mb-3 sub-title">Nume de utilizator: {userName}</p>
                    <p className="font-s-14">
                      Aici poți modifica datele contului tău și preferințele de notificări și poți urmări istoricul
                      problemelor înscrise.
                    </p>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-3 mb-5">
                    <h5 className="font-weight-bold">Setări cont</h5>
                  </div>
                  <div className="col-6 mt-3 mb-5 text-right">
                    <span className="save-button" onClick={() => this.saveChanges()}>
                      Salvează modificarile
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className={`form-row col-md-6 col-sm-12 ${errorMsg.firstName ? 'error' : ''}`}>
                    <label htmlFor="firstName">
                      <span className="title-span">Nume *</span>
                    </label>

                    <input
                      autoComplete="nope"
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Introdu numele de familie"
                      onChange={(event) => this.updateField('firstName', event.target.value)}
                      value={firstName}
                      // disabled={isFormLoading}
                    />
                  </div>
                  <div className={`form-row col-md-6 col-sm-12 margin-left-auto ${errorMsg.lastName ? 'error' : ''}`}>
                    <label htmlFor="lastName">
                      <span className="title-span">Prenume *</span>
                    </label>

                    <input
                      autoComplete="nope"
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Introdu prenumele"
                      onChange={(event) => this.updateField('lastName', event.target.value)}
                      value={lastName}
                      // disabled={isFormLoading}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-row col-md-6 col-sm-12">
                    <label htmlFor="userName">
                      <span className="title-span">Nume de utilizator (opțional)</span>
                    </label>
                    <input
                      autoComplete="nope"
                      type="text"
                      name="userName"
                      id="userName"
                      placeholder="Cum vrei să fii afișat pe platformă"
                      onChange={(event) => this.updateField('userName', event.target.value)}
                      value={userName}
                      // disabled={isFormLoading}
                    />
                  </div>
                  <div className="form-row col-md-6 col-sm-12 margin-left-auto">
                    <label htmlFor="email">
                      <span className="title-span">Adresă de e-mail *</span>
                    </label>

                    <input autoComplete="nope" type="text" name="email" id="email" value={email} disabled={true} />
                  </div>
                </div>
                {loginType !== 'googleLogin' && loginType !== 'facebookLogin' && (
                  <>
                    <h5 className="my-5 font-weight-bold">Securitate</h5>
                    <div className="row">
                      <div className={`form-row col-md-6 col-sm-12 ${errorMsg.password ? 'error' : ''}`}>
                        <label htmlFor="password">
                          <span className="title-span">{!repeatPassword ? 'Parolă' : 'Parola nouă'}</span>
                        </label>

                        <input
                          autoComplete="nope"
                          type="password"
                          name="password"
                          id="password"
                          onChange={(event) => this.updateField('password', event.target.value)}
                          value={password}
                          disabled={!repeatPassword}
                        />
                        {errorMsg.passwordNotStrong && (
                          <span className="error-msg">
                            Minim 6 caractere, ce includ și un caracter special (@#$%^&amp;)
                          </span>
                        )}
                        {errorMsg.notIdenticalPasswords && (
                          <span className="error-msg">Cele două parole nu coincid!</span>
                        )}
                      </div>
                      {repeatPassword && (
                        <div
                          className={`form-row col-md-6 col-sm-12 margin-left-auto ${
                            errorMsg.retypePassword ? 'error' : ''
                          }`}
                        >
                          <label htmlFor="retypePassword">
                            <span className="title-span">Repeta parola *</span>
                          </label>

                          <input
                            autoComplete="nope"
                            type="password"
                            name="retypePassword"
                            id="retypePassword"
                            onChange={(event) => this.updateField('retypePassword', event.target.value)}
                            value={retypePassword}
                            // disabled={isFormLoading}
                          />
                          {errorMsg.notIdenticalPasswords && (
                            <span className="error-msg">Cele două parole nu coincid!</span>
                          )}
                        </div>
                      )}
                      <p
                        className="font-s-12 change-password-button pb-3 col-12"
                        onClick={() => this.showRepeatPassword()}
                      >
                        Schimbă parola
                      </p>
                    </div>
                  </>
                )}
                <h5 className="my-5 font-weight-bold">Notificări</h5>
                <div className="form-row col-md-10">
                  <p>Ce notificări dorești sa primești pe adresa de email?</p>
                  <div className="form-row mb-2 checkbox">
                    <input
                      type="checkbox"
                      name="isSubscribedToOwnProblemsNotifications"
                      id="isSubscribedToOwnProblemsNotifications"
                      onChange={(event) =>
                        this.updateField('isSubscribedToOwnProblemsNotifications', event.target.checked)
                      }
                      checked={isSubscribedToOwnProblemsNotifications}
                      className="no-error"
                    />
                    <label htmlFor="isSubscribedToOwnProblemsNotifications" className="no-error">
                      Vreau să primesc noutăți despre comentarii noi la probleme înscrise de mine.
                    </label>
                  </div>
                  <div className="form-row mb-2 checkbox">
                    <input
                      type="checkbox"
                      name="isSubscribedToCommentsNotifications"
                      id="isSubscribedToCommentsNotifications"
                      onChange={(event) =>
                        this.updateField('isSubscribedToCommentsNotifications', event.target.checked)
                      }
                      checked={isSubscribedToCommentsNotifications}
                      className="no-error"
                    />
                    <label htmlFor="isSubscribedToCommentsNotifications" className="no-error">
                      Vreau să primesc noutăți despre răspunsuri la comentariile mele.
                    </label>
                  </div>
                </div>
              </>
            )}
            {subMenu === 'myProblems' && <ProfileProblems problems={problems} history={this.props.history} />}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      isPending: state.profileReducer.isPending,
      error: state.profileReducer.error,
      changeAccountSuccess: state.profileReducer.changeAccountSuccess,
      profile: state.profileReducer.profile,
      problems: state.profileReducer.problems.problems,
    }),
    (dispatch) => ({
      changeProfile: (profileDetails) => dispatch(changeProfile(profileDetails)),
      getProfile: () => dispatch(getProfile()),
      getMyProblems: () => dispatch(getMyProblems()),
    }),
  )(MyProfile),
);
