import { createSelector } from 'reselect';
import { getSelectedFilterTags } from './filterTags';

export const getIsProblemsLoading = (state) => state.existingProblems.isProblemsLoading;
export const currentProblems = (state) => state.existingProblems.existingProblems;

export const getCurrentProblems = createSelector(
  [getIsProblemsLoading, currentProblems, getSelectedFilterTags],
  (isProblemsLoading, problems, filterTags) => {
    if (isProblemsLoading) {
      return null;
    }

    if (!filterTags.length) {
      return problems;
    }

    const isTagPresent = (tags) => {
      let storegTags = [];
      tags.forEach((current) => storegTags.push(current.tagId));
      return filterTags.some((tag) => storegTags.indexOf(tag) >= 0);
    };

    const filteredProblems = problems.filter((problem) => isTagPresent(problem.tags));

    return filteredProblems;
  },
);
