import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import { onUserRegisterAtion, checkEmailExistenceAction } from '../../../models/actions/userActions';
import { getIsEmailIsAvailable, getIsFormLoading, getRegisterStatus } from '../../../models/selectors/register';
import { onUserGoogleLoginAction, onUserFacebookLoginAction } from '../../../models/actions/userActions';
import { config } from '../../../models/api/apiConfig';
import { isUserLoggedIn } from '../UserUtils';
import { getUserStatus } from '../../../models/selectors/login';

import SocialLogin from '../../Static/SocialLogin/SocialLogin';
import './CreateAccount.scss';

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const validateEmail = (email) => emailPattern.test(email);

const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: {},
      registerFields: {
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        retypePassword: '',
      },
      agreement: 'initial',
      isAvailableForInterview: false,
      isSubscribedToNewsletter: false,
      projectRules: true,
      gdprData: true,
      isFormValid: false,
      errors: {},
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  componentDidUpdate(nextProps) {
    if (this.props.userStatus !== nextProps.userStatus) {
      const isUserAuthorized = isUserLoggedIn();

      if (isUserAuthorized && this.props.location.state && this.props.location.state.tagId) {
        return this.props.history.push(`/register-problem?category=${this.props.location.state.tagId}`);
      } else if (isUserAuthorized) {
        return this.props.history.push('/');
      }
    }
  }

  updateField(fieldName, fieldValue) {
    const { registerFields } = this.state;
    if (fieldName === 'agreement') {
      return this.setState({
        agreement: fieldValue,
      });
    }
    if (fieldName === 'isAvailableForInterview') {
      return this.setState({
        isAvailableForInterview: fieldValue,
      });
    }
    if (fieldName === 'isSubscribedToNewsletter') {
      return this.setState({
        isSubscribedToNewsletter: fieldValue,
      });
    }
    if (fieldName === 'projectRules') {
      return this.setState({
        projectRules: fieldValue,
      });
    }
    if (fieldName === 'gdprData') {
      return this.setState({
        gdprData: fieldValue,
      });
    }

    this.setState({
      registerFields: {
        ...registerFields,
        [fieldName]: fieldName === 'email' ? fieldValue.split(' ').join('') : fieldValue,
      },
    });
  }

  validateFields(fieldName) {
    const { registerFields, errorMsg } = this.state;
    const { checkEmailExistence } = this.props;
    const renderErrMsg = (field) => `'${field}' este un câmp obligatoriu`;

    switch (fieldName) {
      case 'firstName':
        if (registerFields[fieldName].length < 4) {
          errorMsg[fieldName] = renderErrMsg('Numele');
        } else if (!registerFields[fieldName].match(/^[a-zA-Z]+$/)) {
          errorMsg[fieldName] = 'Campul contine caractere invalide';
        } else {
          errorMsg[fieldName] = false;
        }
        break;

      case 'lastName':
        errorMsg[fieldName] = registerFields[fieldName] === '' ? renderErrMsg('Prenumele') : false;
        break;

      case 'email':
        const isEmailValid = validateEmail(registerFields[fieldName]) && registerFields[fieldName];
        checkEmailExistence(registerFields['email']);
        errorMsg[fieldName] =
          registerFields[fieldName] === '' ? renderErrMsg('Email') : !isEmailValid ? 'Email invalid' : false;

        break;

      case 'password':
        const isPasswordStrong = passwordRegex.test(registerFields[fieldName]);
        errorMsg[fieldName] =
          registerFields[fieldName] === ''
            ? renderErrMsg('Parola')
            : !isPasswordStrong
            ? 'Parola trebuie sa contina un numar si o litera mare'
            : false;
        break;

      case 'retypePassword':
        errorMsg[fieldName] =
          registerFields[fieldName] === ''
            ? renderErrMsg('Repeta parola')
            : registerFields[fieldName] !== registerFields['password']
            ? 'Campul Parola si Repeta parola nu coincid'
            : false;
        break;
      default:
        break;
    }

    this.setState({
      errorMsg: {
        ...errorMsg,
      },
    });
  }

  renderFieldError(fieldName) {
    const { errorMsg, registerFields } = this.state;

    if (fieldName === 'email' && !this.props.isEmailAvailable && registerFields[fieldName]) {
      return <span className="error-msg">Email Existent</span>;
    }
    return errorMsg[fieldName] ? <span className="error-msg">{errorMsg[fieldName]}</span> : <span />;
  }

  registerAccount() {
    const { isEmailAvailable } = this.props;
    const { registerFields, agreement, errorMsg, gdprData, projectRules } = this.state;

    Object.keys(registerFields).forEach((field) => this.validateFields(field));

    if (agreement === 'initial') {
      this.setState({
        agreement: false,
      });
    }

    const checkFields = (field) => {
      for (let key in field) {
        if (field[key] === false) {
          return true;
        }
        return false;
      }
    };

    const isFormValid =
      checkFields(errorMsg) === true &&
      agreement &&
      agreement !== 'initial' &&
      isEmailAvailable &&
      gdprData &&
      projectRules;

    const { email, password, firstName, lastName, userName } = registerFields;
    const { isAvailableForInterview, isSubscribedToNewsletter } = this.state;

    isFormValid &&
      this.props.userRegister({
        email,
        password,
        firstName,
        lastName,
        userName,
        isAvailableForInterview,
        isSubscribedToNewsletter,
      });
  }

  responseGoogleSuccess(response) {
    const { tokenId } = response;

    this.props.onUserGoogleLoginAction(tokenId);
  }

  responseGoogleFailure(error) {
    console.log('Google Login error:');
    console.log(error);
  }

  responseFacebook(response) {
    const { accessToken } = response;

    this.props.onUserFacebookLoginAction(accessToken);
  }

  facebookClicked(response) {
    console.log('FB clicked');
    console.log(response);
  }

  render() {
    const { isEmailAvailable, isFormLoading, registerStatus } = this.props;

    const {
      errorMsg,
      agreement,
      isAvailableForInterview,
      isSubscribedToNewsletter,
      projectRules,
      gdprData,
    } = this.state;
    const { firstName, lastName, userName, email, password, retypePassword } = this.state.registerFields;

    if (registerStatus === 'success' && this.props.location.state && this.props.location.state.tagId) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { tagId: this.props.location.state.tagId },
          }}
        />
      );
    } else if (registerStatus === 'success') {
      return <Redirect to={{ pathname: '/login' }} />;
    }

    return (
      <div className={`login-form container create-account-wrapper`}>
        <div className="force-push-menu-space"></div>
        <div className="row">
          <div className="offset-md-3 col-md-6 col-sm-12 offset-sm-0">
            <div className="register">
              <h2 className="font-weight-bold"> Creează-ți un cont</h2>
              <div className="login-link mb-4">
                <NavLink to="/login">Am deja cont {'>'} </NavLink>
              </div>

              <div className={`form-row ${errorMsg.firstName ? 'error' : ''}`}>
                <label htmlFor="firstName">
                  <span className="title-span">Nume *</span>
                  {this.renderFieldError('firstName')}
                </label>

                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Introdu numele de familie"
                  onChange={(event) => this.updateField('firstName', event.target.value)}
                  onBlur={() => this.validateFields('firstName')}
                  value={firstName}
                  // disabled={isFormLoading}
                />
              </div>
              <div className={`form-row ${errorMsg.lastName ? 'error' : ''}`}>
                <label htmlFor="lastName">
                  <span className="title-span">Prenume *</span>
                  {this.renderFieldError('lastName')}
                </label>

                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Introdu prenumele"
                  onChange={(event) => this.updateField('lastName', event.target.value)}
                  onBlur={() => this.validateFields('lastName')}
                  value={lastName}
                  // disabled={isFormLoading}
                />
              </div>
              <div className="form-row">
                <label htmlFor="userName">
                  <span className="title-span">Alege-ți un nume de utilizator (opțional)</span>
                </label>
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="Cum vrei să fii afișat pe platformă"
                  onChange={(event) => this.updateField('userName', event.target.value)}
                  onBlur={() => this.validateFields('userName')}
                  value={userName}
                  // disabled={isFormLoading}
                />
                <span>Așa vei fi văzut de alți utilizatori în cadrul platformei, altfel vom afișa numele întreg</span>
              </div>
              <div className={`form-row ${errorMsg.email || !isEmailAvailable ? 'error' : ''}`}>
                <label htmlFor="email">
                  <span className="title-span">Introdu o adresă de e-mail *</span>
                  {this.renderFieldError('email')}
                </label>

                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="exemplu@domeniu.ro"
                  onChange={(event) => this.updateField('email', event.target.value)}
                  onBlur={() => this.validateFields('email')}
                  value={email}
                  // disabled={isFormLoading}
                />
                <span>Introdu adresa ta de email</span>
              </div>
              <div className={`form-row ${errorMsg.password ? 'error' : ''}`}>
                <label htmlFor="password">
                  <span className="title-span">Alege o parolă puternică *</span>
                  {this.renderFieldError('password')}
                </label>

                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={(event) => this.updateField('password', event.target.value)}
                  onBlur={() => this.validateFields('password')}
                  value={password}
                  // disabled={isFormLoading}
                />
                <span>Minim 6 caractere, ce includ și un caracter special (@#$%^&amp;)</span>
              </div>
              <div className={`form-row ${errorMsg.retypePassword ? 'error' : ''}`}>
                <label htmlFor="retypePassword">
                  <span className="title-span">Repeta parola *</span>
                  {this.renderFieldError('retypePassword')}
                </label>

                <input
                  type="password"
                  name="retypePassword"
                  id="retypePassword"
                  onChange={(event) => this.updateField('retypePassword', event.target.value)}
                  onBlur={() => this.validateFields('retypePassword')}
                  value={retypePassword}
                  // disabled={isFormLoading}
                />
              </div>
              <div className={`form-row mb-2 mt-5 checkbox ${agreement !== 'initial' && !agreement ? 'error' : ''}`}>
                <div className="mandatory-special-label">
                  <span className="title-span">
                    E obligatoriu să confirmi că ai citit și înțeles documentele referențiate*{' '}
                  </span>
                  <br />
                </div>
                <input
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  onChange={(event) => this.updateField('agreement', event.target.checked)}
                  checked={agreement !== 'initial' && agreement}
                  // disabled={isFormLoading}
                />

                <label htmlFor="agreement" className="terms-and-conditions-label">
                  <span className="grey-span">
                    Am citit și sunt de acord cu: <br />
                  </span>
                  <ul className="terms-and-conditions">
                    <li>
                      <NavLink to="/terms-and-conditions">Termenii și condițiile de utilizare a platformei</NavLink>
                    </li>
                    <li>
                      <NavLink to="/regulations">Regulamentul proiectului</NavLink>
                    </li>
                    <li>
                      <NavLink to="/personal-data">Prelucrarea datelor cu caracter personal *</NavLink>
                    </li>
                  </ul>
                </label>
              </div>
              {/* <div className={`form-row mb-2 checkbox ${!projectRules ? 'error' : ''}`}>
                <input
                  type="checkbox"
                  name="projectRules"
                  id="projectRules"
                  onChange={(event) => this.updateField('projectRules', event.target.checked)}
                  checked={projectRules}
                  // disabled={isFormLoading}
                />
                <label htmlFor="projectRules">
                  <span className="grey-span">Am citit și sunt de acord cu </span>
                  <NavLink to="">Regulamentul proiectului *</NavLink>
                </label>
              </div>
              <div className={`form-row mb-2 checkbox ${!gdprData ? 'error' : ''}`}>
                <input
                  type="checkbox"
                  name="gdprData"
                  id="gdprData"
                  onChange={(event) => this.updateField('gdprData', event.target.checked)}
                  checked={gdprData}
                  // disabled={isFormLoading}
                />
                <label htmlFor="gdprData">
                  <span className="grey-span">Am citit și sunt de acord cu </span>
                  <NavLink to="/personal-data">Prelucrarea datelor cu caracter personal *</NavLink>
                </label>
              </div> */}
              {/* <div className={`form-row mb-2 checkbox ${!isAvailableForInterview ? 'error' : ''}`}>
                <input
                  type="checkbox"
                  name="isAvailableForInterview"
                  id="isAvailableForInterview"
                  onChange={(event) => this.updateField('isAvailableForInterview', event.target.checked)}
                  checked={isAvailableForInterview}
                  // disabled={isFormLoading}
                  className="no-error"
                />
                <label htmlFor="isAvailableForInterview" className="no-error">
                  Sunt interesat/ă sa particip la un interviu de cercetare dacă problema aceasta este selectată
                  (opțional)
                </label>
              </div> */}
              <div className={`form-row mb-2 checkbox ${!isSubscribedToNewsletter ? 'error' : ''}`}>
                <input
                  type="checkbox"
                  name="isSubscribedToNewsletter"
                  id="isSubscribedToNewsletter"
                  onChange={(event) => this.updateField('isSubscribedToNewsletter', event.target.checked)}
                  checked={isSubscribedToNewsletter}
                  // disabled={isFormLoading}
                  className="no-error"
                />
                <label htmlFor="isSubscribedToNewsletter" className="no-error">
                  Mă interesează să primesc noutăți despre progresul proiectului (opțional)
                </label>
              </div>
              <div className="form-row single">
                <div className="col-12 mt-3">
                  <button
                    className="submit-btn default"
                    onClick={() => this.registerAccount()}
                    // disabled={isFormLoading}
                  >
                    Creează cont
                  </button>
                </div>
                <div className="col-12 mt-3">
                  <GoogleLogin
                    clientId={config.googleId}
                    buttonText="Login"
                    onSuccess={(response) => this.responseGoogleSuccess(response)}
                    onFailure={(error) => this.responseGoogleFailure(error)}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                    render={(renderProps) => (
                      <button
                        className="login-with-google-btn"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        Înregistrează-te cu Google
                      </button>
                    )}
                  />
                </div>
                <div className="col-12 mt-3">
                  <FacebookLogin
                    appId={config.facebookId}
                    autoLoad={false}
                    textButton="Înregistrează-te cu Facebook"
                    fields="name,email,picture"
                    onClick={(response) => this.facebookClicked(response)}
                    callback={(response) => this.responseFacebook(response)}
                    render={(renderProps) => (
                      <button onClick={renderProps.onClick}>Înregistrează-te cu Facebook</button>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    registerStatus: getRegisterStatus(state),
    isEmailAvailable: getIsEmailIsAvailable(state),
    isFormLoading: getIsFormLoading(state),
    userStatus: getUserStatus(state),
  }),
  (dispatch) => ({
    userRegister: (userData) => dispatch(onUserRegisterAtion(userData)),
    checkEmailExistence: (email) => dispatch(checkEmailExistenceAction(email)),
    onUserGoogleLoginAction: (tokenId) => dispatch(onUserGoogleLoginAction(tokenId)),
    onUserFacebookLoginAction: (accessToken) => dispatch(onUserFacebookLoginAction(accessToken)),
  }),
)(CreateAccount);
