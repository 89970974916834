import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { getProblemDetailsVote } from '../../../models/actions/problemDetailsVote';
import { api } from '../../../models/api/api';
// import ModalNotLoggedIn from '../../Static/ModalNotLoggedIn/ModalNotLoggedIn';
import { isUserLoggedIn } from '../../../components/User/UserUtils';

import { Counter1Img, Counter2Img, Counter3Img, DownArrow } from '../../../assets/images/images';
import './Homepage.scss';

import {
  SpatiiPubliceLogo,
  MobilitateLogo,
  EducatieLogo,
  SanatateLogo,
  GuvernareLogo,
  OrdineSiSigurantaLogo,
  CulturaLogo,
} from '../../../assets/images/images';
import proces_1 from '../../../assets/images/homepage/proces_01.svg';
import proces_2 from '../../../assets/images/homepage/proces_02.svg';
import proces_3 from '../../../assets/images/homepage/proces_03.svg';
import proces_4 from '../../../assets/images/homepage/proces_04.svg';
import proces_5 from '../../../assets/images/homepage/proces_05.svg';
import proces_6 from '../../../assets/images/homepage/proces_06.svg';
import proces_7 from '../../../assets/images/homepage/proces_07.svg';
import proces_scurt from '../../../assets/images/homepage/cum-facem-pe-scurt.svg';
import slide1 from '../../../assets/images/homeSlider/1.png';
import slide2 from '../../../assets/images/homeSlider/2.png';
import slide3 from '../../../assets/images/homeSlider/3.png';
import slide4 from '../../../assets/images/homeSlider/4.png';

const settlement = [
  {
    problemTagId: 1,
  },
  {
    problemTagId: 2,
  },
  {
    problemTagId: 3,
  },
  {
    problemTagId: 4,
  },
  {
    problemTagId: 5,
  },
  {
    problemTagId: 6,
  },
  {
    problemTagId: 7,
  },
];

class Homepage extends Component {
  constructor() {
    super();
    this.state = {
      newsletterValue: '',
      showNewsletterSuccess: false,
      showNewsletterError: false,
      showModal: false,
      categoryClickedTagId: '',
      slideNr: slide1,
      slideNrInt: 1,
      fadingOut: false,
      fadingIn: false,
      carouselIntervalId: '',
      counterItems: {
        usersCount: '',
        problemsCount: '',
        problemsAddedInTheLast24HoursCount: '',
      },
    };
  }
  componentDidMount() {
    document.body.classList.add('homepage');
    window.scrollTo(0, 0);
    this.generateSlideNr();

    api.get('counter').then((counterItems) => {
      this.setState({ counterItems });
    });
    this.props.getProblemDetailsVote();
  }
  componentWillUnmount() {
    document.body.classList.remove('homepage');
    clearInterval(this.state.carouselIntervalId);
  }

  goToProblemCategories(categoryId) {
    const isUserAuthorized = isUserLoggedIn();

    if (isUserAuthorized) {
      this.props.history.push(`/register-problem?category=${categoryId}`);
    } else {
      // this.setState({
      //   showModal: true,
      //   categoryClickedTagId: categoryId,
      // });
      this.props.history.push({
        pathname: '/create-account',
        state: { tagId: categoryId },
      });
    }
  }

  onChangeNewsletterInput(e) {
    this.setState({ newsletterValue: e.target.value });
  }

  submitNewsletter() {
    const { newsletterValue } = this.state;

    this.setState({
      showNewsletterError: false,
      showNewsletterSuccess: false,
    });

    api
      .postDataWithoutAuth('newsletter', { email: newsletterValue })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            showNewsletterSuccess: true,
          });
        }

        if (response.status === 400) {
          this.setState({
            showNewsletterError: true,
          });
        }

        setTimeout(() => {
          this.setState({
            showNewsletterError: false,
            showNewsletterSuccess: false,
          });
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          showNewsletterError: true,
        });
      });
  }

  modalClose() {
    this.setState({
      showModal: false,
    });
  }

  generateSlideNr() {
    let carouselIntervalId = setInterval(() => {
      const { slideNrInt } = this.state;
      const generateSliderNr = slideNrInt === 1 ? 2 : slideNrInt === 2 ? 3 : slideNrInt === 3 ? 4 : 1;

      switch (generateSliderNr) {
        case 1:
          this.setState({
            fadingOut: true,
            fadingIn: false,
          });
          setTimeout(() => {
            this.setState({
              fadingIn: true,
              fadingOut: false,
              slideNr: slide1,
              slideNrInt: 1,
            });
          }, 2000);
          break;
        case 2:
          this.setState({
            fadingOut: true,
            fadingIn: false,
          });
          setTimeout(() => {
            this.setState({
              fadingIn: true,
              fadingOut: false,
              slideNr: slide2,
              slideNrInt: 2,
            });
          }, 2000);
          break;

        case 3:
          this.setState({
            fadingOut: true,
            fadingIn: false,
          });
          setTimeout(() => {
            this.setState({
              fadingIn: true,
              fadingOut: false,
              slideNr: slide3,
              slideNrInt: 3,
            });
          }, 2000);
          break;

        case 4:
          this.setState({
            fadingOut: true,
            fadingIn: false,
          });
          setTimeout(() => {
            this.setState({
              fadingIn: true,
              fadingOut: false,
              slideNr: slide4,
              slideNrInt: 4,
            });
          }, 2000);
          break;
      }
    }, 10000);
    this.setState({ carouselIntervalId });
  }

  redirectToProblemDetailsVotePage(category) {
    this.props.history.push(`/vote/${category}`);
  }

  render() {
    const { newsletterValue, showNewsletterSuccess, showNewsletterError, showModal, categoryClickedTagId } = this.state;
    const { slideNr, fadingOut, fadingIn, counterItems } = this.state;
    const { usersCount, problemsCount, problemsAddedInTheLast24HoursCount } = counterItems;
    const { voteStatsPerCategory, totalVotesCount } = this.props.problemDetailsVote;

    return (
      <>
        <div className="container-fluid p-0 m-0">
          <div className="row p-0 m-0">
            <div className="carousel-info col-md-4 col-sm-12">
              <h1>
                Pentru orașul în <br /> care ne place <br />
                să trăim
              </h1>
              <p>
                Un proiect prin care prioritizăm, documentăm și rezolvăm cele mai apăsătoare probleme ale orașului
                punând împreună cunoaștere și resurse din comunitate.
              </p>
            </div>
            <div
              className={`carousel 
                col-md-12
                d-md-block
                d-sm-none 
                d-xs-none 
                p-0 m-0 
                round-one 
                ${fadingOut ? 'fade-out' : ''}
                ${fadingIn ? 'fade-in' : ''}
              `}
              style={{ backgroundImage: 'url(' + slideNr + ')' }}
            ></div>
          </div>
        </div>
        <div className="container">
          {/* <ModalNotLoggedIn showModal={showModal} onModalClose={() => this.modalClose()} tagId={categoryClickedTagId} /> */}
          <div className="row">
            <div className="col-6 offset-3 center mt-5 margin-bottom-50 carousel-info-button-wrapper">
              {/* <NavLink to="/problem-categories">
                <button>Înscrie o problemă</button>
              </NavLink> */}
              <p> Am început etapa de soluționare.</p>
              <p>
                Ieșenii au prioritizat problemele cu care se confruntă, iar acum, 7 echipe multidisciplinare lucrează la
                găsirea celor mai bune soluții pentru comunitate.
              </p>
              <p>
                <a href="https://afla.solutiicolaborative.ro">Vezi evoluția fiecărui proiect!</a>
              </p>
              {/* <span className="orange-button">
                <NavLink to="/vote-problem-categories">Votează</NavLink>
              </span> */}
            </div>

            {/* <div className="row center counter-container justify-content-center">
              <span className="right-counter col-md-4 col-sm-12 font-s-16 d-inline text-left ml-md-0 ml-sm-3">
                <div className="counter-img-wrapper">
                  <Counter2Img />
                </div>
                <span className="d-block line-height-20">
                  <span className="font-s-26">{problemsAddedInTheLast24HoursCount}</span> probleme au fost înscrise în
                  ultimele 24 de ore
                </span>
              </span>
            </div> */}
            {/* <div className="col-md-6 offset-md-3 mb-5 center col-sm-12 offset-sm-0">
              <hr />
              <br />
              <br />
              <br />
              <br />
              <p>
                <b>Revenim pe 15 februarie cu temele principale semnalate. Rămâneți aproape. Urmează să le votați.</b>
              </p>
              <p>
                Până atunci, împreună cu specialiștii din proiect, analizăm toate problemele înregistrate pe platformă
                pentru a le grupa și sintetiza în probleme generice. Pe cele punctuale, care nu au nevoie de Design
                Thinking pentru soluționare, le transferăm către instituțiile locale. <br />
                Vă mulțumim pentru implicare!
              </p>
              <br />
              <DownArrow />
            </div> */}

            <div className="col-md-8 offset-md-2 mb-5 center col-sm-12 offset-sm-0">
              <DownArrow />
            </div>
          </div>
          <div className="row my-5 problem-cards d-none">
            <div className="col-md-4 col-sm-12 p-4">
              <div>
                <SpatiiPubliceLogo />
              </div>
              <p>
                Care este ​problema cea mai mare​ care vă oprește să vă bucurați pe deplin de{' '}
                <b>s​pații publice prietenoase</b> ​(zone pietonale, spații verzi, trotuare, locuri de joacă și de
                recreere) în Iași?
              </p>
              <p className="tag spatii">Spații publice</p>
            </div>
            <div className="col-md-4 col-sm-12 p-4">
              <MobilitateLogo />
              <p>
                Care este ​bariera principală​ care vă oprește din​ <b>a ajunge cu ușurință și în siguranță​</b> oriunde
                aveți nevoie în Iași?
              </p>
              <p className="tag mobilitate">Mobilitate, accesibilitate și siguranța circulației</p>
            </div>
            <div className="col-md-4 col-sm-12 p-4">
              <EducatieLogo />
              <p>
                Care este ​provocarea majoră​ pe care o întâmpinați pentru a beneficia (personal sau copiii
                dumneavoastră) de​ o <b>educație de calitate​</b> în Iași?
              </p>
              <p className="tag educatie">Educație</p>
            </div>

            <div className="col-md-4 col-sm-12 p-4">
              <SanatateLogo />
              <p>
                Care este ​cea mai mare dificultate​ pe care o întâmpinați în prezent pentru accesul la o{' '}
                <b>viață sănătoasă</b> ​în Iași?
              </p>
              <p className="tag sanatate">Sănătate și mediu</p>
            </div>
            <div className="col-md-4 col-sm-12 p-4">
              <GuvernareLogo />
              <p>
                Ce anume din ce faceți în​ ​relația cu orașul și autoritățile​ e​ste foarte dificil​ din cauza lipsei
                unor <b>s​oluții digitale</b> în Iași?
              </p>
              <p className="tag guvernare">Bună guvernare și digitalizare</p>
            </div>
            <div className="col-md-4 col-sm-12 p-4">
              <OrdineSiSigurantaLogo />
              <p>
                Ce v​ă împiedică cel mai tare ​să vă simțiți <b>​în siguranță</b> î​n Iași?
              </p>
              <p className="tag ordine">Ordine și siguranță publică</p>
            </div>
            <div className="offset-md-4 col-md-4 col-sm-12 px-5 mb-5">
              <CulturaLogo />
              <p>
                Ce anume vă ​îngreunează cel mai tare​ în momentul de față accesul la <b>o viață culturală bogată​</b>{' '}
                în Iași?
              </p>
              <p className="tag cultura">Cultură</p>
            </div>
          </div>
          <div className="container settlement mb-5 pb-5">
            <div className="row mb-5 problem-cards">
              {settlement &&
                settlement.map((category, i) => (
                  <a
                    className={`${
                      settlement.length - 1 !== i
                        ? 'col-md-4 col-sm-12 selectable-card mb-5 mt-5 p-4'
                        : 'offset-md-4 col-md-4 col-sm-12 selectable-card mb-5 mt-5 px-5'
                    }`}
                    key={category.problemTagId}
                    href={`${
                      category.problemTagId === 1
                        ? 'https://afla.solutiicolaborative.ro/spatii-publice'
                        : category.problemTagId === 2
                        ? 'https://afla.solutiicolaborative.ro/mobilitate-accesibilitate-si-siguranta-circulatiei'
                        : category.problemTagId === 3
                        ? 'https://afla.solutiicolaborative.ro/educatie'
                        : category.problemTagId === 4
                        ? 'https://afla.solutiicolaborative.ro/sanatate-si-mediu'
                        : category.problemTagId === 5
                        ? 'https://afla.solutiicolaborative.ro/buna-guvernare-si-digitalizare'
                        : category.problemTagId === 6
                        ? 'https://afla.solutiicolaborative.ro/ordine-si-siguranta-publica'
                        : 'https://afla.solutiicolaborative.ro/cultura'
                    }`}
                  >
                    <div className="left-side float-left">
                      {category.problemTagId === 1 && (
                        <>
                          <p className="tag spatii mt-3">Spații publice</p>
                          <p className="tag-content">
                            <b>Insuficiente spații amenajate pentru recreere.</b>
                          </p>
                        </>
                      )}
                      {category.problemTagId === 2 && (
                        <>
                          <p className="tag mobilitate mt-3">Mobilitate</p>
                          <p className="tag-content">
                            <b>
                              Bicicliștii din Iași nu se simt în siguranță și încurajați să traverseze orașul cu
                              bicicleta.
                            </b>
                          </p>
                        </>
                      )}
                      {category.problemTagId === 3 && (
                        <>
                          <p className="tag educatie mt-3">Educație</p>
                          <p className="tag-content">
                            <b>
                              Prin programa școlară actuală, elevii nu dobândesc abilități suficiente pentru provocările
                              vieții reale.
                            </b>
                          </p>
                        </>
                      )}
                      {category.problemTagId === 4 && (
                        <>
                          <p className="tag sanatate mt-3">Sănătate și mediu</p>
                          <p className="tag-content">
                            <b>Grad ridicat de poluare a aerului.</b>
                          </p>
                        </>
                      )}
                      {category.problemTagId === 5 && (
                        <>
                          <p className="tag guvernare mt-3">Bună guvernare și digitalizare</p>
                          <p className="tag-content">
                            <b>Birocrația excesivă în interacțiunea cu Primăria Iași și instituțiile subordonate.</b>
                          </p>
                        </>
                      )}
                      {category.problemTagId === 6 && (
                        <>
                          <p className="tag ordine mt-3">Ordine și siguranță publică</p>
                          <p className="tag-content">
                            <b>
                              Unii ieșeni nu se simt în siguranță în oraș în raport cu modul în care forțele de ordine
                              își fac simțită prezența.
                            </b>
                          </p>
                        </>
                      )}
                      {category.problemTagId === 7 && (
                        <>
                          <p className="tag cultura mt-3">Cultură</p>{' '}
                          <p className="tag-content">
                            <b>
                              Valorificarea insuficientă a clădirilor de patrimoniu și a spațiilor istorice din zona
                              centrală.
                            </b>
                          </p>
                        </>
                      )}
                    </div>
                    <div className="right-side">
                      {category.problemTagId === 1 ? (
                        <SpatiiPubliceLogo />
                      ) : category.problemTagId === 2 ? (
                        <MobilitateLogo />
                      ) : category.problemTagId === 3 ? (
                        <EducatieLogo />
                      ) : category.problemTagId === 4 ? (
                        <SanatateLogo />
                      ) : category.problemTagId === 5 ? (
                        <GuvernareLogo />
                      ) : category.problemTagId === 6 ? (
                        <OrdineSiSigurantaLogo />
                      ) : (
                        <CulturaLogo />
                      )}
                    </div>
                  </a>
                ))}
            </div>
          </div>
          <div className="col-md-4 offset-md-4 mt-5">
            <hr />
          </div>
          <div className="row center counter-container justify-content-center">
            <span className="left-counter col-md-4 col-sm-12 font-s-16 d-inline text-left ml-md-0 ml-sm-3">
              <div className="counter-img-wrapper">
                <Counter1Img />
              </div>
              <span className="d-block line-height-20">
                <span className="font-s-26">{usersCount}</span> cetățeni sunt deja implicați pe platformă
              </span>
            </span>
            <span className="right-counter col-md-4 col-sm-12 font-s-16 d-inline text-left ml-md-0 ml-sm-3">
              <div className="counter-img-wrapper">
                <Counter2Img />
              </div>
              <span className="d-block line-height-20">
                <span className="font-s-26">{problemsCount}</span> probleme au fost{' '}
                <NavLink to="/existing-problems">înscrise</NavLink> în total
              </span>
            </span>
          </div>
          <div className="row center counter-container justify-content-center margin-bottom-50">
            <span className="left-counter col-md-3 col-sm-12 font-s-16 d-inline text-left ml-md-0 ml-sm-3">
              <div className="counter-img-wrapper">
                <Counter3Img />
              </div>
              <span className="d-block line-height-20">
                <span className="font-s-26">{totalVotesCount}</span> voturi au fost înregistrate în total
              </span>
            </span>
          </div>
          <div className="row margin-bottom-50">
            <div className="col-md-12 center">
              <h2 className="font-weight-bold">Fazele proiectului, pe scurt:</h2>
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-2 col-sm-12 right">
              <p className="p-short">
                Aflăm cele mai mari provocări ale orașului și le găsim, împreună cu specialiști, o rezolvare
                alternativă, centrată pe nevoile cetățenilor
              </p>
            </div>
            <div className="col-md-10 col-sm-12">
              <img src={proces_scurt} className="img-fluid" />
            </div>
          </div>
          <div className="row margin-bottom-50">
            <div className="col-md-12 center">
              <h2 className="font-weight-bold">Cum facem asta, pe larg?</h2>
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Primul pas, creezi un cont pe platformă</h2>
              <p className="p-20">
                Ai nevoie de el ca să <b>înscrii o problemă</b>, să <b>îți spui opinia despre problemele înscrise</b>,
                să <b>votezi</b> și să <b>dezbați soluțiile finale.</b>{' '}
              </p>
              <br />
              <NavLink to="/create-account">Creează cont &gt;</NavLink>
            </div>
            <div className="col-md-6 center align-items-center">
              {/* <NavLink to="/problem-categories">
                <button>Înscrie o problemă</button>
              </NavLink> */}
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12">
              <img src={proces_1} className="img-fluid" />
            </div>
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Înscrii probleme</h2>
              <p className="p-20">
                Până pe 31 ianuarie 2021 <b>poți înscrie problemele care te afectează</b> cel mai mult din{' '}
                <b>7 domenii strategice ale vieții orașului.</b>
              </p>
              <br />
              <NavLink to="/existing-problems">Vezi problemele înscrise. &gt;</NavLink>
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Comunitatea votează</h2>
              <p className="p-20">
                <b>Toată luna februarie</b>, ieșenii <b>pot vota problema cu care rezonează cel mai mult</b>, pentru
                fiecare din cele <b>7 domenii.</b>
              </p>
              <p className="p-20">
                Astfel vor merge mai departe, pentru <b>evaluarea unei comisii de specialiști</b>, cele{' '}
                <b>relevante pentru un număr cât mai mare de cetățeni.</b>
              </p>
              <br />
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={proces_2} className="img-fluid" />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12">
              <img src={proces_3} className="img-fluid" />
            </div>
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Comisia evaluează</h2>
              <p className="p-20">
                O <b>Comisie tehnică</b> formată din reprezentanți ai <b>Primăriei Iași, Asociația CIVICA</b>, ai
                mediului privat, academic, <b>ONG și specialiști în ​design thinking</b> evaluează principalele probleme
                votate. Scopul este să rămână, în final, <b>7 probleme</b> (câte una pentru fiecare domeniu) care au
                cele mai mari <b>șanse să fie rezolvate în viitorul apropiat</b> printr-o abordare de tip{' '}
                <b>Design Thinking</b> (centrată pe nevoile oamenilor, cu soluții inovatoare, cu aport dinmai multe
                discipline).
              </p>
              <br />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Formăm echipe de lucru</h2>
              <p className="p-20">
                Pentru fiecare din cele <b>7 probleme selectate</b> (în urma votului și a evaluării comisiei) formăm
                <b>echipe de lucru multidisciplinare.</b> Vom invita <b>ieșeni afectați de acele probleme</b> alături de
                <b>specialiști din mediul academic, economic și din administrație.</b> Cele <b>7 echipe de lucru</b> vor
                fi ghidate în procesul de <b>Design Thinking</b> de <b>7 organizații-mentor.</b> Credem că dacă aducem
                împreună oameni cu <b>experiențe și perspective diferite,</b> vom putea găsi{' '}
                <b>soluțiile cele mai potrivite pentru provocările vieții</b> complexe din prezent.
              </p>
              <br />
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={proces_4} className="img-fluid" />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12">
              <img src={proces_5} className="img-fluid" />
            </div>
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Organizăm un Hackathon</h2>
              <p className="p-20">
                După ce s-au{' '}
                <b>
                  documentat despre problemă prin cercetare și interviuri cu cetățenii, echipele de lucru, alături de
                  mentori,
                </b>{' '}
                se întâlnesc <b>pentru a construi soluții.</b> Această etapă se întâmplă într-un eveniment de{' '}
                <b>2 zile de tip Hackathon.</b> Nu vor sta 2 zile încontinuu, desigur. Timp de <b>câteva ore pe zi</b>{' '}
                se vor concentra pe a găsi <b>soluția care răspunde cel mai bine nevoilor reale,</b> care să fie{' '}
                <b>fezabilă și de lungă durată.</b>
              </p>
              <br />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Publicăm soluțiile pentru consultare publică</h2>
              <p className="p-20">
                Odată <b>conturate soluțiile</b> și <b>testate prototipurile,</b> le <b>publicăm pe platformă.</b> Vom
                invita ieșenii să le consulte și să ofere <b>feedback.</b> Vom aștepta{' '}
                <b>sugestii de îmbunătățire, inițiative de implicare în implementare</b> și orice alte{' '}
                <b>informații utile care să ajute la rezolvare.</b> Din nou, <b>ne încredem în forța lui ”împreună”</b>{' '}
                pentru a ne construi o <b>viață mai bună în Iași.</b>
              </p>
              <br />
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={proces_6} className="img-fluid" />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-6 col-sm-12">
              <img src={proces_7} className="img-fluid" />
            </div>
            <div className="col-md-6 col-sm-12 right">
              <h2 className="font-weight-bold my-3">Ne asigurăm că soluțiile sunt puse în practică</h2>
              <p className="p-20">
                Împreună cu <b>Primăria Municipiului Iași</b> și organizațiile interesate de implementare,{' '}
                <b>definim un calendar realist de punere în practică și resursele necesare.</b> Alături de{' '}
                <b>echipele multidisciplinare și organizațiile mentor</b> vom rămâne în dialog constant cu Primăria Iași
                și organizațiile implicate în implementare pentru a <b>susține punerea în practică a soluțiilor</b> și
                depășirea blocajelor ce pot apărea.
              </p>
              <br />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-8 offset-md-2 col-sm-12 offset-sm-0 center">
              <h2 className="font-weight-bold my-3">Monitorizăm și evaluăm implementarea soluțiilor</h2>
              <p className="p-20">
                Vom urmări <b>implementarea planurilor</b> de acțiune alături de echipele multidisciplinare,
                organizațiile mentor, Consiliul Consultativ, dar și orice ieșean direct interesat.{' '}
                <b>Vom publica periodic pe platformă</b> informații despre{' '}
                <b>progresul implementării celor 7 soluții.</b>
              </p>
              <br />
            </div>
          </div>
          <div className="row margin-y-50">
            <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0">
              <hr />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-8 offset-md-2 col-sm-12 offset-sm-0 center">
              <h2 className="font-weight-bold my-3">Un proiect despre comunitate</h2>
              <p className="p-20">Vezi cine sunt oamenii și organizațiile care fac posibil acest proiect</p>
              <br />
              <p className="p-20">
                <a href="/team" target="_blank">
                  Echipa
                </a>
                {' | '}
                <a href="/partners" target="_blank">
                  Parteneri
                </a>
              </p>
            </div>
          </div>
          <div className="row margin-y-50">
            <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0">
              <hr />
            </div>
          </div>
          <div className="row margin-y-100">
            <div className="col-md-8 offset-md-2 col-sm-12 offset-sm-0 center">
              <h2 className="font-weight-bold my-3">Abonează-te la newsletter-ul Soluții Colaborative!</h2>
              <p className="p-20">
                Ca abonat la acest newsletter, rămâi la curent cu noutățile din fiecare etapă a proiectului.
              </p>
              <br />
              <div className="col-md-8 offset-md-2 col-sm-12 offset-sm-0 newsletter">
                <label htmlFor="newsletter-input" className="col-12 text-left p-0">
                  Adresa de e-mail
                </label>
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Introdu adresa de e-mail"
                  onChange={(e) => this.onChangeNewsletterInput(e)}
                  value={newsletterValue}
                />
                <button className="mt-3" onClick={() => this.submitNewsletter()}>
                  Înscrie-te
                </button>
                {showNewsletterSuccess && (
                  <div className="mt-5 alert alert-success" role="alert">
                    Mulțumim că ne ești alături. Te-ai abonat cu succes!
                  </div>
                )}
                {showNewsletterError && (
                  <div className="mt-5 alert alert-danger" role="alert">
                    Ne pare rău, dar ceva nu a funcționat. Te rugam incearca din nou.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isPending: state.problemDetailsVote.isPending,
    problemDetailsVote: state.problemDetailsVote.problemDetailsVote,
    error: state.problemDetailsVote.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProblemDetailsVote: (problemId) => dispatch(getProblemDetailsVote(problemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
