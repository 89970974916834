import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { getChangePasswordStatus, getCheckTokenStatus } from '../../../models/selectors/passwordReset';
import { checkResetPasswordTokenAction, changePasswordAction } from '../../../models/actions/userActions';

import './ChangePassword.scss';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      token: '',
      passwordError: false,
    };
  }

  componentDidMount() {
    const urlResetToken = window.location.pathname.split('/')[2];
    console.log(urlResetToken);
    this.setState({
      token: urlResetToken,
    });
    this.props.checkResetPasswordTokenAction(urlResetToken);
  }

  passwordChange(type, value) {
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

    if (!passwordRegex.test(value)) {
      this.setState({
        passwordError: true,
      });
    } else {
      this.setState({
        passwordError: false,
      });
    }
    if (type === 'password') {
      this.setState({
        password: value,
      });
    } else {
      this.setState({
        confirmPassword: value,
      });
    }
  }

  changePasswordAction() {
    const { password, confirmPassword, token } = this.state;
    const { changePasswordAction } = this.props;

    if (password !== confirmPassword) {
      this.setState({
        passwordError: true,
      });
    } else {
      this.setState(
        {
          passwordError: false,
        },
        () => {
          changePasswordAction(token, password);
        },
      );
    }
  }

  render() {
    const { password, confirmPassword, passwordError } = this.state;
    const { checkTokenPasswordResetStatus, changePasswordStatus } = this.props;

    // console.log(checkTokenPasswordResetStatus);
    return (
      <div className="password-reset-wrapper">
        <div className="force-push-menu-space"></div>
        <div className={`passord-reset-form container`}>
          {checkTokenPasswordResetStatus === 'error' && <Redirect to={{ pathname: '/not-found' }} />}
          {checkTokenPasswordResetStatus && (
            <div className="row">
              {changePasswordStatus === 'success' ? (
                <>
                  <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0 center">
                    <h3 className="font-weight-bold">
                      Gata! Noua parolă este salvată. Clic pe Log in să intri în cont!
                    </h3>
                  </div>
                  <div className="row center m-0">
                    <div className="col-12 p-0 mt-2">
                      <span className="redirect-to-home">
                        <NavLink to="/login">Du-mă la login {'>'}</NavLink>
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="offset-md-3 col-md-6 col-sm-12 offset-sm-0 center">
                  <h3 className="font-weight-bold">Schimbare parolă</h3>
                  <div className="form mt-5">
                    <div className={`form-row mb-3 ${passwordError ? 'error' : ''}`}>
                      <label htmlFor="password">Scrie aici noua parolă</label>
                      <input
                        type="password"
                        name="password"
                        placeholder="******"
                        value={password}
                        onChange={(event) => this.passwordChange('password', event.target.value)}
                        // onBlur={(event) => this.validateEmailField(event.target.value)}
                      />
                    </div>
                    <div className={`form-row mb-3 ${passwordError ? 'error' : ''}`}>
                      <label htmlFor="confirmPassword">Repetă noua parolă și click pe Salvează</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="******"
                        value={confirmPassword}
                        onChange={(event) => this.passwordChange('confirmPassword', event.target.value)}
                        // onBlur={(event) => this.validateEmailField(event.target.value)}
                      />
                    </div>
                    {passwordError && (
                      <div className="error-message font-s-12">
                        Cele două parole trebuie sa coincidă sau nu ai indeplinit urmatoarele conditii: Minim 6
                        caractere, ce includ și un caracter special (@#$%^&)
                      </div>
                    )}

                    <div className="row center m-0">
                      <div className="col-12 p-0 mt-3">
                        <button className="password-reset" type="button" onClick={() => this.changePasswordAction()}>
                          Salvează
                        </button>
                      </div>
                      <div className="col-12 p-0 mt-2">
                        {' '}
                        <span className="register">
                          <NavLink to="/login">Am deja cont {'>'}</NavLink>
                        </span>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    checkTokenPasswordResetStatus: getCheckTokenStatus(state),
    changePasswordStatus: getChangePasswordStatus(state),
  }),
  (dispatch) => ({
    checkResetPasswordTokenAction: (token) => dispatch(checkResetPasswordTokenAction(token)),
    changePasswordAction: (token, password) => dispatch(changePasswordAction(token, password)),
  }),
)(ChangePassword);
