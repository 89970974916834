import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const isUserLoggedIn = () => {
  const authHeader = Cookies.get('SESSION_TOKEN');
  if (!authHeader) {
    return false;
  }
  const userData = jwt_decode(authHeader);

  const { userId, unique_name } = userData;

  return userId !== null && unique_name !== null;
};

export const getIsUserAdmin = () => {
  const authHeader = Cookies.get('SESSION_TOKEN');
  if (!authHeader) {
    return false;
  }

  const userData = jwt_decode(authHeader);
  // const userData = jwt_decode(authHeader);

  const { userId, unique_name, userType } = userData;
  return userId && unique_name && userType === 'Admin';
};
