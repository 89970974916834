import pattern1 from './patterns/pattern-1.png';
import pattern2 from './patterns/pattern-2.png';
import pattern3 from './patterns/pattern-3.png';
import pattern4 from './patterns/pattern-4.png';
import pattern5 from './patterns/pattern-5.png';
import pattern6 from './patterns/pattern-6.png';
import pattern7 from './patterns/pattern-7.png';

const patterns = {
  pattern1,
  pattern2,
  pattern3,
  pattern4,
  pattern5,
  pattern6,
  pattern7,
};

export default patterns;
