import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './components/Static/Header/Header';
import Footer from './components/Static/Footer/Footer';
import { getUserStatus } from './models/selectors/login';
import { setUserStatusAction } from './models/actions/userActions';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import CreateAccount from './components/User/CreateAccount/CreateAccount';
import PasswordReset from './components/User/PasswordReset/PasswordReset';
import ChangePassword from './components/User/ChangePassword/ChangePassword';
import MyProfile from './components/User/MyProfile/MyProfile';
import ExistingProblems from './components/Problems/ExistingProblems/ExistingProblems';
import About_overview from './components/Static/About/About_overview';
import About_project from './components/Static/About/About_project';
import Contribute from './components/Static/Contribute/Contribute';
import Login from './components/User/Login/Login';
// import RegisterProblem from './components/Problems/RegisterProblem/RegisterProblem';
import Logout from './components/User/Logout/Logout';
import Team from './components/Static/Team/Team';
import Partners from './components/Static/Partners/Partners';
import Contact from './components/Static/Contact/Contact';
import TermsAndConditions from './components/Static/TermsAndConditions/TermsAndConditions';
import CookiePolicy from './components/Static/CookiePolicy/CookiePolicy';
import PersonalData from './components/Static/PersonalData/PersonalData';
import Regulations from './components/Static/Regulations/Regulations';
import Homepage from './components/Static/Homepage/Homepage';
import NotFoundPage from './components/Static/NotFoundPage/NotFoundPage';
import RegisterPeriod from './components/User/RegisterPeriod/RegisterPeriod';
import VotingPeriod from './components/User/VotingPeriod/VotingPeriod';
import CheckingPeriod from './components/User/CheckingPeriod/CheckingPeriod';
import SolvingPeriod from './components/User/SolvingPeriod/SolvingPeriod';
import Dashboard from './components/User/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main.scss';
import AccountConfirm from './components/User/CreateAccount/AccountConfirm';
import { Route, Switch } from 'react-router-dom';
import ProblemDetails from './components/Problems/ProblemDetails/ProblemDetails';
import ProblemDetailsVote from './components/Problems/ProblemDetailsVote/ProblemDetailsVote';
// import ProblemCategories from './components/Problems/ProblemCategories/ProblemCategories';
import ProblemCategoriesVote from './components/Problems/ProblemCategoriesVote/ProblemCategoriesVote';
import Settlement from './components/Static/Settlement/Settlement';
import VoteResults from './components/Problems/VoteResults/VoteResults';
import { getUserData } from './models/selectors/users';
import { isUserLoggedIn } from './components/User/UserUtils';

class App extends Component {
  componentDidMount() {
    const isUserAuthorized = isUserLoggedIn();
    if (!isUserAuthorized) {
      this.props.setUserStatus();
    }
  }

  render() {
    const { userStatus, userData } = this.props;
    return (
      <>
        <div className="main-wrapper">
          <Header userStatus={userStatus} userData={userData} />
          <Switch>
            <Route exact path="/create-account" component={CreateAccount} />
            <Route exact path="/password-reset" component={PasswordReset} />
            <Route path="/account-reset" component={ChangePassword} />
            <Route exact path="/existing-problems" component={ExistingProblems} />
            <Route path="/problems" component={ProblemDetails} />
            <Route path="/vote" component={ProblemDetailsVote} />
            <Route exact path="/about" component={About_overview} />
            <Route exact path="/about-project" component={About_project} />
            <Route exact path="/how-to-contribute" component={Contribute} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/cookie-policy" component={CookiePolicy} />
            <Route exact path="/personal-data" component={PersonalData} />
            <Route exact path="/regulations" component={Regulations} />
            <Route exact path="/account/validate/:id" component={AccountConfirm} />
            <Route exact path="/solutionare" component={Settlement} />
            <Route exact path="/login" component={Login} />
            {/* <Route path="/problem-categories" component={ProblemCategories} /> */}
            {/* <Route path="/vote-problem-categories" component={ProblemCategoriesVote} /> */}
            <Route exact path="/vote-results" component={VoteResults} />

            <PrivateRoute path="/register-period" component={RegisterPeriod} />
            <PrivateRoute path="/voting-period" component={VotingPeriod} />
            <PrivateRoute path="/checking-period" component={CheckingPeriod} />
            <PrivateRoute path="/solving-period" component={SolvingPeriod} />
            {/* <PrivateRoute path="/register-problem" component={RegisterProblem} /> */}
            <PrivateRoute path="/logout" component={Logout} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/my-profile" component={MyProfile} />
            <Route exact path="/" component={Homepage} />
            <Route exact path="*" component={NotFoundPage} />
            <Route exact path="/not-found" component={NotFoundPage} />
          </Switch>
        </div>
        <Footer />
      </>
    );
  }
}

export default connect(
  (state) => ({
    userStatus: getUserStatus(state),
    userData: getUserData(state),
  }),
  (dispatch) => ({
    setUserStatus: () => dispatch(setUserStatusAction()),
  }),
)(App);
