import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  onUserLoginAction,
  onUserGoogleLoginAction,
  onUserFacebookLoginAction,
} from '../../../models/actions/userActions';
import { getUserStatus } from '../../../models/selectors/login';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { config } from '../../../models/api/apiConfig';

import SocialLogin from '../../Static/SocialLogin/SocialLogin';

import './LoginForm.scss';

export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const validataEmail = (email) => emailPattern.test(email);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      isFormValid: true,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeyDown, false);
  }

  onEmailChange(email) {
    this.setState({
      email: email.split(' ').join(''),
      emailError: '',
    });
  }

  validateEmailField() {
    const { email } = this.state;
    this.setState({
      emailError: !validataEmail(email),
    });
  }

  onPasswordChange(password) {
    this.setState({
      password,
    });
  }

  validatePasswordField() {
    const { password } = this.state;
    if (password.toString().length < 3) {
      return this.setState({
        passwordError: true,
      });
    }

    return this.setState({
      passwordError: false,
    });
  }

  onUserLoginHandler() {
    const { email, password, emailError, passwordError } = this.state;
    const { onUserLogin } = this.props;

    this.validateEmailField();
    this.validatePasswordField();

    const isFormValid = !emailError && !passwordError && validataEmail(email);
    if (!isFormValid) {
      return this.setState({
        isFormValid,
      });
    }

    const userData = {
      userEmail: email,
      userPassword: password,
    };

    onUserLogin(userData);
  }

  responseGoogleSuccess(response) {
    const { tokenId } = response;

    this.props.onUserGoogleLoginAction(tokenId);
  }

  responseGoogleFailure(error) {
    console.log('Google Login error:');
    console.log(error);
  }

  responseFacebook(response) {
    const { accessToken } = response;

    this.props.onUserFacebookLoginAction(accessToken);
  }

  facebookClicked(response) {
    console.log('FB clicked');
    console.log(response);
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onUserLoginHandler();
    }
  };

  render() {
    const { userStatus } = this.props;
    const { email, password, emailError, passwordError } = this.state;
    const invalidCretentials = userStatus === 'authfail' ? 'active' : '';
    const invalidGoogleLogin = userStatus === 'googleAuthFail' ? 'active' : '';
    const invalidFacebookLogin = userStatus === 'facebookAuthFail' ? 'active' : '';

    return (
      <div className={`login-form container`}>
        <div className="row">
          <div className="offset-md-3 col-md-6 col-sm-12 offset-sm-0">
            <h2 className="font-weight-bold">Accesează platforma</h2>
            {invalidCretentials && (
              <div className={`submit-error ${invalidCretentials}`}>
                <p>Adresa de e-mail sau parola invalide</p>
              </div>
            )}
            {invalidGoogleLogin && (
              <div className={`submit-error ${invalidGoogleLogin}`}>
                <p>Autentificarea cu Google a esuat.</p>
              </div>
            )}
            {invalidFacebookLogin && (
              <div className={`submit-error ${invalidFacebookLogin}`}>
                <p>Autentificarea cu Facebook a esuat.</p>
              </div>
            )}
            <div className="form">
              <div className={`form-row ${emailError ? 'error' : ''}`}>
                <label htmlFor="email">Adresa de e-mail</label>
                <input
                  type="text"
                  name="email"
                  placeholder="adresa@exemplu.ro"
                  value={email}
                  onChange={(event) => this.onEmailChange(event.target.value)}
                  onBlur={() => this.validateEmailField()}
                />
              </div>
              <div className={`form-row ${passwordError ? 'error' : ''}`}>
                <label htmlFor="password">Parola</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event) => this.onPasswordChange(event.target.value)}
                  onBlur={() => this.validatePasswordField()}
                  className={passwordError ? 'error' : ''}
                />
              </div>
              <div className="row center m-0">
                <div className="col-12 p-0">
                  <button className="login" type="button" onClick={() => this.onUserLoginHandler()}>
                    Login
                  </button>
                </div>
                <div className="col-12 p-0 mt-3">
                  <GoogleLogin
                    clientId={config.googleId}
                    buttonText="Login"
                    onSuccess={(response) => this.responseGoogleSuccess(response)}
                    onFailure={(error) => this.responseGoogleFailure(error)}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                    render={(renderProps) => (
                      <button
                        className="login-with-google-btn"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        Login cu Google
                      </button>
                    )}
                  />
                </div>
                <div className="col-12 p-0 mt-3">
                  <FacebookLogin
                    appId={config.facebookId}
                    autoLoad={false}
                    textButton="Login cu Facebook"
                    fields="name,email,picture"
                    onClick={(response) => this.facebookClicked(response)}
                    callback={(response) => this.responseFacebook(response)}
                    render={(renderProps) => <button onClick={renderProps.onClick}>Login cu Facebook</button>}
                  />
                </div>
                <div className="col-12 p-0 mt-3">
                  <button className="password-reset" type="button">
                    <NavLink to="/password-reset">Schimbă parola</NavLink>
                  </button>
                </div>
                <div className="col-12 p-0 mt-2">
                  {' '}
                  <span className="register">
                    Nu ai cont? <br />{' '}
                    <NavLink to="/create-account">Creează-ți un cont pentru a înregistra o problemă {'>'}</NavLink>
                  </span>
                </div>
              </div>
              <div></div>
              {/* <SocialLogin /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userStatus: getUserStatus(state),
  }),
  (dispatch) => ({
    onUserLogin: (userData) => dispatch(onUserLoginAction(userData)),
    onUserGoogleLoginAction: (tokenId) => dispatch(onUserGoogleLoginAction(tokenId)),
    onUserFacebookLoginAction: (accessToken) => dispatch(onUserFacebookLoginAction(accessToken)),
  }),
)(LoginForm);
