import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUserStatus } from '../../models/selectors/login';
import { isUserLoggedIn } from '../User/UserUtils';

const PrivateRoute = (props) => {
  const { component } = props;
  const Component = component;
  const isUserAuthorized = isUserLoggedIn();
  return isUserAuthorized ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />;
};

export default connect((state) => ({
  userStatus: getUserStatus(state),
}))(PrivateRoute);
