import { api } from '../api/api';

export function getProblemsListAction() {
  return function (dispatch) {
    api
      .get('admin/problem')
      .then((response) => dispatch({ type: 'GET_PROBLEMS_LIST_SUCCESS', problemsList: response.problems }));
  };
}

export function getUsersListAction() {
  return function (dispatch) {
    api.get('admin/user').then((response) => dispatch({ type: 'GET_USERS_LIST_SUCCESS', usersList: response.users }));
  };
}

export function getUserDetails(userId) {
  return function (dispatch) {
    api
      .get(`admin/user/${userId}`)
      .then((response) => dispatch({ type: 'GET_USER_DETAILS_SUCCESS', userDetails: response }));
  };
}

export function approveProblemAction(problemID) {
  return function (dispatch) {
    api.post(`admin/problem/accept/${problemID}`).then((response) => {
      if (response.status === 200) {
        api
          .get('admin/problem')
          .then((response) => dispatch({ type: 'GET_PROBLEMS_LIST_SUCCESS', problemsList: response.problems }));
      } else {
        dispatch({ type: 'PROBLEM_ACCEPTED_FAILURE' });
      }
    });
  };
}

export function rejectProblemAction(problemID) {
  return function (dispatch) {
    return api.post(`admin/problem/reject/${problemID}`).then((response) => {
      if (response.status === 200) {
        api
          .get('admin/problem')
          .then((response) => dispatch({ type: 'GET_PROBLEMS_LIST_SUCCESS', problemsList: response.problems }));
      }
    });
  };
}

export function getProblemDetailsAction(problemID) {
  return function (dispatch) {
    dispatch({ type: 'GET_PROBLEM_DETAILS_REQUEST' });
    return api.get(`admin/problem/${problemID}`).then((response) => {
      dispatch({ type: 'GET_PROBLEM_DETAILS_SUCCESS', problemDetails: response });
    });
  };
}
