import createReducer from '../store/createReducer';

const initialState = {
  isProblemsLoading: true,
  existingProblems: [],
};

function GET_EXISTING_PROBLEMS_REQUEST(nextState) {
  nextState.isProblemsLoading = true;
}

function GET_EXISTING_PROBLEMS_SUCCESS(nextState, action) {
  nextState.isProblemsLoading = false;
  nextState.existingProblems = action.existingProblems;
}

function GET_EXISTING_PROBLEMS_FAILURE(nextState) {
  nextState.isProblemsLoading = false;
  nextState.existingProblems = [];
}

export default createReducer(initialState, {
  GET_EXISTING_PROBLEMS_REQUEST,
  GET_EXISTING_PROBLEMS_SUCCESS,
  GET_EXISTING_PROBLEMS_FAILURE,
});
