import { api } from '../api/api';
import {
  PUT_CHANGE_PROFILE_LOADING,
  PUT_CHANGE_PROFILE_SUCCESS,
  PUT_CHANGE_PROFILE_ERROR,
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  GET_MY_PROBLEMS_LOADING,
  GET_MY_PROBLEMS_SUCCESS,
  GET_MY_PROBLEMS_ERROR,
} from '../constants';

export function changeProfile(profileDetails) {
  return (dispatch) => {
    dispatch(changeProfilePending(true));
    api
      .put('account', profileDetails)
      .then((response) => {
        console.log(response);
        dispatch(changeProfilePending(false));
        dispatch(changeProfileSuccess(true));
      })
      .catch((error) => {
        dispatch(changeProfilePending(false));
        dispatch(changeProfileError(true));
      });
  };
}

export function getProfile() {
  return (dispatch) => {
    dispatch(getProfilePending(true));
    api
      .get('account')
      .then((response) => {
        dispatch(getProfilePending(false));
        dispatch(getProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(getProfilePending(false));
        dispatch(getProfileError(true));
      });
  };
}

export function getMyProblems() {
  return (dispatch) => {
    dispatch(getMyProblemsPending(true));
    api
      .get('ownproblem')
      .then((response) => {
        dispatch(getMyProblemsPending(false));
        dispatch(getMyProblemsSuccess(response));
      })
      .catch((error) => {
        dispatch(getMyProblemsPending(false));
        dispatch(getMyProblemsError(true));
      });
  };
}

const changeProfilePending = (status) => ({
  type: PUT_CHANGE_PROFILE_LOADING,
  isSavePending: status,
});

const changeProfileSuccess = (response) => ({
  type: PUT_CHANGE_PROFILE_SUCCESS,
  payload: response,
});

const changeProfileError = (error) => ({
  type: PUT_CHANGE_PROFILE_ERROR,
  error,
});

const getProfilePending = (status) => ({
  type: GET_PROFILE_LOADING,
  isPending: status,
});

const getProfileSuccess = (response) => ({
  type: GET_PROFILE_SUCCESS,
  payload: response,
});

const getProfileError = (error) => ({
  type: GET_PROFILE_ERROR,
  error,
});

const getMyProblemsPending = (status) => ({
  type: GET_MY_PROBLEMS_LOADING,
  isPending: status,
});

const getMyProblemsSuccess = (response) => ({
  type: GET_MY_PROBLEMS_SUCCESS,
  payload: response,
});

const getMyProblemsError = (error) => ({
  type: GET_MY_PROBLEMS_ERROR,
  error,
});
