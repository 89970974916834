import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './Team.scss';
import teamImages from '../../../assets/images/team';

class Team extends Component {
  componentDidMount() {
    const idOfElemFromUrl = window.location.href.split('#')[1];

    if (idOfElemFromUrl) {
      const elemPositionTop = this.getOffset(document.getElementById(idOfElemFromUrl)).top;

      setTimeout(() => {
        window.scroll(0, elemPositionTop - 60);
      }, 500);
    } else {
      window.scroll(0, 0);
    }
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  render() {
    return (
      <div className="team-wrapper">
        <div className="team">
          <div className="row" id="echipa-executiva">
            <div className="col-lg-5 col-md-8 col-sm-12">
              <h1>Cine suntem noi, oamenii din spatele Soluțiilor colaborative?</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 team-executive">
              <NavLink to="/team#echipa-executiva"></NavLink>
              <h2>Echipa Executivă</h2>
              <p>
                Aceștia suntem oamenii direct implicați în punerea în practică a proiectului Soluții colaborative pentru
                comunitate. Planificăm, organizăm și implementăm cu sprijinul experților și sub îndrumarea Consiliului
                Consultativ activitățile care, la final, generează cele mai potrivite soluții pentru problemele
                relevante ale orașului.
              </p>
              <br />
              <h3>Asociația CIVICA</h3>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_alexandrina_dinga} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Alexandrina Dinga </p>
              </div>
              <div className="row">
                <p className="title">Manager proiect</p>
              </div>
              <div className="row">
                <p className="bio">
                  Alexandrina este președinte executiv al Asociației CIVICA și are experiență de peste 10 ani în
                  sectorul non-profit lucrând în proiecte de organizare și dezvoltare comunitară, advocacy, educație
                  civică și transparență decizională. Este interesată de guvernarea deschisă și dezvoltarea urbană.
                </p>
                <p className="bio">
                  Pentru ea, un Iași în care îi place să trăiască înseamnă un oraș în care cetățenii au inițiativă și
                  colaborează și între ei, și cu autoritățile.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_raluca_marcu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Raluca Marcu</p>
              </div>
              <div className="row">
                <p className="title">Coordonator organizare evenimente</p>
              </div>
              <div className="row">
                <p className="bio">
                  Raluca este absolventă de Comunicare și Relații Publice. În urmă cu 10 ani a luat contact, pentru
                  prima dată, cu mediul ongistic și de atunci, a activat în mai multe organizații non-guvernamentale din
                  Iași și din țară. Când nu vorbește, scrie. Iar când nu scrie, citește sau desenează. Pentru ea, Iașul
                  în care dorește să trăiască este un oraș atent la nevoile cetățenilor, grijuliu la grupurile
                  vulnerabile și ambițios prin excelență.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_florin_apetrei} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Florin Apetrei</p>
              </div>
              <div className="row">
                <p className="title">Coordonator realizare design</p>
              </div>
              <div className="row">
                <p className="bio">
                  Florin este designer de peste 15 ani, dezvoltând proiecte digitale și platforme pentru organizații
                  mari sau startups, aducând o experiență plăcută și prietenoasă în folosirea tehnologiei cu fiecare
                  oportunitate. Crede în “tech for good”.
                </p>
                <p className="bio">
                  Iașul în care i-ar plăcea să trăiască este unul împăcat cu identitatea sa, primitor, și care continuă
                  să ofere oportunități de creștere în timp ce se poate “pune și umărul” la continua sa dezvoltare.
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_gabriel_marchidan} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Gabriel Marchidan</p>
              </div>
              <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div>
              <div className="row">
                <p className="bio">
                  Gabriel a început ca programator acum 13 ani și a migrat către arhitecturi software. În tot acest timp
                  a rămas pasionat de inteligență artificială și de web. Gabriel crede că Iașul este deja o forță pe
                  partea de dezvoltare software și consideră că are toate șansele să ajungă un exemplu de digitalizare.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_alin_pavel} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Sorin-Alin Pavel</p>
              </div>
              {/* <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div> */}
              <div className="row">
                <p className="bio">Alin este un programator pasionat de web de peste 10 ani.</p>
                <p className="bio">
                  Pentru Alin, Iașul trebuie să fie un oraș orientat către toate opiniile, dar mai ales către tineri,
                  deoarece forța pe care o caută multe alte orașe se regăseste în ei.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_roxana_irinei} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Roxana Irinei</p>
              </div>
              {/* <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div> */}
              <div className="row">
                <p className="bio">
                  Roxana lucrează ”în IT”, un domeniu cu multe provocări, oportunități și satisfacții. Crede că ”IT-ul”
                  poate face lumea mai bună și se bucură că Iași vrea să crească din punct de vedere digital. Îi place
                  să fie parte din soluție mai degrabă decât din problemă, așa că a decis să ajute schimbarea Iașului în
                  bine, să-l ajute să devină un oraș mai prietenos cu cetățenii.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_irina_theodoru} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Irina Theodoru</p>
              </div>
              <div className="row"></div>
              <div className="row">
                <p className="bio">
                  Irina este copywriter și specialist în comunicare de peste 10 ani. S-a implicat, de-a lungul anilor,
                  în numeroase proiecte și inițiative ale unor ONG-uri locale și naționale. Pentru ea, Iașul în care îi
                  place să trăiască este cel în care se poate bucura de timp de calitate alături de familie și prieteni,
                  de locuri cu poveste care să o inspire și de multă verdeață.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_amelia_mistreanu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Amelia Mistreanu</p>
              </div>
              <div className="row">{/* <p className="title">Coordonator organizare evenimente</p> */}</div>
              <div className="row">
                <p className="bio">
                  Amelia este în mediul ONG-ist de 5 ani, activând, până să se alăture echipei CIVICA, atât în
                  organizații locale de tineret, cât și în organizații naționale, de reprezentare a studenților și de
                  mediu.
                </p>
                <p className="bio">
                  A început ca voluntar, bucurându-se din plin de spațiul de învățare, creare și experimentare oferit de
                  asociațiile studențești. A fost implicată în dezvoltarea și implementarea a zeci de proiecte și
                  activități, majoritatea pentru tineri, în fiecare dintre acestea fiind la purtător cu grija față de
                  sens, cu elanul și răbdarea de a face lucrurile să se întâmple cu bine.
                </p>
                <p className="bio">
                  Iașul în care i-ar plăcea să trăiască este cel în care tinerii au spațiu și resursele necesare
                  explorării și împlinirii potențialului lor.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_cosmina_hatmanu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Cosmina Hatmanu</p>
              </div>
              {/* <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div> */}
              <div className="row">
                <p className="bio">
                  Cosmina este fotograf și consilier juridic definitiv. În ultimii 5 ani, Cosmina a participat în mod
                  activ în activitățile comunității ieșene, prin intermediul proiectelor demarate de ONG-urile locale,
                  precum Asociația CIVICA și Fundația Comunitară Iași. A început ca voluntar în cadrul asociației
                  studențești ELSA, dorind mereu a-și îmbunătăți aptitudinile și a dobândi noi abilități. Iașul în care
                  i-ar plăcea să trăiască este un Iași cu multe locuri verzi, care pe timp de toamnă ar deveni adevărate
                  tablouri, pe care le-ar putea captura pentru eternitate, prin lentila sa.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_adrian_baltariu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Adrian Baltariu</p>
              </div>
              <div className="row">
                <p className="bio">
                  Adrian este fotograf cu experiență de peste zece ani. Este un membru activ al comunității, valorizând
                  educația despre faună și protejarea acesteia. Printre proiectele în care se implică pentru documentare
                  foto se numără și evenimente, festivaluri, colaborări individuale, dar și fotografie de strada.
                </p>
                <p className="bio">
                  Adrian participă activ și la proiecte independente ce au ca scop sprijinul persoanelor din
                  comunitățile rurale fără acces la resurse.
                </p>
                <p className="bio">
                  Iașul în care i-ar plăcea să trăiască este unul deschis spre implicare, sprijină respectul față de
                  zonele naturale și contribuie la conștientizarea importanței acestora pentru bunăstarea tuturor.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_vlad_samson} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Vlad Samson</p>
              </div>
              {/* <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div> */}
              <div className="row">
                <p className="bio">
                  Vlad este designer, pasionat de găsirea soluțiilor în comunicarea grafică. Iașul în care i-ar plăcea
                  să trăiască este unul în care responsabilitatea construiește mediul.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_sergiu_ojoc} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Sergiu Ojoc</p>
              </div>
              {/* <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div> */}
              <div className="row">
                <p className="bio">
                  Sergiu este un programator de peste 12 ani. De-a lungul experienței, a avut oportunitatea de a lucra
                  pe diverse proiecte de complexitate diversă, fiecare proiect având provocările tehnice aferente. Crede
                  în munca bine făcută și în dedicația totală față de ceea ce ți-ai asumat mai mult decât în rezultatele
                  pe termen scurt.
                </p>
                <p className="bio">
                  Iașul în care i-ar plăcea să trăiască – este chiar Iașiul curent, destul de matur din punct de vedere
                  IT. Un Iași ce oferă posibilități atât celor cu experiență, cât și celor care sunt la primii pași în
                  cariera. Bineînțeles, lucruri de îmbunătățit există tot timpul...
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_liviu_atudorei} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Liviu Atudorei</p>
              </div>
              {/* <div className="row">
                <p className="title">Coordonator dezvoltare website</p>
              </div> */}
              <div className="row">
                <p className="bio">
                  Liviu este designer, axat pe echilibrul dintre estetic și funcțional în rezolvarea problemelor de
                  design.
                </p>
                <p className="bio">
                  Iașul în care i-ar plăcea să trăiască este unul cu o identitate bine conștientizată și bine definită
                  ce oferă orașului aptitudinile de a se individualiza și expune de la sine.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-5 col-md-8 col-sm-12">
              <h3>Voluntari CIVICA</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_alina_batcu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Alina Batcu </p>
              </div>
              <div className="row">
                <p className="bio">
                  Alina este jurnalist de aproximativ 4 ani. În prezent este reporter la Radio VIVA FM și corespondent
                  „Adevărul”. Și, așa cum îi place ei să spună, este un „voluntar fericit”.
                </p>
                <p className="bio">
                  Pentru ea, Iașul în care îi place să trăiască este un Iași pentru oameni. Un Iași în care spațiile
                  verzi sunt mari și dese, iar mijloacele de transport în comun sunt accesibile tuturor. Un Iași care se
                  şi ne respectă.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_roxana_cojocaru} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Roxana Cojocaru</p>
              </div>
              <div className="row">
                <p className="bio">
                  Roxana Cojocaru este pasionată de marketing digital și comunicare, iar de curând și-a descoperit
                  bucuria de a scrie. A început să cunoască pe îndelete Iașul în primul an de studenție și de atunci
                  orașul acesta a devenit noul „acasă”. Iașul în care i-ar plăcea să trăiască este un oraș care își
                  valorifică la maximum potențialul cultural, pe măsura renumelui și a istoriei sale.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_laura_cozmanca} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Laura Cozmîncă</p>
              </div>
              <div className="row">
                <p className="bio">
                  Este studentă în anul 3 la Jurnalism și de aproape doi ani cochetează cu activitatea de copywriting
                  independent. Scrie și asta o face fericită. Și-ar dori ca prin condeiul ei să poată aduce un bine cât
                  de mic într-o comunitate.
                </p>
                <p className="bio">
                  Dacă ar fi să se ghidez după un motto, acela ar fi că fiecare om poartă cu sine o poveste și că lumea,
                  în general, ar fi un loc mai bun dacă pur și simplu ne-am face timp să le ascultăm și să le înțelegem.
                  Iașul în care i-ar plăcea să trăiască este un Iași în care oamenii se respectă mai mult unii pe alții.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_ana_maria_l} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Anamaria Lăzăruc</p>
              </div>
              <div className="row">
                <p className="bio">
                  Anamaria este o tânără implicată, creativă și responsabilă. Are o experiență bogată în proiecte
                  internaționale și naționale, iar voluntariatul reprezintă pentru ea sursa de energie și de motivație
                  încă de la începutul liceului. În prezent este studentă la Facultatea de Psihologie și Științe ale
                  Educației, iar unul dintre obiectivele ei este să contribuie la micile schimbări din comunitate, care
                  pot îmbunătăți viața celor din jur.
                </p>
                <p className="bio">
                  Pentru ea, Iașiul în care își dorește să trăiască este un oraș curat, aerisit și bine organizat. Își
                  dorește spații verzi, străzi asfaltate și curate și înlocuirea griului cotidian cu lucrări de street
                  art. Un oraș vibrant, în care fiecare dintre noi să găsească bucuria vieții de zi cu zi.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_ioana_ursache} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Ioana Ursache</p>
              </div>
              <div className="row">
                <p className="bio">
                  Ioana este geograf și studentă la master la Centrul de Studii Europene. A studiat la Londra
                  "Dezvoltare Urbană" și dorește să aplice la Iași cunoștințele dobândite acolo.
                </p>
                <p className="bio">
                  Iașul în care îi place să trăiască este un oraș bine administrat și plin de vitalitate. Un Iași pentru
                  oameni care oferă posibilitatea de a lua parte și a te bucura de viața urbană, dar mai ales în care
                  ieșenii au dreptul de a se schimba pe ei prin schimbările aduse orașului lor.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_georgiana_riglea} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Georgiana Riglea</p>
              </div>
              <div className="row">
                <p className="bio">
                  Geo este în primul rând, un om al oamenilor, este o persoană empatică și dornică de a ajuta, cu focus
                  pe a înțelege nevoile reale ale oamenilor
                </p>
                <p className="bio">
                  În urmă cu 8 ani și-a ales drumul, urmându-și pasiunea în domeniul marketingului. În prezent este
                  Marketing specialist și viitor profesor, preocupările ei sunt diverse, de la echitatea în educație, la
                  sustenabilitate în industria fashion și design interior sustenabil. Iașul în care îi place să trăiască
                  este un oraș creativ și sustenabil, care oferă cetățenilor, șanse egale la educație, oportunități și
                  mai ales echilibru.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-5">
            <div className="col-lg-5 col-md-8 col-sm-12">
              <h3>Reprezentanți ai Primăriei Iași</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_dan_postolea} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Dan Postolea </p>
              </div>
              <div className="row">
                <p className="title">Șef birou comunicare, Primăria Muncipiului Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Un oraș tânăr, prosper, civilizat, atractiv, vibrant, prietenos cu cetățenii și din ce în ce mai smart
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_alexandru_chelaru} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Alexandru Chelaru</p>
              </div>
              <div className="row">
                <p className="title">Șef birou relații cu societatea civilă, Primăria Municipiului Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Pentru mine, un oraș adaptat necesităților actuale ale locuitorilor, astfel încât să aibă un nivel
                  ridicat de accesibilitate și servicii cât mai calitative, este orașul în care îmi place să trăiesc; un
                  oraș definit de creativitate, pentru că doar așa va putea să rețină minți inteligente, fiind, în
                  același timp, atrăgător pentru vizitatori, investitori, dar și locuitori.
                </p>
                <p className="bio">
                  Acestea sunt aspectele pe care le consider importante pentru evoluția unui oraș, și sunt încântat că
                  Iașul se îndreaptă în direcția potrivită.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12"></div>
          </div>

          <div className="row mt-5 mb-5 team-experts" id="experti">
            <div className="col-lg-9 col-md-9 col-sm-12">
              <NavLink to="/team#experti"></NavLink>
              <h2>Experți</h2>
              <p>
                Ei sunt profesioniștii care ne împrumută din expertiza lor în guvernare deschisă, inovare publică și
                Design Thinking. Cu ajutorul lor ne asigurăm că prioritățile comunității ieșene își găsesc rezolvări
                inovatoare, centrate pe oameni și construite în mod participativ, prin colaborarea administrației cu
                cetățenii.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_adelina_dabu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Adelina Dabu </p>
              </div>
              <div className="row">
                <p className="title">expert afaceri publice și sustenabilitate, Președinte Asociația pentru Inovație</p>
              </div>
              <div className="row">
                <p className="bio">
                  Orașul în care mi-ar plăcea să trăiesc este o comunitate în natură unde care fiecare individ își poate
                  dezvolta creativitatea într-un context de încredere și colaborare. Este un oraș tehnologizat cu
                  soluții care pun omul și natura în centru. Valorile pe care le promovează nu ne sunt necunoscute,
                  ne-am întâlnit cu ele în mediul rural: vecinătatea, conectarea la natură, producție locală, resurse
                  regenerabile, circularitate, etc. Ce-ar fi să avem micro păduri printre blocurile printate 3D și
                  parcurile să fie populate cu animale și să gătim cu legume de sezon din grădini urbane în bucătării
                  comunitare?
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_tudor_juravlea} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Tudor Juravlea</p>
              </div>
              <div className="row">
                <p className="title">expert design thinking și UX design, Co-fondator Design Thinking Society</p>
              </div>
              <div className="row">
                <p className="bio">
                  Orașul în care mi-ar plăcea să trăiesc ar fi un oraș multicultural și divers, guvernat direct de
                  locuitorii lui - în care oamenii să poată măsura și îmbunătăți în timp real efectul propriilor
                  decizii. Mi-aș dori să fie un oraș care să prospere din propriile resurse, fără un impact negativ
                  asupra mediului. Un oraș în care tehnologia să fie omniprezentă dar invizibilă - în care oamenii să
                  simtă natura aproape și în care să poată face mișcare. Ar fi un oraș în care fiecare spațiu construit
                  ar încuraja o viață mai sănătoasă și mai echilibrată pentru locuitori. În orașul meu ideal spațiile
                  publice ar fi pentru oameni nu pentru mașini iar trotuarele, piețele și parcurile ar fi locuri pentru
                  comunități, dezbatere, colaborare și experimentare.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_oana_olariu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">asist. univ. dr. Oana Olariu</p>
              </div>
              <div className="row">
                <p className="title">
                  expert comunicare și guvernare deschisă, Jurnalism, Facultatea de Litere, UAIC Iași
                </p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul meu de suflet e orizontal și uman. Nu știu ce anume se întâmplă zi de zi, dar știu cum. Știu cum
                  se întâmplă lucrurile. E ca acasă. Suntem atât de diferiți, încât pare c-am venit fiecare de pe altă
                  planetă. Și-avem mare încredere în diferență. Slavă Domnului, realitatea e atât de complexă, încât ar
                  fi un calvar să acționăm mereu, doar după capul unora dintre noi. Știm cu toții ce se întâmplă în jur
                  și nu căutăm vinovați. Asta drenează energia. Acționăm, creăm, mai greșim uneori și-apoi facem mai
                  bine. Acoperim lipsurile unora cu preaplinul altora. Fiecare dintre noi contează, fără să fie
                  împovărat cineva. Avem timp. Nu mi se pune niciodată un nod în gât, fiindcă nu trebuie să explic de ce
                  reziliența nu e o piramidă, ci o țesătură, pe cât de densă, pe atât de elastică, din firele tuturor.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_radu_puchiu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Radu Puchiu </p>
              </div>
              <div className="row">
                <p className="title">expert guvernare deschisă, Open Government Partnership Envoy</p>
              </div>
              <div className="row">
                <p className="bio">
                  Orașul în care mi-ar plăcea să trăiesc e un oraș deschis. Deschis la un dialog onest cu oamenii din
                  comunitate, deschis la idei și soluții venite din partea locuitorilor săi, deschis la nou și la
                  posibilitățile oferite de tehnologie pentru a crește calitatea vieții. Un oraș căruia îi pasă, care
                  apreciază implicarea și care include pe toată lumea în planurile sale. Un oraș fericit, cu oameni
                  bucuroși că pot contribui la fericirea lui.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_ovidiu_voicu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Ovidiu Voicu</p>
              </div>
              <div className="row">
                <p className="title">
                  expert inovare publică și guvernare deschisă, Director executiv Centrul pentru Inovare Publică
                </p>
              </div>
              <div className="row">
                <p className="bio">
                  Poate e nostalgia vârstei, dar îmi e dor de orașul copilăriei, în care puteam să plec cu orele singur
                  de-acasă – cu cheia de gât, cum se zicea – și să fac o mulțime de lucruri interesante, să îmi descopăr
                  cartierul și, mai departe, orașul, fără teamă că ceva rău se va întâmpla. Aș vrea să poată face asta
                  și copilul meu. În primul rând, vreau să locuiesc într-un oraș cu oameni buni, care se ajută, care se
                  salută și vorbesc între ei, care nu sunt amenințători unii cu alții și mai ales cu copiii. Nu vreau
                  neapărat un Oraș Inteligent, ci mai ales un Oraș Bun, un oraș al oamenilor. Mai puțin un oraș al
                  mașinilor și al locurilor de parcare, mai puțin un oraș al betoanelor și al bordurilor. Un oraș în
                  care un copil poate să se dea cu tramvaiul de la un capăt la altul, minunându-se de câte vede, sub
                  supravegherea blândă a miilor de ochi de oameni buni.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12"></div>
          </div>

          <div className="row mt-5 mb-5 team-council" id="consiliu-consultativ">
            <div className="col-lg-9 col-md-9 col-sm-12">
              <NavLink to="/team#consiliu-consultativ"></NavLink>
              <h2>Consiliu Consultativ</h2>
              <p>
                Nu am putea vorbi de Soluții colaborative fără colaborare și în organizarea proiectului. Aceștia sunt
                reprezentanții din diferite domenii și sectoare de activitate care ni s-au alăturat într-un grup
                informal, cu rol consultativ, menit să ne ghideze și să ne sprijine în implementarea activităților. Aduc
                împreună cunoștințe și resurse care ne ajută să adresăm cât mai bine nevoile reale ale comunității.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_lenuta_alboaie} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">prof. univ. dr. Lenuța Alboaie </p>
              </div>
              <div className="row">
                <p className="title">
                  Director Școala Doctorală, Director Centrul de Transfer Tehnologic - ITransfer, Facultatea de
                  Informatică, UAIC Iași
                </p>
              </div>
              <div className="row">
                <p className="bio">Iași = oraș viu, smart și rezilient cu adânci amprente culturale.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_ciprian_alupului} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Ciprian Alupului</p>
              </div>
              <div className="row">
                <p className="title">Coordonator Centrul de Documentare Europeană „Jean Monnet”, UAIC Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Îmi doresc ca în viitorul apropiat să pot spune fără ezitare despre Iași că este un loc deschis
                  oportunităților, apreciat de către cei care lucrează aici, respectat de către cei care studiază la
                  universitățile noastre, îndrăgit de cei care îl vizitează și, mai ales, prețuit de ieșeni. Acest lucru
                  poate fi posibil doar ca rezultat al unui proces în care cetățeni, antreprenori, reprezentanți ai
                  societății civile și decidenți identifică și implementează modele adaptate de dezvoltare, ca rezultat
                  al unui proces real de consultare și colaborare. Acest proces nu este dificil de configurat, fiind
                  necesară doar parcurgerea unor etape deja testate cu succes de alte centre urbane.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_maria_corina_barbaros} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">lect. univ. dr. Maria Corina Barbaros</p>
              </div>
              <div className="row">
                <p className="title">Facultatea de Filosofie și Științe Social-Politice, UAIC Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul în care mi-ar plăcea să trăiesc este cel al cărui potențial ar fi materializat, adică un oraș cu
                  un vibe creativ și mereu activ dar care reușește să conserve, totodată, tihna și istoria. Ar fi orașul
                  la care cei care au trecut pe aici s-ar gândi cu nostalgie și dorință de a reveni.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_mihai_bulai} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">lect. univ. dr. Mihai Bulai</p>
              </div>
              <div className="row">
                <p className="title">Prodecan Facultatea de Geografie și Geologie, UAIC Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul în care îmi place să trăiesc este un oraș al oamenilor, al pietonilor. Este un oraș liniștit, cu
                  zgomot cât mai redus, cât mai puțin poluat cu putință, cu parcuri ample, piațete - multe piațete în
                  care să stai, nu doar prin care să treci. Este un oraș cu oameni responsabili, care se implică în
                  viața urbei, reciclează, triază și reduc consumul de deșeuri, folosesc mașina personală cât mai rar,
                  atunci când au alternative, au inițiative sociale, se ajută, reacționează când cuiva nu îi sunt
                  respectate drepturile. Un oraș în care administrația investește în nevoile fundamentale ale
                  cetățenilor, menținând totuși la cote ridicate nevoia de cultură.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_anca_elena_chirila_gheorghica} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Anca Elena Chirilă-Gheorghică</p>
              </div>
              <div className="row">
                <p className="title">co-fondator și coordonator Asociația Mai bine</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul în care îmi place să trăiesc este cel pe care mi-l pot imagina verde, sănătos și rezilient: cu
                  aer curat, cu apă potabilă sigură pentru toți locuitorii și cișmele publice la îndemâna fiecăruia, cu
                  spații verzi recuperate și soluri regenerate prin grădini urbane publice din ce în ce mai prezente,
                  unul în care transportul cu mașina personală este taxat în timp ce mobilitatea alternativă este
                  încurajată, cu acces facil la hrană naturală, produsă local, fără deșeuri și cu cetățeni care se
                  dedică pentru a-l face realitate.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_daniela_coman} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Daniela Coman</p>
              </div>
              <div className="row">
                <p className="title">Barista și co-creator, Jassyro coffee shop</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul meu este un oraș verde care își respectă trecutul, istoria și își pune în evidență arhitectura.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_andrei_cristian_danila} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Andrei Cristian Dănilă</p>
              </div>
              <div className="row">
                <p className="title">CEO CODE932</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul în care îmi place să trăiesc este un oraș al oamenilor focusați pe dezvoltarea comună, pe
                  educație, pe proiecte pe termen lung. Este un oraș al oamenilor care lasă ura și se opresc din a arăta
                  cu degetul pe cei ce nu fac și își îndreaptă atenția spre cei ce fac și le vin acestora în ajutor.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_beatrice_elena_fotache} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Beatrice Elena Fotache</p>
              </div>
              <div className="row">
                <p className="title">Director Direcția Proiecte Europene, Primăria Municipiului Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Orașul în care locuitorii sunt cetățeni, nu populație, iar cetățenii au drepturi și, în aceeași
                  măsură, obligații, indiferent în ce domeniu activează. Orașul în care instituțiile sunt partenerii
                  cetățenilor, nu conduc. Orașul în care organizațiile neguvernamentale sunt o a treia parte într-un
                  contract în care toți cei implicați sunt egali într-o guvernare participativă, transparentă și
                  eficientă.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_marcel_ionescu_heroiu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Marcel Ionescu-Heroiu</p>
              </div>
              <div className="row">
                <p className="title">Expert Senior Dezvoltare Urbană, Banca Mondială</p>
              </div>
              <div className="row">
                <p className="bio">
                  Orașul în care îmi place să trăiesc are arhitectura Pragăi, urbanismul Barcelonei, dinamismul
                  Berlinului, bucătăria San Sebastianului și nebunia Bucureștiului.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_florin_lazarescu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Florin Lăzărescu</p>
              </div>
              <div className="row">
                <p className="title">Scriitor</p>
              </div>
              <div className="row">
                <p className="bio">
                  Pe de o parte, subiectiv vorbind, Iașul a fost dintotdeauna pentru mine cel mai bun oraș din cea mai
                  bună lume posibilă. În sensul că mi-a oferit mereu suficiente motive nu doar de a rămâne, ci și de a
                  mă bucura din tot sufletul că trăiesc aici. Indiferent de oportunități, nici nu mi-a trecut prin cap
                  vreodată ideea de a pleca. Pe de alta — ooo, da! —, sunt o mulțime de lucruri de îmbunătățit, de
                  regândit, pentru a-l transforma în ceea ce poate fi cu adevărat. Iar pentru asta cred că e nevoie de
                  cât mai mulți oameni onești și profesioniști, care să tragă la aceeași căruță, fără să aibă alt
                  interes personal decât de a simți cât mai bine acasă.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_gabriela_mesnita} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">prof. univ. dr. Gabriela Meșniță</p>
              </div>
              <div className="row">
                <p className="title">Facultatea de Economie și Administrarea Afacerilor, UAIC Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul îl văd ca pe o metropolă a noului, în care istoria nu se pierde, ci se valorifică. O metropolă a
                  oamenilor pentru oameni, în care sentimentul de apartenență la comunitate va fi tot mai vizibil. Îmi
                  doresc un oraș CERT, în care Comunicarea/colaborarea între toți actorii este o normalitate, Educația
                  primează pentru toți, Respirăm aer curat, iar Tehnologia susține eficient demersurile cetății.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_gratian_mihailescu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Grațian Mihăilescu</p>
              </div>
              <div className="row">
                <p className="title">Fondator UrbanizeHub</p>
              </div>
              <div className="row">
                <p className="bio">
                  Orașul în care îmi place să trăiesc este format dintr-un ecosistem urban viu, unde primarul este doar
                  un facilitator, iar ceilalți actori din privat, societate civilă și universitate contribuie la
                  dezvoltarea sustenabilă a orașului. Îmi plac orașele creative, orașele colorate, orașele verzi,
                  orașele cu spații publice de calitate, walkable cities, dar cel mai important orașele unde guvernanța
                  urbană e funcțională.
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_ciprian_mihai_paius} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Ciprian Mihai Păiuș</p>
              </div>
              <div className="row">
                <p className="title">Director executiv Fundația Comunitară Iași</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul în care îm place să trăiesc e vibrant, plin de evenimente și oameni activi, e verde și
                  responsabil față de natură, e locul în care împreună construim un viitor mai bun pentru noi și copii
                  noștri.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_andrei_spitelnicu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Andrei Spițelnicu</p>
              </div>
              <div className="row">
                <p className="title">Country Manager Centric IT Solutions Romania</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul în care mi-ar plăcea să trăiesc este unul verde, luminos, modern, un oraș al oamenilor ce au
                  mereu zambetul pe buze.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_stefan_teisanu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Ștefan Teișanu</p>
              </div>
              <div className="row">
                <p className="title">Director Centrul Cultural Clujean, Președinte NORD, Fondator Fapte</p>
              </div>
              <div className="row">
                <p className="bio">
                  Un oraș bun e un oraș despre care toți locuitorii săi vorbesc cu drag și au dreptate să o facă.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row">
                <img src={teamImages.img_adrian_mironescu} alt="" className="profile-image" />
              </div>
              <div className="row">
                <p className="name">Adrian Mironescu</p>
              </div>
              <div className="row">
                <p className="title">Antreprenor, Fondator WIRON</p>
              </div>
              <div className="row">
                <p className="bio">
                  Iașul este orașul în care simt că pot să cresc împreună cu cei dragi. Este un oraș la care mă bucur să
                  contribui în felul meu și să îi implic și pe cei din jur pentru a ne fi tuturor mai bine.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
