import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUserStatus } from '../../models/selectors/login';
import { getUserData } from '../../models/selectors/users';
import { getIsUserAdmin } from '../User/UserUtils';

const AdminRoute = (props) => {
  const { component } = props;
  const isUserAdmin = getIsUserAdmin();
  const Component = component;
  return isUserAdmin ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />;
};

export default connect((state) => ({
  userStatus: getUserStatus(state),
  userData: getUserData(state),
}))(AdminRoute);
