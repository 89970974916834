import img_alexandrina_dinga from './team/alexandrina_dinga.png';
import img_irina_theodoru from './team/irina_theodoru.png';
import img_amelia_mistreanu from './team/amelia_mistreanu.png';
import img_florin_apetrei from './team/florin_apetrei.png';
import img_gabriel_marchidan from './team/gabriel_marchidan.png';
import img_alina_batcu from './team/alina_batcu.png';
import img_roxana_cojocaru from './team/roxana_cojocaru.png';
import img_laura_cozmanca from './team/laura_cozmanca.png';
import img_dan_postolea from './team/dan_postolea.png';
import img_alexandru_chelaru from './team/alexandru_chelaru.png';
import img_adelina_dabu from './team/adelina_dabu.png';
import img_tudor_juravlea from './team/tudor_juravlea.png';
import img_oana_olariu from './team/oana_olariu.png';
import img_radu_puchiu from './team/radu_puchiu.png';
import img_ovidiu_voicu from './team/ovidiu_voicu.png';
import img_lenuta_alboaie from './team/lenuta_alboaie.png';
import img_ciprian_alupului from './team/ciprian_alupului.png';
import img_maria_corina_barbaros from './team/maria_corina_barbaros.png';
import img_mihai_bulai from './team/mihai_bulai.png';
import img_anca_elena_chirila_gheorghica from './team/anca_elena_chirila_gheorghica.png';
import img_daniela_coman from './team/daniela_coman.png';
import img_andrei_cristian_danila from './team/andrei_cristian_danila.png';
import img_beatrice_elena_fotache from './team/beatrice_elena_fotache.jpg';
import img_marcel_ionescu_heroiu from './team/marcel_ionescu_heroiu.png';
import img_florin_lazarescu from './team/florin_lazarescu.png';
import img_gabriela_mesnita from './team/gabriela_mesnita.png';
import img_gratian_mihailescu from './team/gratian_mihailescu.png';
import img_adrian_mironescu from './team/adrian_mironescu.png';
import img_ciprian_mihai_paius from './team/ciprian_mihai_paius.png';
import img_stefan_teisanu from './team/stefan_teisanu.png';
import img_alin_pavel from './team/alin_pavel.jpg';
import img_cosmina_hatmanu from './team/cosmina_hatmanu.jpg';
import img_vlad_samson from './team/vlad_samson.jpg';
import img_roxana_irinei from './team/roxana_irinei.jpg';
import img_sergiu_ojoc from './team/sergiu_ojoc.jpg';
import img_andrei_spitelnicu from './team/andrei_spitelnicu.jpg';
import img_raluca_marcu from './team/raluca_marcu.jpg';
import img_ana_maria_l from './team/ana_maria_l.jpg';
import img_ioana_ursache from './team/ioana_ursache.jpg';
import img_georgiana_riglea from './team/georgiana_riglea.png';
import img_adrian_baltariu from './team/adrian_baltariu.JPG';
import img_liviu_atudorei from './team/liviu_atudorei.png';
import acajuLogo from './partners/acaju.png';
import binduLogo from './partners/bindu.jpg';
import cakeExpertLogo from './partners/cake-expert.png';
import fika from './partners/fika.png';
import jassyro from './partners/jassyro.png';
import viva from './partners/viva.png';
import designKit from './partners/design-kit.png';
import wink from './partners/wink.png';
import pin_magazine from './partners/pin-magazine.png';
import bistroLaNoi from './partners/bistroLaNoi.png';
import cuib from './partners/cuib.png';
import energy from './partners/energy.png';
import armony from './partners/armony.png';
import pmi from './partners/pmi.png';
import centric from './partners/centric.png';
import mambu from './partners/mambu.png';
import civicHeroes from './partners/civicheroes.png';
import yonder from './partners/yonder.png';
import miolk from './partners/miolk.png';
import facultaGeogra from './partners/facultaGeogra.png';
import industriicreative from './partners/industriicreative.png';
import maibine from './partners/maibine.png';
import probike from './partners/probike.png';
import ziaruldeiasi from './partners/ziaruldeiasi.png';
import evogym from './partners/evogym.png';
import designthinking from './partners/designthinking.png';
import relians from './partners/relians.png';
import cmm from './partners/cmm.png';
import iulius from './partners/iulius.png';
import scc from './partners/scc.png';
import open_society from './partners/open-society-foundations.png';
import edurom from './partners/edurom.png';
import adservio from './partners/adservio.png';
import continental from './partners/continental.png';
import endava from './partners/endava.png';
import fab_lab from './partners/fab-lab.png';

const teamImages = {
  img_alexandrina_dinga,
  img_irina_theodoru,
  img_amelia_mistreanu,
  img_florin_apetrei,
  img_gabriel_marchidan,
  img_alina_batcu,
  img_roxana_cojocaru,
  img_laura_cozmanca,
  img_dan_postolea,
  img_alexandru_chelaru,
  img_adelina_dabu,
  img_tudor_juravlea,
  img_oana_olariu,
  img_radu_puchiu,
  img_ovidiu_voicu,
  img_lenuta_alboaie,
  img_ciprian_alupului,
  img_maria_corina_barbaros,
  img_mihai_bulai,
  img_anca_elena_chirila_gheorghica,
  img_daniela_coman,
  img_andrei_cristian_danila,
  img_beatrice_elena_fotache,
  img_marcel_ionescu_heroiu,
  img_florin_lazarescu,
  img_gabriela_mesnita,
  img_gratian_mihailescu,
  img_adrian_mironescu,
  img_ciprian_mihai_paius,
  img_stefan_teisanu,
  img_alin_pavel,
  img_cosmina_hatmanu,
  img_vlad_samson,
  img_roxana_irinei,
  img_sergiu_ojoc,
  img_liviu_atudorei,
  img_andrei_spitelnicu,
  img_raluca_marcu,
  img_ana_maria_l,
  img_ioana_ursache,
  img_georgiana_riglea,
  img_adrian_baltariu,
  acajuLogo,
  binduLogo,
  cakeExpertLogo,
  fika,
  jassyro,
  viva,
  designKit,
  wink,
  pin_magazine,
  bistroLaNoi,
  cuib,
  armony,
  energy,
  pmi,
  centric,
  mambu,
  civicHeroes,
  yonder,
  miolk,
  facultaGeogra,
  industriicreative,
  maibine,
  probike,
  ziaruldeiasi,
  evogym,
  designthinking,
  relians,
  cmm,
  iulius,
  scc,
  open_society,
  edurom,
  adservio,
  continental,
  endava,
  fab_lab,
};

export default teamImages;
