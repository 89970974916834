import React, { useEffect } from 'react';
import './About_overview.scss';
import { NavLink } from 'react-router-dom';
import aboutImages from '../../../assets/images/about';

const About_overview = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-wrapper ">
      <div className="about container">
        <div className="offset-md-3 col-md-6 col-sm-12">
          <h1 className="font-weight-bold">Despre</h1>
        </div>

        <div className="offset-md-3 col-md-6 col-sm-12">
          <h2 className="font-weight-bold">
            ..cum ne implicăm și rezolvăm împreună problemele prioritare ale orașului.
          </h2>
        </div>
        <div className="row">
          <div className="col-12 center space-bot">
            <NavLink to="/about-project">Descrierea proiectului</NavLink>
            <br />
            <a
              href="https://solutiicolaborative.ro/static_files/Solutii_Colaborative_prezentare.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Descarcă prezentarea proiectului
            </a>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="space-bot"></div>
        <div className="row">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-5 col-sm-12 center">
            <img src={aboutImages.img_about_us} alt="" className="img-fluid team-image" />
          </div>
          <div className="col-md-5 col-sm-12 team-text">
            <h3>Echipa</h3>
            <p>
              Cine suntem noi, oamenii din spatele Soluțiilor <br /> Colaborative?
            </p>
            <br />
            <NavLink to="/team#echipa-executiva">Echipa executivă {'>'}</NavLink>
            <br />
            <NavLink to="/team#experti">Experți {'>'}</NavLink>
            <br />
            <NavLink to="/team#consiliu-consultativ">Consiliul Consultativ {'>'}</NavLink>
          </div>
        </div>
        <div className="space-bot"></div>
      </div>
    </div>
  );
};

export default About_overview;
