import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getExistingTagsAction } from '../../models/actions/filterTags';
import { getFilterTags, getIsFilterTagsLoading } from '../../models/selectors/filterTags';
import './FilterTags.scss';

const getTagColor = {
  1: '#CFFF9F',
  2: '#C2E9FF',
  3: '#FFCFA3',
  4: '#E0C8FF',
  5: '#FFB3B3',
  6: '#EDEDED',
  7: '#F4E87B',
};

class FilterTagsMulty extends Component {
  componentDidMount() {
    this.props.getCurrentTags();
  }

  render() {
    const { onChange, currentTags, isTagsLoading, selectedTag, selectedTagsArray } = this.props;
    return (
      !isTagsLoading && (
        <ul className="problem-tags">
          {currentTags.map((currentTag) => {
            const { name, tagId } = currentTag;
            return (
              <li key={name} style={{ background: getTagColor[tagId] }}>
                <input
                  type="checkbox"
                  id={tagId}
                  onChange={() => onChange(tagId)}
                  name="categories"
                  defaultChecked={selectedTag && tagId.toString() === selectedTag.toString()}
                  checked={selectedTagsArray && selectedTagsArray.indexOf(tagId) !== -1}
                />
                <label htmlFor={tagId}>{name}</label>
              </li>
            );
          })}
        </ul>
      )
    );
  }
}

export default connect(
  (state) => ({
    isTagsLoading: getIsFilterTagsLoading(state),
    currentTags: getFilterTags(state),
  }),
  (dispatch) => ({
    getCurrentTags: () => dispatch(getExistingTagsAction()),
  }),
)(FilterTagsMulty);
