import createReducer from '../store/createReducer';

const initialState = {
  userStatus: 'unauthenticathed',
  userData: null,
};

export function GET_USER_LOGIN_REQUEST(nextState) {
  nextState.userStatus = 'loading';
}

export function SET_USER_STATUS(nextState, { userStatus }) {
  nextState.userStatus = userStatus;
}

export function SET_USER_DATA(nextState, { userData }) {
  nextState.userData = userData;
}

export default createReducer(initialState, {
  GET_USER_LOGIN_REQUEST,
  SET_USER_STATUS,
  SET_USER_DATA,
});
