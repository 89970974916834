import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { logoutUserAction } from '../../../models/actions/userActions';

class Logout extends Component {
  componentDidMount() {
    this.props.onUserLogout();
    setTimeout(() => {
      if (window.location.pathname === '/logout') {
        this.props.history.push('/');
      }
    }, 15000);
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 center p-5 my-5">
            <p>Te-ai delogat cu success</p>
            <p>Te redirectam imediat pe pagina principală...</p>
            <NavLink to="/">Du-mă pe pagina principală</NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  onUserLogout: () => dispatch(logoutUserAction()),
}))(Logout);
