import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import teamImages from '../../../assets/images/team';

import './Partners.scss';

class Partners extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className="partners">
        <div className="partners-container container">
          <div className="row left py-5">
            <div className="col-md-6 col-sm-12 mt-md-5 px-md-0 pt-md-5">
              <h2 className="title-mobile px-2 pt-5 mb-md-5 font-weight-bold title-partners">
                Soluții Colaborative pentru comunitate nu s-ar putea întâmpla fără sprijinul și implicarea
                organizațiilor care ne înțeleg misiunea și ne sunt alături.
              </h2>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0 center">
              <hr />
              <h3 className="pt-3 pb-5 font-weight-bold">Co-finanțatori</h3>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="partner-image">
                    <img src={teamImages.open_society} alt="" className="partner-image" />
                  </div>
                  <br />
                  <br />
                  <a className="font-weight-bold" href="https://www.opensocietyfoundations.org/" target="_blank">
                    {' '}
                    Open Society Foundations
                  </a>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="partner-image">
                    <img src={teamImages.pmi} alt="" className="partner-image" />
                  </div>
                  <br />
                  <br />
                  <a className="font-weight-bold" href="http://primaria-iasi.ro/" target="_blank">
                    {' '}
                    Primăria Municipiului Iași
                  </a>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0 center">
              <hr />
              <h3 className="pt-3 pb-4 font-weight-bold">Partener principal</h3>
              <div className="partner-image">
                <img src={teamImages.centric} alt="" className="partner-image" />
              </div>
              <a className="font-weight-bold" href="https://careers.centric.eu/ro/" target="_blank">
                {' '}
                Centric IT Solutions Romania
              </a>
              <br />
              <br />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0 center">
              <hr />
              <h3 className="pt-3 pb-5 font-weight-bold">Sponsori</h3>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="partner-image">
                    <img src={teamImages.mambu} alt="" className="partner-image" />
                  </div>
                  <a className="font-weight-bold" href="https://www.mambu.com" target="_blank">
                    {' '}
                    Mambu
                  </a>
                  <br />
                  <br />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="partner-image">
                    <img src={teamImages.scc} alt="" className="partner-image" />
                  </div>
                  <a className="font-weight-bold" href="https://ro.scc.com/scc-services-romania/" target="_blank">
                    {' '}
                    SCC Services Romania
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0">
            <hr />
            <h3 className="pt-3 pb-5 font-weight-bold center">Sponsori inkind</h3>
            <div className="row center">
              <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                <img src={teamImages.designKit} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://thekit.ro/design" target="_blank">
                  {' '}
                  the design KIT <br />
                </a>
                <p>(servicii design)</p>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="partner-image">
                  <img src={teamImages.adservio} alt="" className="partner-image" />
                </div>
                <br />

                <a className="font-weight-bold" href="https://www.adservio.ro/ro/" target="_blank">
                  {' '}
                  Adservio <br />
                </a>
                <p>(servicii technice)</p>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.cakeExpertLogo} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://torturi-iasi.ro/" target="_blank">
                  {' '}
                  CakeExpert <br />
                </a>
                <p>(produse cofetărie)</p>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.iulius} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://www.iuliuscompany.ro" target="_blank">
                  {' '}
                  Compania IULIUS
                </a>
                <p>(cheltuieli logistice)</p>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.designthinking} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://designthinkingsociety.com" target="_blank">
                  {' '}
                  Design Thinking Society
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.cmm} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://palatulculturii.ro" target="_blank">
                  {' '}
                  Complexul Muzeal Naţional „Moldova" Iaşi
                </a>
              </div>
              <div className="col-md-4 offset-md-4 col-sm-12 ">
                <br />
                <img src={teamImages.endava} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://www.endava.com/" target="_blank">
                  {' '}
                  Endava
                </a>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0">
            <hr />
            <h3 className="pt-3 pb-5 font-weight-bold center">Organizații mentor</h3>
            <div className="row center">
              <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                <div className="partner-image">
                  <img src={teamImages.centric} alt="" className="partner-image" />
                </div>
                <a className="font-weight-bold" href="https://careers.centric.eu/ro/" target="_blank">
                  {' '}
                  Centric IT Solutions Romania
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="partner-image">
                  <img src={teamImages.mambu} alt="" className="partner-image" />
                </div>
                <a className="font-weight-bold" href="https://www.mambu.com" target="_blank">
                  {' '}
                  Mambu
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.relians} alt="" className="partner-image" />
                <br />
                <a className="font-weight-bold" href="http://www.relians.ro" target="_blank">
                  {' '}
                  Relians
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.civicHeroes} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://civicheroes.ro" target="_blank">
                  {' '}
                  Civic HEROES
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.yonder} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://tss-yonder.com" target="_blank">
                  {' '}
                  Yonder
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.miolk} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://www.miolk.ro" target="_blank">
                  {' '}
                  MIOLK
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />

                <div className="partner-image">
                  <img src={teamImages.edurom} alt="" className="partner-image" />
                </div>
                <br />
                <br />
                <a className="font-weight-bold" href="https://www.edurom.ro" target="_blank">
                  {' '}
                  EDUROM
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <br />
                <img src={teamImages.facultaGeogra} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://geo.uaic.ro/ro" target="_blank">
                  {' '}
                  Facultatea de Geografie
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <br />
                <img src={teamImages.industriicreative} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://asociatiaindustriicreative.com" target="_blank">
                  {' '}
                  Asociația industrii creative
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <br />
                <img src={teamImages.maibine} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://www.maibine.eu" target="_blank">
                  {' '}
                  Asociația Mai bine
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <br />
                <div className="partner-image">
                  <img src={teamImages.continental} alt="" className="partner-image" />
                </div>
                <br />
                <a className="font-weight-bold" href="https://www.continental.com/ro-ro/" target="_blank">
                  {' '}
                  Continental Automotive Romania
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <br />
                <img src={teamImages.probike} alt="" className="partner-image" />
                <br />
                <br />
                <a className="font-weight-bold" href="https://www.probikeaddiction.ro/" target="_blank">
                  {' '}
                  ProBikeAddiction
                </a>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0 center">
            <hr />
            <h3 className="pt-3 pb-5 font-weight-bold">Parteneri media</h3>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.viva} alt="" className="partner-image" />
                <br />
                <a className="font-weight-bold" href="https://vivafm.ro/" target="_blank">
                  {' '}
                  VIVA FM
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.wink} alt="" className="partner-image" />
                <br />
                <a className="font-weight-bold" href="https://wink.ro/" target="_blank">
                  {' '}
                  WINK Public Media
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.pin_magazine} alt="" className="partner-image" />
                <br />
                <a className="font-weight-bold" href="https://www.pinmagazine.ro/" target="_blank">
                  {' '}
                  PIN Magazine
                </a>
              </div>
            </div>
            <br />
            <br />
            <hr />
          </div>
          <div className="col-md-6 offset-md-3 col-sm-12 offset-sm-0 center">
            <h3 className="pt-3 pb-5 font-weight-bold">Susținători din comunitate</h3>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.acajuLogo} alt="" className="partner-image" />
                <br />
                <a
                  className="font-weight-bold"
                  href="https://la-acaju.ro/?fbclid=IwAR1Y2HuRC3wwHloThNOku_zcZwRRdOvZNE56kVnn3izUnepppOP7LMqRlv0"
                  target="_blank"
                >
                  {' '}
                  Acaju
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.binduLogo} alt="" className="partner-image" />
                <br />
                <a className="font-weight-bold" href="https://www.facebook.com/BinduIasi/" target="_blank">
                  {' '}
                  Bindu Iasi
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={teamImages.jassyro} alt="" className="partner-image" />
                <br />
                <a className="font-weight-bold" href="https://jassyro.ro" target="_blank">
                  {' '}
                  Jassyro
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.fika} alt="" className="partner-image pb-2 mb-1" />
                <br />
                <a
                  className="font-weight-bold"
                  href="https://www.facebook.com/NuDoarOPauzadeCafea/?ref=page_internal"
                  target="_blank"
                >
                  {' '}
                  Fika Iasi
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.bistroLaNoi} alt="" className="partner-image pb-2 mb-1" />
                <br />
                <a className="font-weight-bold" href="https://www.bistrolanoi.ro" target="_blank">
                  {' '}
                  Bistro La Noi
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.cuib} alt="" className="partner-image pb-2 mb-1" />
                <br />
                <a className="font-weight-bold" href="https://incuib.ro" target="_blank">
                  {' '}
                  Cuib
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.armony} alt="" className="partner-image pb-2 mb-1" />
                <a className="font-weight-bold" href="https://www.armony.ro" target="_blank">
                  {' '}
                  Armony Club
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.energy} alt="" className="partner-image pb-2 mb-1" />
                <a className="font-weight-bold" href="https://energymcenter.ro" target="_blank">
                  {' '}
                  EnerGym
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <br />
                <img src={teamImages.evogym} alt="" className="partner-image pb-2 mb-1" />
                <a className="font-weight-bold" href="https://evogym.com" target="_blank">
                  {' '}
                  EvoGym
                </a>
              </div>
              <div className="col-md-4 offset-md-4 col-sm-12">
                <br />
                <img src={teamImages.fab_lab} alt="" className="partner-image pb-2 mb-1" />
                <a className="font-weight-bold" href="http://www.fablabiasi.ro/en" target="_blank">
                  {' '}
                  Fab Lab
                </a>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Partners;
