import { combineReducers } from 'redux';
import loginReducers from './login';
import registerReducers from './register';
import registerProblems from './registerProblems';
import existingProblems from './existingProblems';
import filterTags from './filterTags';
import adminReducers from './admin';
import problemDetails from './problemDetails';
import problemDetailsVote from './problemDetailsVote';
import profileReducer from './profile';
import resetPasswordReducer from './resetPassword';

const reducers = combineReducers({
  loginReducers,
  registerReducers,
  registerProblems,
  existingProblems,
  filterTags,
  adminReducers,
  problemDetails,
  profileReducer,
  problemDetailsVote,
  resetPasswordReducer,
});

export default reducers;
