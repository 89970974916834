import React, { useEffect } from 'react';
import './TermsAndConditions.scss';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms">
      <h1 className="center">
        Termeni și condiții de utilizare a platformei <br />
        soluții colaborative.ro
      </h1>
      <h2>1. Domeniul de aplicare a termenilor și condițiilor </h2>
      <p>
        Această rubrică prezintă termenii și condițiile de accesare a platformei solutiicolaborative.ro. Orice persoană
        care dorește să participe la procesul de soluționare colaborativă de probleme trebuie să se înregistreze pe
        platformă, pas pentru care vă vom solicita acceptarea acestor condiții de utilizare.
      </p>
      <p>
        Platforma a fost dezvoltată în 2020, la inițiativa Asociației CIVICA, cu scopul de a pune la dispoziția
        ieșenilor un serviciu online prin care să contribuie la identificarea, prioritizarea și soluționarea problemelor
        din oraș
      </p>
      <h2>2. Scopul platformei</h2>
      <p>
        Platforma solutiicolaborative.ro. reprezintă un serviciu online gratuit prin intermediul căruia cetățenii care
        locuiesc, muncesc sau studiază în Iași și au împlinit vârsta de 18 ani, au posibilitatea de a fi parte a
        procesului de soluționare a problemelor orașului prin următoarele funcționalități ale platformei: înscrierea de
        probleme, prioritizarea problemelor înscrise prin supunerea la vot, transmiterea de observații pentru soluțiile
        dezvoltate, transmiterea de noutăți despre progresul implementării soluțiilor colaborative.
      </p>
      <p>Platforma solutiicolaborative.ro. este disponibilă în format web, adaptată și pentru dispozitive mobile.</p>
      <h2>3. Acceptarea termenilor</h2>
      <div>
        Prin folosirea platformei solutiicolaborative.ro sunteți de acord să acceptaţi Termenii și condițiile de
        funcționare. Administratorii platformei au dreptul de a modifica Termenii și condițiile de funcționare oricând,
        cu scopul de a îmbunătăţi procesul de implicare a cetățenilor în identificarea și soluționarea problemelor din
        municipiul Iași, precum și experiența acestora pe website. Prin continuarea utilizării platformei sunteți de
        acord să acceptaţi acele schimbări, în cazul în care ele sunt efectuate. Termenii și condițiile de funcționare
        pentru utilizarea platformei solutiicolaborative.ro. sunt cu privire la:
        <ul>
          <li>
            Utilizator – orice persoană fizică ce se se înregistrează în platformă și accesează conținutul pus la
            dispoziţie de către solutiicolaborative.ro.
          </li>
          <li>
            Înscriere problemă – semnalarea unei probleme ce afectează calitatea vieții cetățenilor, la nivelul unui
            cartier sau la nivelul municipiului, cu respectarea criteriilor de eligibilitate cuprinse în Regulamentul
            proiectului;
          </li>
          <li>
            Votarea problemelor – procesul prin care utilizatorii pot prioritiza problemele înscrise, în acord cu
            nevoile de soluționare resimțite de ei;
          </li>
          <li>
            Consultare publică – procesul de consultare cu cetățenii, prin intermediul solutiicolaborative.ro, prin care
            utilizatorii platformei pot aduce îmbunătățiri soluțiilor dezvoltate pentru cele 7 probleme finale.
          </li>
        </ul>
      </div>
      <h3>Utilizarea Platformei Soluții colaborative</h3>
      <p>
        Cetățenii vor putea accesa și naviga platforma solutiicolaborative.ro în mod gratuit și anonim, dar atunci când
        vor dori să întreprindă una dintre acțiuni (expunere problemă, susținere prin vot sau comentariu) li se va cere
        să se autentifice, pas pentru care va fi necesară înregistrarea în prealabil.
      </p>
      <p>
        Pentru a fi parte a procesului de soluționare colaborativă vă veți înregistra pe platformă, la secțiunea
        CREAZĂ-ȚI UN CONT prin introducerea următoarelor date: nume de utilizator, e-mail, acceptarea condițiilor de
        utilizare a solutiicolaborative.ro sau prin identificarea cu contul de Facebook sau Google.
      </p>
      <div>
        Utilizatorul poate interacționa pe platformă în următoarele moduri:
        <ul>
          <li>
            <strong> Înscriere problemă </strong>
            <br />
            Fiecare utilizator va putea înregistra una sau mai multe probleme, răspunzând uneia sau tuturor întrebărilor
            de pe platformă. Propunerile nu vor fi publicate automat, ci după ce sunt supuse unui prim filtru de
            verificare a respectării condițiilor de urcare a problemelor conținutului, precum lipsa limbajului licențios
            (a se vedea Condițiile de acceptare ca utilizator expuse mai jos), verificare realizată de administratorii
            platformei.
          </li>
          <li>
            <strong>Prioritizare de probleme prin vot public</strong> <br />
            Votul reprezintă calea de exprimare a susținerii față de o problemă expusă de către un alt utilizator.
            Fiecare utilizator poate vota aceeași propunere o singură dată. Fiecare problemă este însoțită de o secțiune
            de comentarii unde utilizatorii pot face schimb de opinii pentru a înțelege mai bine problema expusă și
            pentru a-și argumenta punctul de vedere
          </li>
          <li>
            <strong>Consultare</strong> <br /> Fiecare soluție este însoțită de o secțiune de comentarii unde
            utilizatorii pot aduce argumente pentru care sunt sau nu sunt de acord cu soluția dezvoltată.
          </li>
        </ul>
      </div>
      <h3>Condiții de acceptare ca utilizator</h3>
      <p>
        Te poți înscrie pe platformă cu un singur cont (adresă de e-mail). Este interzisă înscrierea aceluiași
        utilizator de pe mai multe conturi (fraudare prin conturi multiple, mai exact mai multe adrese de email
        aparținând aceleași persoane). În situația în care se constată că o persoană este înscrisă prin intermediul mai
        multor conturi în cadrul platformei, aceasta va fi eliminată din rețea și nu îi va mai fi permisă înscrierea
        ulterioară.
      </p>
      <p>
        Persoanele care se înscriu ca utilizatori vor fi înștiințați de către echipa solutiicolaborative.ro de crearea
        contului în termen de cel mult 24 de ore de la trimiterea cererii de alăturare pe platformă. Ca utilizator poți
        invita, prin intermediul rețelelor sociale, și alte persoane să viziteze solutiicolaborative.ro, să înscrie
        probleme, să ia parte la vot și la discuțiile online pe soluții.
      </p>
      <div>
        <strong>
          {' '}
          Ca utilizator cu drept de a-și exprima ideile și opinia pe această platformă, îți asumi și următoarele
          responsabilități:
        </strong>
        <ul>
          <li>
            vei adopta un limbaj decent în timpul utilizării platformei, atât în momentul înscrierii unei probleme, cât
            și în interacțiunea cu alți utilizatori prin schimb de comentarii pe platformă;
          </li>
          <li>
            nu vei include în expunerea problemelor sau în comentarii injurii, descrierea oricărei forme de violență sau
            cruzime, conținut care instigă la rasism, sexism, homofobie, discriminare etnică sau socială, care poate
            aduce prejudicii unei persoane și/sau încalcă dreptul la imagine al unei persoane;
          </li>
          <li>nu vei include publicitate sau materiale promoționale ale unor terți;</li>
          <li>
            nu vei încărca pe platformă conținut care încalcă drepturile de proprietate industrială și intelectuală sau
            a reglementărilor cu privire la protecția datelor cu caracter personal;
          </li>
          <li>vei formula problemele cu claritate și concizie;</li>
          <li>vei oferi în comentarii doar informații sau puncte de vedere relevante la subiectul problemei;</li>
          <li>
            vei înscrie probleme reale, verificând, în măsura în care ai acces la informații, corectitudinea datelor;
          </li>
          <li>
            vei include detalii tehnice, dacă este cazul, în situația în care acestea sunt fundamentate, actualizate și
            conduc la o înțelegere mai bună a situației prezentate;
          </li>
          <li>
            nu vei încerca să utilizezi datele personale ale altui utilizator, acest fapt fiind strict interzis și
            considerat o tentativă de fraudă, sancționată în conformitate cu prevederile legale în vigoare,
            contravențional sau penal, după caz.{' '}
          </li>
          <br />
          <strong>Neasumarea acestor responsabilități atrage următoarele consecințe, după caz:</strong>
          <li>
            problemele înscrise care nu respectă indicațiile de la primul punct nu vor fi acceptate spre publicare;
          </li>
          <li>
            comentariile care nu respectă indicațiile vor fi șterse; La un număr de 2 abateri, contul pe platformă va fi
            suspendat.{' '}
          </li>
        </ul>
      </div>
      <h3>Condiții pentru conținutul furnizat de utilizatori</h3>
      <p>
        Aceste condiții reglementează termenii aplicabili conținutului trimis de utilizatorii acestei platforme prin
        completarea formularului de răspuns la întrebările secțiunii de înscriere probleme.
      </p>
      <p>
        A. Non-confidențialitate: întregul conținut înscris de utilizator pe platforma solutiicolaborative.ro trebuie să
        poată fi cunoscut publicului larg. Prin urmare, echipa de proiect va trata conținutul menționat anterior ca
        informații neconfidențiale.
      </p>
      <p>
        B. Procedură: în cazul în care administratorii platformei sunt interesați de informații suplimentare cu privire
        la conținutul urcat pe platformă de către un utilizator, aceștia își rezervă dreptul de a-l contacta pe
        utilizator. Informațiile menționate vor fi, de asemenea, neconfidențiale. În cazul în care părțile consideră
        necesar schimbul de informații confidențiale, se semnează un acordul de confidențialitate corespunzător.
      </p>
      <p>
        C. Diseminarea conținutului prezentat: Participanții implicați pe platforma soluțiicolaborative.ro declară că
        știu și acceptă faptul că informațiile furnizate pot fi publicate pe site, precum și prin intermediul altor
        mijloace pe care asociația CIVICA le consideră adecvate pentru a fi comunicate public, fără nicio compensație,
        recunoaștere sau plată către utilizatori.
      </p>
      <p>
        D. Nereturnarea materialului: administratorii platformei solutiicolaborative.ro nu au nicio obligație de a
        returna conținutul trimis de utilizatori.
      </p>
      <p>
        E. Procedura de notificare și retragere a conținutului: administratorii platformei își rezervă dreptul de a
        elimina conținutul care încalcă condițiile de utilizare menționate anterior. De asemenea, orice persoană care
        sesizează încălcare condițiilor de utilizare, poate solicita retragerea conținutului de la administratorii
        platformei.
      </p>
      <h2>4. Proprietate intelectuală</h2>
      <p>
        Asociația CIVICA este proprietar al platformei solutiicolaborative.ro, ceea ce include orice software, domeniu
        și conținut pus la dispoziție prin intermediul platformei sau a altor canale de comunicare. Platforma
        solutiicolaborative.ro este protejată de drepturile de autor din România. Grafica, logo-urile, imaginile și
        numele serviciilor legate de platforma solutiicolaborative.ro nu pot fi folosite fără acordul Asociației CIVICA.
      </p>
      <div>
        Prin folosirea platformei solutiicolaborative.ro, sunteți de acord să nu încărcați sau să trimiteți prin e-mail
        materiale care conține viruși sau alte coduri de computer, fișiere sau programe destinate să întrerupă, distrugă
        sau să limiteze funcționalitatea oricărui software sau hardware sau echipament de telecomunicație, sau sa
        distorsioneze orice conţinut asociat cu platforma solutiicolaborative.ro. De asemenea, sunteți de acord să nu:
        <ul>
          <li>interferați cu serverele sau rețelele ce au legătură cu platforma solutiicolaborative.ro;</li>
          <li>
            încălcați nicio procedură, politică sau regulament al rețelelor ce au legătură cu platforma
            solutiicolaborative.ro;
          </li>
          <li>pretindeți că sunteți altă persoană în timp ce folosiți platforma solutiicolaborative.ro;</li>
          <li>folosiți platforma solutiicolaborative.ro în scopuri ilegale;</li>
          <li>revindeți sau exportați software-ul asociat cu platforma solutiicolaborative.ro;</li>
          <li>
            solutiicolaborative.ro nu promovează și nu recomandă folosirea platformei de pe dispozitivele mobile în
            timpul activităților care ar putea pune viața utilizatorilor în pericol, cum ar fi conducerea de vehicule.
            Utilizarea platformei se realizează pe propria răspundere a utilizatorilor.
          </li>
        </ul>
      </div>
      <h2>5. Site-uri ale altor terți</h2>
      <p>
        Echipa Soluții colaborative depune toate eforturile necesare transmiterii în siguranță a datelor tale către
        terțe persoane, însă nu își asumă responsabilitatea pentru modalitatea de prelucrare și securizare a datelor de
        către aceștia. În situația compromiterii acestora ca urmare a unei breșe de securitate, te vom notifica imediat
        în conformitate cu procedurile de notificare cerute de lega aplicabilă.
      </p>
      <p>
        Platforma solutiicolaborative.ro poate conține link-uri către site-urile web ale unor părți terțe ce nu sunt
        controlate sau asociate cu solutiicolaborative.ro. Echipa platformei solutiicolaborative.ro nu este responsabilă
        pentru conținutul, ofertele sau politicile de confidențialitate ale unor asemenea site-uri sau programe. Relația
        utilizatorilor cu site-urile părților terțe se desfășoară doar între utilizatori și respectivele părți terțe.
      </p>
      <p>
        În cazul în care conținutul introdus de utilizatori încorporează un link către un alt site web,
        solutiicolaborative,ro nu va fi responsabil pentru daunele sau pierderile rezultate din accesul la link sau la
        conținutul acestuia.
      </p>
      <h2>6. Reclamații</h2>
      <p>
        Prin agrearea acestor termeni și condiții ești de acord ca orice reclamație, dispută sau controversă între tine
        ca utilizator și solutiicolaborative.ro, ce reies sau au legătură cu acești Termeni și condiții de funcționare,
        vor fi rezolvate în conformitate cu legislația în vigoare în România.
      </p>
      <h2>7. Revizuirea condițiilor de utilizare</h2>
      <p>
        Echipa platformei soluticolaborative.ro își rezervă dreptul de a revizui acești Termeni și condiții de utilizare
        și politica de confidențialitate în orice moment consideră necesar. În acest caz, utilizatorii înregistrați vor
        fi anunțați prin canalele online iar continuarea utilizării platformei va fi înțeles ca accept asupra
        modificărilor introduse.
      </p>
      <h2>8. Contactați-ne</h2>
      <p>
        Utilizatorii pot contacta solutiicolaborative.ro și trimite documente sau alte informații pe care le consideră
        relevante pentru procesul de soluționare a problemei lor, prin intermediul secțiunii Contact. Tot prin
        intermediul acestei secțiuni, utilizatorii pot trimite întrebări, face sesizări, cere informații sau clarificări
        pe unul sau mai multe subiecte.
      </p>
      <p>
        În cazul în care ai întrebări referitoare la acești Termeni și condiții de funcționare sau folosirea platformei,
        nu ezita să ne contactezi la telefon prin e-mail la adresa contact@asociatiacivica.ro
      </p>
    </div>
  );
};

export default TermsAndConditions;
