export const getIsProblemsListLoading = (state) => state.adminReducers.isProblemListLoading;
export const getProblemsList = (state) => state.adminReducers.problemsList;

export const getIsUsersListLoading = (state) => state.adminReducers.isUsersListLoading;
export const getUsersList = (state) => state.adminReducers.usersList;

export const getIsProblemaccepted = (state) => state.adminReducers.isProblemAccepted;

export const getIsProblemDetailsLoading = (state) => state.adminReducers.isProblemDetailsLoading;
export const currentProblemDetails = (state) => state.adminReducers.problemDetails;

export const getUserDetailsSelector = (state) => state.adminReducers.userDetails;
