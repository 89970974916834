import { api } from '../api/api';
import { getFilterTags, getSelectedFilterTags } from '../selectors/filterTags';

export function getExistingTagsAction() {
  return function (dispatch) {
    api.get('tag').then((response) => dispatch({ type: 'GET_PROBLEM_TAGS_SUCCESS', filterTags: response.tags }));
  };
}

export function setFilterTagsAction(tagID) {
  return function (dispatch, getState) {
    const currentTags = getSelectedFilterTags(getState());
    let tagsCopy = [...currentTags];
    const tagIndex = tagsCopy.indexOf(tagID);

    if (tagIndex === -1) {
      tagsCopy.push(tagID);
    } else {
      tagsCopy.splice(tagIndex, 1);
    }

    dispatch({ type: 'SET_FILTER_TAGS', selectedTags: tagsCopy });
  };
}

export function setFilterTagsActionOld(tagState, tagValue) {
  return function (dispatch, getState) {
    const currentTags = getFilterTags(getState());
    let updatedTags = [...currentTags];

    if (tagState) {
      updatedTags = [...currentTags, tagValue];
    } else {
      updatedTags.splice(updatedTags.indexOf(tagValue), 1);
    }
    dispatch({ type: 'SET_FILTER_TAGS', selectedTags: updatedTags });
  };
}
