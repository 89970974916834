import React from 'react';

const ProblemsHeader = () => (
  <div className="problem-header">
    <div className="desc col-md-6 col-sm-12">
      <h1 className="font-weight-bold">Problemele propuse de comunitate</h1>
      <p>
        Aici poți vedea ce probleme ale orașului îi apasă cel mai mult pe ieșeni. Problemele pot fi înscrise între
        <strong> 29 octombrie 2020</strong> și <strong>31 ianuarie 2021</strong> de oricine are cont pe platformă.
      </p>
    </div>
    <div className="desc-image col-md-6 col-sm-12 center"></div>
    <div className="clear"></div>
  </div>
);

export default ProblemsHeader;
