export const GET_PROBLEM_DETAILS_LOADING = 'GET_PROBLEM_DETAILS_LOADING';
export const GET_PROBLEM_DETAILS_SUCCESS = 'GET_PROBLEM_DETAILS_SUCCESS';
export const GET_PROBLEM_DETAILS_ERROR = 'GET_PROBLEM_DETAILS_ERROR';

export const GET_PROBLEM_DETAILS_VOTE_LOADING = 'GET_PROBLEM_DETAILS_VOTE_LOADING';
export const GET_PROBLEM_DETAILS_VOTE_SUCCESS = 'GET_PROBLEM_DETAILS_VOTE_SUCCESS';
export const GET_PROBLEM_DETAILS_VOTE_ERROR = 'GET_PROBLEM_DETAILS_VOTE_ERROR';

export const GET_PROBLEM_TO_VOTE_LOADING = 'GET_PROBLEM_TO_VOTE_LOADING';
export const GET_PROBLEM_TO_VOTE_SUCCESS = 'GET_PROBLEM_TO_VOTE_SUCCESS';
export const GET_PROBLEM_TO_VOTE_ERROR = 'GET_PROBLEM_TO_VOTE_ERROR';

export const POST_VOTE_PROBLEM_LOADING = 'POST_VOTE_PROBLEM_LOADING';
export const POST_VOTE_PROBLEM_SUCCESS = 'POST_VOTE_PROBLEM_SUCCESS';
export const POST_VOTE_PROBLEM_ERROR = 'POST_VOTE_PROBLEM_ERROR';

export const GET_COMMENTS_PROBLEM_DETAILS_LOADING = 'GET_COMMENTS_PROBLEM_DETAILS_LOADING';
export const GET_COMMENTS_PROBLEM_DETAILS_SUCCESS = 'GET_COMMENTS_PROBLEM_DETAILS_SUCCESS';
export const GET_COMMENTS_PROBLEM_DETAILS_ERROR = 'GET_COMMENTS_PROBLEM_DETAILS_ERROR';

export const LIKE_COMMENTS_PROBLEM_DETAILS_SUCCESS = 'LIKE_COMMENTS_PROBLEM_DETAILS_SUCCESS';

export const POST_COMMENTS_PROBLEM_DETAILS_SUCCESS = 'POST_COMMENTS_PROBLEM_DETAILS_SUCCESS';

export const EDIT_COMMENTS_PROBLEM_DETAILS_SUCCESS = 'EDIT_COMMENTS_PROBLEM_DETAILS_SUCCESS';

export const DELETE_COMMENTS_PROBLEM_DETAILS_SUCCESS = 'DELETE_COMMENTS_PROBLEM_DETAILS_SUCCESS';

export const POST_FOLLOW_ERROR = 'POST_FOLLOW_ERROR';
export const POST_FOLLOW_SUCCESS = 'POST_FOLLOW_SUCCESS';

export const DELETE_FOLLOW_ERROR = 'DELETE_FOLLOW_ERROR';
export const DELETE_FOLLOW_SUCCESS = 'DELETE_FOLLOW_SUCCESS';

export const PUT_CHANGE_PROFILE_ERROR = 'PUT_CHANGE_PROFILE_ERROR';
export const PUT_CHANGE_PROFILE_SUCCESS = 'PUT_CHANGE_PROFILE_SUCCESS';
export const PUT_CHANGE_PROFILE_LOADING = 'PUT_CHANGE_PROFILE_LOADING';

export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';

export const GET_MY_PROBLEMS_ERROR = 'GET_MY_PROBLEMS_ERROR';
export const GET_MY_PROBLEMS_SUCCESS = 'GET_MY_PROBLEMS_SUCCESS';
export const GET_MY_PROBLEMS_LOADING = 'GET_MY_PROBLEMS_LOADING';
