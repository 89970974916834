import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getResetStatus } from '../../../models/selectors/passwordReset';
import { resetPasswordAction } from '../../../models/actions/userActions';

import './PasswordReset.scss';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
    };
  }

  componentDidMount() {}

  validateEmailField() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const validataEmail = (email) => emailPattern.test(email);

    const { email } = this.state;
    this.setState({
      emailError: !validataEmail(email),
    });
  }

  onEmailChange(email) {
    this.setState({
      email: email.split(' ').join(''),
      emailError: '',
    });
  }

  resetPassword() {
    const { email, emailError } = this.state;
    const { resetPasswordAction } = this.props;

    if (emailError) {
      return;
    } else {
      console.log('here');
      resetPasswordAction(email);
    }
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <div className="offset-md-3 col-md-6 col-sm-12 offset-sm-0 center">
          <h3 className="font-weight-bold">Ți-am trimis pe email un link către pagina de ”Schimbare parolă”</h3>
        </div>
        <div className="row center m-0">
          <div className="col-12 p-0 mt-2">
            <span className="redirect-to-home">
              <NavLink to="/">Du-mă pe pagina principală {'>'}</NavLink>
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { email, emailError } = this.state;
    const { resetPasswordStatus } = this.props;

    return (
      <div className="password-reset-wrapper">
        <div className="force-push-menu-space"></div>
        <div className={`passord-reset-form container`}>
          {resetPasswordStatus && this.renderSuccessMessage()}

          {!resetPasswordStatus && (
            <div className="row">
              <div className="offset-md-3 col-md-6 col-sm-12 offset-sm-0 center">
                <h3 className="font-weight-bold">
                  Scrie aici adresa de email cu care ai creat contul. Îți trimitem un link pentru a schimba parola.
                </h3>
                <div className="form mt-5">
                  <div className={`form-row mb-3 ${emailError ? 'error' : ''}`}>
                    <label htmlFor="email">Adresa de e-mail</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="adresa@exemplu.ro"
                      value={email}
                      onChange={(event) => this.onEmailChange(event.target.value)}
                      onBlur={(event) => this.validateEmailField(event.target.value)}
                    />
                  </div>
                  <div className="row center m-0">
                    <div className="col-12 p-0 mt-3">
                      <button className="password-reset" type="button" onClick={() => this.resetPassword()}>
                        Resetează parola
                      </button>
                    </div>
                    <div className="col-12 p-0 mt-2">
                      {' '}
                      <span className="register">
                        <NavLink to="/login">Am deja cont {'>'}</NavLink>
                      </span>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    resetPasswordStatus: getResetStatus(state),
  }),
  (dispatch) => ({
    resetPasswordAction: (email) => dispatch(resetPasswordAction(email)),
  }),
)(PasswordReset);
