import React from 'react';
import GoogleLogin from 'react-google-login';
import { GOOGLE_CLIEN_ID } from '../../../constants/Constants';

const GoogleAuth = (props) => {
  const onGoogleAuthSuccess = (response) => {
    if (response.error) {
      return;
    }
    const { profileObj } = response;
    const { email, name, googleId } = profileObj;
    const payLoad = {
      email,
      name,
      googleId,
    };
    props.onLogin(payLoad);
  };

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIEN_ID}
      cookiePolicy="single_host_origin"
      onSuccess={onGoogleAuthSuccess}
      onFailure={onGoogleAuthSuccess}
    >
      Continuă cu Google
    </GoogleLogin>
  );
};

export default GoogleAuth;
