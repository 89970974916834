import { act } from 'react-dom/test-utils';
import {
  PUT_CHANGE_PROFILE_LOADING,
  PUT_CHANGE_PROFILE_SUCCESS,
  PUT_CHANGE_PROFILE_ERROR,
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  GET_MY_PROBLEMS_LOADING,
  GET_MY_PROBLEMS_SUCCESS,
  GET_MY_PROBLEMS_ERROR,
} from '../constants';

const initialState = {
  isSavePending: false,
  isProfilePenging: false,
  error: false,
  changeAccountSuccess: false,
  profile: {},
  myProblemsPenging: false,
  problems: {},
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PUT_CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        error: false,
        isSavePending: false,
        changeAccountSuccess: action.payload,
      };
    case PUT_CHANGE_PROFILE_ERROR:
      return {
        ...state,
        error: action.error,
        isSavePending: false,
        changeAccountSuccess: false,
      };
    case PUT_CHANGE_PROFILE_LOADING:
      return {
        ...state,
        isSavePending: action.isSavePending,
        error: false,
        changeAccountSuccess: false,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        isProfilePenging: false,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        isProfilePenging: false,
        error: true,
      };
    case GET_PROFILE_LOADING:
      return {
        ...state,
        isProfilePenging: true,
      };

    case GET_MY_PROBLEMS_SUCCESS:
      return {
        ...state,
        problems: action.payload,
        myProblemsPenging: false,
      };
    case GET_MY_PROBLEMS_ERROR:
      return {
        ...state,
        myProblemsPenging: false,
      };
    case GET_MY_PROBLEMS_LOADING:
      return {
        ...state,
        myProblemsPenging: true,
      };

    default:
      return state;
  }
}

export default profileReducer;
