import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { CollapseArrow, EllapseArrow } from '../../../assets/images/images';
import { getProblemDetailsVote } from '../../../models/actions/problemDetailsVote';
import {
  SpatiiPubliceLogo,
  MobilitateLogo,
  EducatieLogo,
  SanatateLogo,
  GuvernareLogo,
  OrdineSiSigurantaLogo,
  CulturaLogo,
} from '../../../assets/images/images';

import './VoteResults.scss';

class VoteResults extends Component {
  constructor() {
    super();
    this.state = {
      ellapseId: '',
      ellapse: false,
      alreadyVotedOnThisCategory: false,
      showVotedPopup: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.getProblemDetailsVote();
  }

  returnTagIdFromCategory(category) {
    switch (category) {
      case 'spatii-publice':
        return 1;
      case 'educatie':
        return 3;
      case 'sanatate-si-mediu':
        return 4;
      case 'buna-guvernare-si-digitalizare':
        return 5;
      case 'ordine-si-siguranta-publica':
        return 6;
      case 'cultura':
        return 7;
      case 'mobilitate-accesibilitate-si-siguranta-circulatiei':
        return 2;
      default:
        return '';
    }
  }

  redirectToProblemDetails(url) {
    window.open(url, '_blank');
  }

  switchCollapse(i) {
    this.setState({
      ellapseId: this.state.ellapseId === i ? '' : i,
      ellapse: !this.state.ellapse,
    });
  }

  redirectToProblemDetailsVotePage(category) {
    window.open(`/vote/${category}`, '_self');
  }

  render() {
    const { ellapseId } = this.state;
    const categoryId = this.returnTagIdFromCategory() - 1;
    const { voteStatsPerCategory } = this.props.problemDetailsVote;

    return (
      <div className="problem-details-vote container mt-5 pt-md-5 pt-0">
        <div className="row center mt-5">
          <div className="col-md-12">
            <h1>
              <b>Voturile comunității</b>
            </h1>
            <br />
          </div>
          <div className="col-md-8 offset-md-2 mb-3">
            <p className="font-s-16">
              Au fost exprimate {this.props.problemDetailsVote.totalVotesCount} voturi. Explorează fiecare domeniu
              pentru a afla ce probleme au fost considerate prioritare de către ieșeni. Vezi mai jos rezultatele
              votului, iar{' '}
              <b>
                <a href="#" target="_blank">
                  aici
                </a>{' '}
              </b>
              progresul soluționării pentru fiecare problemă prioritizată.
            </p>
          </div>
        </div>
        <div className="row my-5 problem-cards">
          {voteStatsPerCategory &&
            voteStatsPerCategory.map((category, i) => (
              <div
                className={`${
                  voteStatsPerCategory.length - 1 !== i
                    ? 'col-md-4 col-sm-12 selectable-card mb-5 p-4'
                    : 'offset-md-4 col-md-4 col-sm-12 selectable-card mb-5 px-5 mb-5'
                }`}
                key={category.problemTagId}
                onClick={() =>
                  this.redirectToProblemDetailsVotePage(
                    category.problemTagId === 1
                      ? 'spatii-publice'
                      : category.problemTagId === 2
                      ? 'mobilitate-accesibilitate-si-siguranta-circulatiei'
                      : category.problemTagId === 3
                      ? 'educatie'
                      : category.problemTagId === 4
                      ? 'sanatate-si-mediu'
                      : category.problemTagId === 5
                      ? 'buna-guvernare-si-digitalizare'
                      : category.problemTagId === 6
                      ? 'ordine-si-siguranta-publica'
                      : 'cultura',
                  )
                }
              >
                <div className="left-side float-left">
                  <p>
                    <span>
                      <b>{category.voteCount}</b>
                    </span>{' '}
                    voturi în total
                  </p>
                  <p className="font-s-13 smaller-font">
                    <span>
                      <b>{category.problemsToVoteCount}</b>
                    </span>{' '}
                    teme pentru vot
                  </p>
                  {category.problemTagId === 1 && <p className="tag spatii mt-3">Spații publice</p>}
                  {category.problemTagId === 2 && (
                    <p className="tag mobilitate mt-3">Mobilitate, accesibilitate și siguranța circulației</p>
                  )}
                  {category.problemTagId === 3 && <p className="tag educatie mt-3">Educație</p>}
                  {category.problemTagId === 4 && <p className="tag sanatate mt-3">Sănătate și mediu</p>}
                  {category.problemTagId === 5 && <p className="tag guvernare mt-3">Bună guvernare și digitalizare</p>}
                  {category.problemTagId === 6 && <p className="tag ordine mt-3">Ordine și siguranță publică</p>}
                  {category.problemTagId === 7 && <p className="tag cultura mt-3">Cultură</p>}
                </div>
                <div className="right-side">
                  {category.problemTagId === 1 ? (
                    <SpatiiPubliceLogo />
                  ) : category.problemTagId === 2 ? (
                    <MobilitateLogo />
                  ) : category.problemTagId === 3 ? (
                    <EducatieLogo />
                  ) : category.problemTagId === 4 ? (
                    <SanatateLogo />
                  ) : category.problemTagId === 5 ? (
                    <GuvernareLogo />
                  ) : category.problemTagId === 6 ? (
                    <OrdineSiSigurantaLogo />
                  ) : (
                    <CulturaLogo />
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2 offset-sm-0 col-sm-12 problem mb-3 no-background">
            <h2>
              <b>Cea mai votată temă din fiecare domeniu</b>
            </h2>
            <br />
          </div>
        </div>
        {voteStatsPerCategory &&
          voteStatsPerCategory.map((problem, i) => (
            <div className="row" key={problem.topProblem.problemToVoteId}>
              <div className="col-md-8 offset-md-2 offset-sm-0 col-sm-12 problem mb-3">
                <div className="left-side float-left tag-select">
                  {problem.problemTagId === 1 && (
                    <p
                      className="tag spatii mt-3"
                      onClick={() => this.redirectToProblemDetailsVotePage('spatii-publice')}
                    >
                      Spații publice
                    </p>
                  )}
                  {problem.problemTagId === 2 && (
                    <p
                      className="tag mobilitate mt-3"
                      onClick={() =>
                        this.redirectToProblemDetailsVotePage('mobilitate-accesibilitate-si-siguranta-circulatiei')
                      }
                    >
                      Mobilitate, accesibilitate și siguranța circulației
                    </p>
                  )}
                  {problem.problemTagId === 3 && (
                    <p className="tag educatie mt-3" onClick={() => this.redirectToProblemDetailsVotePage('educatie')}>
                      Educație
                    </p>
                  )}
                  {problem.problemTagId === 4 && (
                    <p
                      className="tag sanatate mt-3"
                      onClick={() => this.redirectToProblemDetailsVotePage('sanatate-si-mediu')}
                    >
                      Sănătate și mediu
                    </p>
                  )}
                  {problem.problemTagId === 5 && (
                    <p
                      className="tag guvernare mt-3"
                      onClick={() => this.redirectToProblemDetailsVotePage('buna-guvernare-si-digitalizare')}
                    >
                      Bună guvernare și digitalizare
                    </p>
                  )}
                  {problem.problemTagId === 6 && (
                    <p
                      className="tag ordine mt-3"
                      onClick={() => this.redirectToProblemDetailsVotePage('ordine-si-siguranta-publica')}
                    >
                      Ordine și siguranță publică
                    </p>
                  )}
                  {problem.problemTagId === 7 && (
                    <p className="tag cultura mt-3" onClick={() => this.redirectToProblemDetailsVotePage('cultura')}>
                      Cultură
                    </p>
                  )}
                  <h4>
                    <b>{problem.topProblem.title}</b>
                  </h4>
                  <br />
                  <p>{problem.topProblem.description}</p>
                </div>
                <div className="existing-votes">
                  <p>
                    <b>{problem.voteCount}</b>
                    <br />
                    <span>voturi</span>
                  </p>
                </div>
                {/* <div className="right-side center float-right">
                  
                </div> */}
                <div className="extra-problems">
                  <p className="extra-problems-title" onClick={() => this.switchCollapse(i)}>
                    {problem.topProblem.linkedProblems && problem.topProblem.linkedProblems.length} probleme incluse{' '}
                    <span>
                      {problem.topProblem.linkedProblems &&
                      problem.topProblem.linkedProblems.length !== 0 &&
                      ellapseId === i ? (
                        <EllapseArrow />
                      ) : (
                        <CollapseArrow />
                      )}
                    </span>
                  </p>
                  {ellapseId === i && (
                    <ul>
                      {problem.topProblem.linkedProblems.map((linkedProblem) => (
                        <li
                          key={linkedProblem.creationDate}
                          onClick={() => this.redirectToProblemDetails(linkedProblem.url)}
                        >
                          {linkedProblem.title} &middot;{' '}
                          <span> {moment(linkedProblem.creationDate).format('MMM DD')} </span> &middot;{' '}
                          <span> {linkedProblem.proposerDisplayName} </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          ))}
        <div className="row my-5">
          <div className="col-md-4 offset-md-4">
            <hr />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    problemDetailsVote: state.problemDetailsVote.problemDetailsVote,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProblemDetailsVote: (problemId) => dispatch(getProblemDetailsVote(problemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoteResults);
