import React from 'react';
import { NavLink } from 'react-router-dom';
import './Dashboard.scss';

const Sidebar = (props) => {
  const { userType } = props.userData;
  const isAdmin = userType === 'Admin';
  return (
    <div className="left-sidebar">
      <ul className="user-menu mt-5">
        {isAdmin && (
          <>
            {/* <li>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li> */}
            <li>
              <NavLink to="/dashboard/list-problems">Lista probleme</NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/list-users">Lista Utilizatori</NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
