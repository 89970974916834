import React, { useEffect } from 'react';
import './Settlement.scss';
import { NavLink } from 'react-router-dom';
import {
  SpatiiPubliceLogo,
  MobilitateLogo,
  EducatieLogo,
  SanatateLogo,
  GuvernareLogo,
  OrdineSiSigurantaLogo,
  CulturaLogo,
} from '../../../assets/images/images';

const Settlement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const settlement = [
    {
      problemTagId: 1,
    },
    {
      problemTagId: 2,
    },
    {
      problemTagId: 3,
    },
    {
      problemTagId: 4,
    },
    {
      problemTagId: 5,
    },
    {
      problemTagId: 6,
    },
    {
      problemTagId: 7,
    },
  ];

  return (
    <div className="container settlement mb-5 pb-5">
      <div class="force-push-menu-space"></div>
      <div className="row margin-bottom-50">
        <div className="col-md-12 center">
          <h2 className="font-weight-bold">Soluționare</h2>
          <p>Alege o problemă de mai jos și urmărește tot ce e nou cu privire la soluționare</p>
        </div>
      </div>
      <div className="row my-5 problem-cards">
        {settlement &&
          settlement.map((category, i) => (
            <a
              className={`${
                settlement.length - 1 !== i
                  ? 'col-md-4 col-sm-12 selectable-card mb-5 mt-5 p-4'
                  : 'offset-md-4 col-md-4 col-sm-12 selectable-card mb-5 mt-5 px-5'
              }`}
              key={category.problemTagId}
              href={`${
                category.problemTagId === 1
                  ? 'https://afla.solutiicolaborative.ro/spatii-publice'
                  : category.problemTagId === 2
                  ? 'https://afla.solutiicolaborative.ro/mobilitate-accesibilitate-si-siguranta-circulatiei'
                  : category.problemTagId === 3
                  ? 'https://afla.solutiicolaborative.ro/educatie'
                  : category.problemTagId === 4
                  ? 'https://afla.solutiicolaborative.ro/sanatate-si-mediu'
                  : category.problemTagId === 5
                  ? 'https://afla.solutiicolaborative.ro/buna-guvernare-si-digitalizare'
                  : category.problemTagId === 6
                  ? 'https://afla.solutiicolaborative.ro/ordine-si-siguranta-publica'
                  : 'https://afla.solutiicolaborative.ro/cultura'
              }`}
            >
              <div className="left-side float-left">
                {category.problemTagId === 1 && (
                  <>
                    <p className="tag spatii mt-3">Spații publice</p>
                    <p className="tag-content">
                      <b>Insuficiente spații amenajate pentru recreere.</b>
                    </p>
                  </>
                )}
                {category.problemTagId === 2 && (
                  <>
                    <p className="tag mobilitate mt-3">Mobilitate</p>
                    <p className="tag-content">
                      <b>
                        Bicicliștii din Iași nu se simt în siguranță și încurajați să traverseze orașul cu bicicleta.
                      </b>
                    </p>
                  </>
                )}
                {category.problemTagId === 3 && (
                  <>
                    <p className="tag educatie mt-3">Educație</p>
                    <p className="tag-content">
                      <b>
                        Prin programa școlară actuală, elevii nu dobândesc abilități suficiente pentru provocările
                        vieții reale.
                      </b>
                    </p>
                  </>
                )}
                {category.problemTagId === 4 && (
                  <>
                    <p className="tag sanatate mt-3">Sănătate și mediu</p>
                    <p className="tag-content">
                      <b>Grad ridicat de poluare a aerului.</b>
                    </p>
                  </>
                )}
                {category.problemTagId === 5 && (
                  <>
                    <p className="tag guvernare mt-3">Bună guvernare și digitalizare</p>
                    <p className="tag-content">
                      <b>Birocrația excesivă în interacțiunea cu Primăria Iași și instituțiile subordonate.</b>
                    </p>
                  </>
                )}
                {category.problemTagId === 6 && (
                  <>
                    <p className="tag ordine mt-3">Ordine și siguranță publică</p>
                    <p className="tag-content">
                      <b>
                        Unii ieșeni nu se simt în siguranță în oraș în raport cu modul în care forțele de ordine își fac
                        simțită prezența.
                      </b>
                    </p>
                  </>
                )}
                {category.problemTagId === 7 && (
                  <>
                    <p className="tag cultura mt-3">Cultură</p>{' '}
                    <p className="tag-content">
                      <b>
                        Valorificarea insuficientă a clădirilor de patrimoniu și a spațiilor istorice din zona centrală.
                      </b>
                    </p>
                  </>
                )}
              </div>
              <div className="right-side">
                {category.problemTagId === 1 ? (
                  <SpatiiPubliceLogo />
                ) : category.problemTagId === 2 ? (
                  <MobilitateLogo />
                ) : category.problemTagId === 3 ? (
                  <EducatieLogo />
                ) : category.problemTagId === 4 ? (
                  <SanatateLogo />
                ) : category.problemTagId === 5 ? (
                  <GuvernareLogo />
                ) : category.problemTagId === 6 ? (
                  <OrdineSiSigurantaLogo />
                ) : (
                  <CulturaLogo />
                )}
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

export default Settlement;
