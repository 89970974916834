import React, { useEffect } from 'react';
import './CookiePolicy.scss';

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cookie-policy">
      <h1 className="center">Politica de utilizare cookie-uri</h1>
      <p>
        Website-ul solutiicolaborative.ro folosește module cookie pentru a oferi utilizatorilor o experienţă online
        ușoară, interactivă, completă şi axată pe un timp scurt de reacție. Prin navigarea pe website-ul
        www.solutiicolaborative.ro vă exprimați acordul cu privire la folosirea datelor stocate de cookies, conform
        articolului 4 (51) din Legea nr. 506/2004 privind prelucrarea datelor cu caracter personal şi protecţia vieţii
        private în sectorul comunicaţiilor electronice.
      </p>
      <p>
        Dezactivarea și refuzul de a primi cookies pot duce la blocarea, neafișarea sau utilizarea greoaie a anumitor
        secțiuni sau pagini ale acestui website.
      </p>
      <h2>Ce sunt cookie-urile?</h2>
      <p>
        “Internet Cookie” (termen cunoscut și ca “browser cookie” sau “HTTP cookie” sau pur și simplu “cookie”) este un
        fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe computerul, terminalul mobil sau
        alte echipamente ale unui utilizator de pe care este accesat internetul. Cookies se instalează în cazul în care
        se acceptă navigarea pe website, fiind o legătură între utilizatorul browserului și website.
      </p>
      <p>
        Această tehnologie de stocare automată a datelor este foarte răspândită și se folosește pentru a colecta
        informații precum tipul de browser și sistemul de operare, pagina sursă, denumirea domeniului din care
        vizitatorul s-a conectat la website-ul www.solutiicolaborative.ro etc., cu scopul de a înțelege cum folosesc
        vizitatorii această pagină web.
      </p>
      <p>
        Un modul cookie nu conţine şi nu este alcătuit din fragmente de cod, în consecinţă acesta nu poate fi rulat ori
        executat în nicio modalitate. Cookie-ul nu poate fi replicat sau duplicat şi nu poate produce nicio modificare
        în dispozitivul pe care este stocat, motiv pentru care nu poate reprezenta nicio ameninţare pentru securitatea
        informaţiilor stocate în dispozitivul dumneavoastră.
      </p>
      <p>
        Utilizatorii își pot configura browserul pentru a respinge fișierele cookie. În plus, utilizatorii pot seta
        browserul pentru a bloca cookies sau pentru a afişa o notificare de avertizare, înainte ca un cookie să fie
        stocat pe dispozitivul utilizat.
      </p>
      <h2>Cum putem controla cookie-urile?</h2>
      <p>
        Consimțământul pentru utilizarea cookie-urilor se acordă și poate fi revocat prin modificarea setărilor de pe
        browser-ul tău, care îți permite să accepți sau să refuzi setarea tuturor sau a unor cookie-uri. Aceste setări
        se găsesc de obicei în meniul ‘opțiuni’ sau ‘preferințe’ din browser.
      </p>
      <div>
        Pentru a înțelege aceste setări, ar putea fi utile următoarele linkuri{' '}
        <ul>
          <li>Setările pentru cookie din Internet Explorer</li>
          <li>Setările pentru cookie din Firefox</li>
          <li>Setările pentru cookie din Chrome</li>
          <li>Setările pentru cookie din Safari</li>
        </ul>
      </div>
      <p>
        Poți seta browserul să te avertizeze înainte de a accepta cookie-urile sau poți să-l setezi să le refuze.
        Recurge la butonul “help” (ajutor) al browser-ului tău, pentru a afla cum poți face acest lucru. Poți să
        controlezi și/ sau să ștergi cookie-urile, după cum dorești. Poți șterge toate modulele cookie, care sunt deja
        pe computerul tău și poți seta cele mai multe browsere pentru a împiedică plasarea acestora.
      </p>
      <h2>Securitatea datelor</h2>
      <p>
        Pentru mai multe detalii, te rugăm să accesezi politica noastră privind prelucrarea datelor personale. (link
        Politica de prelucrare a datelor personale)
      </p>
      <h2>Amendamente la Politica de utilizare cookie-uri</h2>
      <p>
        Orice modificare privind prezenta Politică de utilizare cookies va fi publicată și afișată pe această pagină cu
        indicarea ultimei date actualizate.
      </p>
      <p>Prezenta Politică de utilizare cookies se aplică din data de 01.11.2020</p>
    </div>
  );
};

export default CookiePolicy;
