import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUsersListAction } from '../../../models/actions/admin';
import { getIsUsersListLoading, getUsersList } from '../../../models/selectors/admin';

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.props.getUsers();
  }

  renderUserRow(userData) {
    const {
      userId,
      email,
      firstName,
      lastName,
      userType,
      userStatus,
      userName,
      isAvailableForInterview,
      isSubscribedToNewsletter,
    } = userData;
    const userTypes = ['NormalUser', 'Admin'];
    const userStatuses = ['Created', 'Verified', 'Suspended'];

    return (
      <tr key={userId}>
        <td>{email}</td>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{userTypes[userType - 1]}</td>
        <td>{userStatuses[userStatus - 1]}</td>
        <td>{userName}</td>
        <td>{isAvailableForInterview + ''}</td>
        <td>{isSubscribedToNewsletter + ''}</td>
        <td>
          <ul className="actions center">
            <li>
              {userType !== 2 && (
                <button type="button" style={{ margin: '1px', width: '120px' }}>
                  Promoveaza Admin
                </button>
              )}
            </li>
            <li>
              <button type="button" style={{ margin: '1px', width: '120px' }}>
                Sterge utilizator
              </button>
            </li>
          </ul>
        </td>
      </tr>
    );
  }

  renderUsersList() {
    const { usersList } = this.props;
    return (
      <table className="table table-lg table-striped">
        <thead>
          <tr>
            <th scope="col">email</th>
            <th scope="col">firstName</th>
            <th scope="col">lastName</th>
            <th scope="col">userType</th>
            <th scope="col">userStatus</th>
            <th scope="col">userName</th>
            <th scope="col">isAvailableForInterview</th>
            <th scope="col">isSubscribedToNewsletter</th>
            <th scope="col">Actiuni</th>
          </tr>
        </thead>
        <tbody>{usersList.map((currentUser) => this.renderUserRow(currentUser))}</tbody>
      </table>
    );
  }
  render() {
    const { isUsersListLoading } = this.props;
    return !isUsersListLoading && this.renderUsersList();
  }
}

export default connect(
  (state) => ({
    isUsersListLoading: getIsUsersListLoading(state),
    usersList: getUsersList(state),
  }),
  (dispatch) => ({
    getUsers: () => dispatch(getUsersListAction()),
  }),
)(ListUsers);
