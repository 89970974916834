import createReducer from '../store/createReducer';

const initialState = {
  filterTags: [],
  isTagsLoading: true,
  selectedTags: [],
};

function GET_PROBLEM_TAGS_REQUEST(nextState) {
  nextState.isTagsLoading = false;
}
function GET_PROBLEM_TAGS_SUCCESS(nextState, action) {
  nextState.filterTags = action.filterTags;
  nextState.isTagsLoading = false;
}

function GET_PROBLEM_TAGS_FAILURE(nextState) {
  nextState.isTagsLoading = false;
  nextState.filterTags = [];
}

function SET_FILTER_TAGS(nextState, action) {
  nextState.selectedTags = action.selectedTags;
}

export default createReducer(initialState, {
  GET_PROBLEM_TAGS_REQUEST,
  GET_PROBLEM_TAGS_SUCCESS,
  GET_PROBLEM_TAGS_FAILURE,
  SET_FILTER_TAGS,
});
