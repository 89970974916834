import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { config } from '../../../models/api/apiConfig';

import './ProfileProblems.scss';

export const ProfileProblems = ({ problems, history }) => {
  const [acceptedProblems, setAcceptedProblems] = useState([]);
  const [pendingProblems, setPendingProblems] = useState([]);
  const [rejectedProblems, setRejectedProblems] = useState([]);
  const [menuType, setMenuType] = useState('accepted');

  useEffect(() => {
    let accepted = [];
    let rejected = [];
    let pending = [];
    problems.map((problem, i) => {
      if (problem.problemStatus === 2) pending.push(problem);
      if (problem.problemStatus === 4) accepted.push(problem);
      if (problem.problemStatus === 5) rejected.push(problem);

      if (i === problems.length - 1) {
        setAcceptedProblems(accepted);
        setPendingProblems(pending);
        setRejectedProblems(rejected);
      }
    });
  }, []);

  const changeProblemsMenu = (menu) => {
    setMenuType(menu);
  };

  const redirectToProblemDetails = (problem) => {
    // history.push(`/problems/${problem.problemId}/${problem.safeTitle}`);
    window.open(`/problems/${problem.problemId}/${problem.safeTitle}`, '_blank');
  };

  return (
    <div className="my-problems">
      <div className="row">
        <div className="col-12">
          <h4 className="font-weight-bold mb-5">Probleme înscrise de tine</h4>
          <p className="mt-3 mb-3 font-s-14">
            Aici poți vedea problemele înscrise de tine și stadiul lor. Vei putea schimba conținutul doar acelor
            probleme care au fost refuzate pentru publicare, urmând ca tu să corectezi postarea conform sfaturilor de la
            administrator.
          </p>
          <hr />
        </div>
      </div>
      <div className="row">
        <div
          className={`col-md-3 col-sm-4 sub-menu-problems ${menuType === 'accepted' && 'active'}`}
          onClick={() => {
            changeProblemsMenu('accepted');
          }}
        >
          Acceptate ({acceptedProblems.length})
        </div>
        <div
          className={`col-md-3 col-sm-4 sub-menu-problems ${menuType === 'pending' && 'active'}`}
          onClick={() => {
            changeProblemsMenu('pending');
          }}
        >
          În așteptare ({pendingProblems.length})
        </div>
        <div
          className={`col-md-3 col-sm-4 sub-menu-problems ${menuType === 'rejected' && 'active'}`}
          onClick={() => {
            changeProblemsMenu('rejected');
          }}
        >
          Respinse ({rejectedProblems.length})
        </div>
      </div>
      {Boolean(menuType === 'accepted') && acceptedProblems
        ? acceptedProblems.map((problem, i) => (
            <div
              key={i}
              className={`problem-content row mb-3 ${i === 0 && 'mt-5'}`}
              onClick={() => redirectToProblemDetails(problem)}
            >
              <div className="col-8 align-self-center">
                <p className="font-weight-bold font-s-16">{problem.title}</p>
                <p className="problem-footer font-s-14">
                  {moment(problem.creationDate).format('MMM DD ')} &#183; {problem.tags[0].name}
                </p>
              </div>
              <div className="col-4 img-wrapper-my-problem align-self-center center">
                <img src={`${config.apiUrl}${problem.resources[0].url}`} className="img-fluid" />
              </div>
            </div>
          ))
        : ''}
      {Boolean(menuType === 'pending') && pendingProblems.length !== 0
        ? pendingProblems.map((problem, i) => (
            <div key={i} className={`row mb-3 ${i === 0 && 'mt-5'}`}>
              <div className="col-8 align-self-center">
                <p className="font-weight-bold font-s-16">{problem.title}</p>
                <p className="problem-footer font-s-14">
                  {moment(problem.creationDate).format('MMM DD ')} &#183; {problem.tags[0].name}
                </p>
              </div>
              <div className="col-4 img-wrapper-my-problem align-self-center center">
                <img src={`${config.apiUrl}${problem.resources[0].url}`} className="img-fluid" />
              </div>
            </div>
          ))
        : ''}
      {Boolean(menuType === 'rejected') && rejectedProblems.length !== 0
        ? rejectedProblems.map((problem, i) => (
            <div key={i} className={`row mb-3 ${i === 0 && 'mt-5'}`}>
              <div className="col-8 align-self-center">
                <p className="font-weight-bold font-s-16">{problem.title}</p>
                <p className="problem-footer font-s-14">
                  {moment(problem.creationDate).format('MMM DD ')} &#183; {problem.tags[0].name}
                </p>
              </div>
              <div className="col-4 img-wrapper-my-problem align-self-center center">
                <img src={`${config.apiUrl}${problem.resources[0].url}`} className="img-fluid" />
              </div>
            </div>
          ))
        : ''}
    </div>
  );
};

export default ProfileProblems;
