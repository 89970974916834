import { createSelector } from 'reselect';

const registerStatus = (state) => state.registerReducers.registerStatus;
export const getRegisterStatus = (state) => state.registerReducers.registerStatus;
export const getIsEmailIsAvailable = (state) => state.registerReducers.isEmailAvailable;

export const getIsFormLoading = createSelector([registerStatus], (status) => status === 'loading' || false);
export const getIsAccountRegistered = createSelector([registerStatus], (status) => status === 'success');

export const getAccountValidationStatus = (state) => state.registerReducers.validateStatus;
