import createReducer from '../store/createReducer';

const initialState = {
  problemData: {
    title: '',
    description: '',
    tagIds: [],
    resources: [],
  },
  submitStatus: '',
};

function UPDATE_PROBLEM_FIELD(nextState, action) {
  nextState.problemData[action.fieldName] = action.fieldValue;
}

function UPDATE_PROBLEM_TAGS(nextState, action) {
  nextState.problemData.tagIds = action.tag;
}

function UPDATE_PROBLEM_RESOURCES(nextState, action) {
  nextState.problemData.resources = action.resource;
}

function SUBMIT_PROBLEM_REQUEST(nextState) {
  nextState.submitStatus = 'loading';
}

function SUBMIT_PROBLEM_SUCCESS(nextState, action) {
  nextState.submitStatus = action.submitStatus === 200 ? 'success' : 'fail';
}

function UPDATE_SUBMIT_STATUS(nextState, action) {
  nextState.submitStatus = action.submitStatus;
}

function SUBMIT_PROBLEM_FAILURE(nextState) {
  nextState.submitStatus = '';
}

export default createReducer(initialState, {
  UPDATE_PROBLEM_FIELD,
  UPDATE_PROBLEM_TAGS,
  UPDATE_PROBLEM_RESOURCES,
  SUBMIT_PROBLEM_REQUEST,
  SUBMIT_PROBLEM_SUCCESS,
  SUBMIT_PROBLEM_FAILURE,
  UPDATE_SUBMIT_STATUS,
  // UPDATE_PROBLEM_SUBMIT_STATUS,
});
