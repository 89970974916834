import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  CentricLogoBlk,
  FacebookLogo,
  InstagramLogo,
  LinkdedInLogo,
  Logo,
  LogoCivica,
  MairLogo,
  MambuLogoBlk,
  OpenSocientyLogo,
} from '../../../assets/images/images';
import './Footer.scss';

export const Footer = () => (
  <div className="footer">
    <div className="footer-wrapper container">
      <div className="row">
        <div className="col-md-1 col-sm-6 center footer-img-wrapper mb-4 footer-logo-wrapper">
          <Logo />
        </div>
        <div className="col-md-2 col-sm-6 center mb-4 mb-sm-0">
          <ul>
            <li>
              <NavLink to="/about">Despre proiect</NavLink>
            </li>
            <li>
              <NavLink to="/regulations">Regulament</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li className="inline">
              <FacebookLogo />
              <LinkdedInLogo />
              <InstagramLogo />
            </li>
            {/* <li>
            <NavLink to="/partners">Parteneri</NavLink>
          </li>
          <li>
            <NavLink to="/how-to-contribute">Cum poți contribui</NavLink>
          </li> */}
          </ul>
        </div>
        <div className="col-md-3 col-sm-6 center mb-4 mb-sm-0">
          <ul>
            <li>
              <NavLink to="/personal-data">Prelucrarea datelor personale</NavLink>
            </li>
            <li>
              <NavLink to="/cookie-policy">Politica de cookies</NavLink>
            </li>
            <li>
              <NavLink to="/terms-and-conditions">Termeni si conditii</NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-6 center mb-4 mb-sm-0">
          <p>
            Proiect implementat de Asociația CIVICA,
            <br />
            co-finanțat de Primăria Iași și Open Society Foundations,
            <br />
            sponsorizat de Centric IT Solutions Romania și Mambu
          </p>
          <ul>
            <li className="inline footer-logo-wrapper">
              <i className="mr-3">
                <LogoCivica />
              </i>
              <i className="mr-3">
                <OpenSocientyLogo />
              </i>
              <i className="mr-3">
                <CentricLogoBlk />
              </i>
              <i className="mr-3">
                <MambuLogoBlk />
              </i>
              <i className="show-on-small mr-3">
                <MairLogo />
              </i>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-2 offset-md-10 col-sm-12  offset-sm-0 left mair-logo center hide-on-small">
        <MairLogo />
      </div>
    </div>
  </div>
);

export default Footer;
