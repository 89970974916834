import createReducer from '../store/createReducer';

const initialState = {
  problemsList: [],
  isProblemListLoading: true,
  usersList: [],
  isUsersListLoading: true,
  isProblemAccepted: false,
  isProblemDetailsLoading: true,
  problemDetails: '',
  userDetails: {},
};

function GET_PROBLEMS_LIST_REQUEST(nextState) {
  nextState.isProblemListLoading = true;
}

function GET_PROBLEMS_LIST_SUCCESS(nextState, action) {
  nextState.problemsList = action.problemsList;
  nextState.isProblemListLoading = false;
}

function GET_PROBLEMS_LIST_FAILURE(nextState) {
  nextState.isProblemListLoading = false;
  nextState.problemsList = [];
}

function GET_USERS_LIST_REQUEST(nextState) {
  nextState.isUsersListLoading = true;
}
function GET_USERS_LIST_SUCCESS(nextState, action) {
  nextState.usersList = action.usersList;
  nextState.isUsersListLoading = false;
}
function GET_USERS_LIST_FAILURE(nextState) {
  nextState.isUsersListLoading = true;
  nextState.usersList = [];
}

function PROBLEM_ACCEPTED_REQUEST(nextState) {
  nextState.isProblemListLoading = true;
}

function PROBLEM_ACCEPTED_SUCCESS(nextState, action) {
  nextState.isProblemAccepted = action.isProblemAccepted;
  nextState.isProblemListLoading = false;
}

function PROBLEM_ACCEPTED_FAILURE(nextState) {
  nextState.isProblemListLoading = false;
  nextState.isProblemAccepted = false;
}

function GET_PROBLEM_DETAILS_REQUEST(nextState) {
  nextState.isProblemDetailsLoading = true;
}
function GET_PROBLEM_DETAILS_SUCCESS(nextState, action) {
  nextState.problemDetails = action.problemDetails;
  nextState.isProblemDetailsLoading = false;
}
function GET_PROBLEM_DETAILS_FAILURE(nextState) {
  nextState.isProblemDetailsLoading = false;
  nextState.problemDetails = '';
}

function GET_USER_DETAILS_SUCCESS(nextState, action) {
  nextState.isProblemDetailsLoading = false;
  nextState.userDetails = action.userDetails;
}

export default createReducer(initialState, {
  GET_PROBLEMS_LIST_REQUEST,
  GET_PROBLEMS_LIST_SUCCESS,
  GET_PROBLEMS_LIST_FAILURE,

  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,

  PROBLEM_ACCEPTED_REQUEST,
  PROBLEM_ACCEPTED_SUCCESS,
  PROBLEM_ACCEPTED_FAILURE,

  GET_PROBLEM_DETAILS_REQUEST,
  GET_PROBLEM_DETAILS_SUCCESS,
  GET_PROBLEM_DETAILS_FAILURE,

  GET_USER_DETAILS_SUCCESS,
});
