import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import { approveProblemAction, getProblemsListAction, rejectProblemAction } from '../../../models/actions/admin';
import { getIsProblemaccepted, getIsProblemsListLoading, getProblemsList } from '../../../models/selectors/admin';
import moment from 'moment';
import ModalDialog from '../../Common/ModalDialog';

class ListProblems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProblemStateModalOpen: false,
      problemID: '',
      problemState: '',
    };
    this.routeChange = this.routeChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getProblems();
  }

  routeChange(problemID) {
    return (window.location.href = `/dashboard/problem-details/${problemID}`);
  }

  setProblemData(problemID, problemState) {
    this.setState(
      {
        problemID,
        problemState,
      },
      () => this.toggleProblemStateModal(),
    );
  }

  toggleProblemStateModal() {
    const { isProblemStateModalOpen } = this.state;
    this.setState({
      isProblemStateModalOpen: !isProblemStateModalOpen,
    });
  }

  handleProblemState() {
    const { problemID, problemState } = this.state;
    const { approveProblem, rejectProblem } = this.props;
    if (problemState === 'approve') {
      approveProblem(problemID);
    } else {
      rejectProblem(problemID);
    }

    this.toggleProblemStateModal();
  }

  renderProblemStateModal() {
    const { isProblemStateModalOpen, problemState, problemID } = this.state;
    const problemInfo = problemState === 'approve' ? 'aprobi' : 'respingi';

    if (!isProblemStateModalOpen) {
      return null;
    }

    const modalProps = {
      isOpen: isProblemStateModalOpen,
      modalTitle: 'Modificare status problema',
      onConfirm: () => this.handleProblemState(),
      onAbort: () => this.toggleProblemStateModal(),
    };

    return <ModalDialog {...modalProps}>Esti sigur ca vrei sa {problemInfo} aceasta problema?</ModalDialog>;
  }

  renderProblemTags(tag) {
    return <li key={tag.name}>{tag.name}</li>;
  }

  renderProblemRow(problem) {
    const { problemId, title, description, creationDate, problemStatus, tags } = problem;
    const statuses = ['Created', 'AwaitingValidation', 'AwaitingUserChanges', 'Validated', 'Rejected'];
    return (
      <tr key={problemId}>
        <td>{title}</td>
        <td>{description}</td>
        <td>{moment(creationDate).format('MMM DD ')}</td>
        <td>{statuses[problemStatus - 1]}</td>
        <td>
          <ul>{tags.map((tag) => this.renderProblemTags(tag))}</ul>
        </td>
        <td>
          <ul className="actions center">
            <li>
              {problemStatus !== 4 && (
                <button
                  type="button"
                  style={{ margin: '1px', width: '100px' }}
                  onClick={() => this.setProblemData(problemId, 'approve')}
                >
                  Aproba
                </button>
              )}
            </li>
            {/* <li>
              <button className="button" style={{ margin: '1px', width: '100px' }} type="button">
                Sugereaza o modificare
              </button>
            </li> */}
            <li>
              {problemStatus !== 5 && (
                <button
                  type="button"
                  style={{ margin: '1px', width: '100px' }}
                  onClick={() => this.setProblemData(problemId, 'reject')}
                >
                  Refuza
                </button>
              )}
            </li>
          </ul>
        </td>
        <td>
          <button type="button" onClick={() => this.routeChange(problemId)}>
            Detalii
          </button>
        </td>
      </tr>
    );
  }

  renderProblems() {
    const { problemsList } = this.props;
    return (
      <table className="table table-lg table-striped">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Creation Date</th>
            <th scope="col">Problem Status</th>
            <th scope="col">Tags</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>{problemsList.map((currentProblem) => this.renderProblemRow(currentProblem))}</tbody>
      </table>
    );
  }

  render() {
    const { isProblemsLoading } = this.props;

    return (
      <div>
        {!isProblemsLoading && this.renderProblems()}
        {this.renderProblemStateModal()}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isProblemsLoading: getIsProblemsListLoading(state),
    problemsList: getProblemsList(state),
    isProblemAccepted: getIsProblemaccepted(state),
  }),
  (dispatch) => ({
    getProblems: () => dispatch(getProblemsListAction()),
    approveProblem: (problemID) => dispatch(approveProblemAction(problemID)),
    rejectProblem: (problemID) => dispatch(rejectProblemAction(problemID)),
  }),
)(ListProblems);
