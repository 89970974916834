import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserStatus } from '../../../models/selectors/login';
import LoginForm from './LoginForm';
import { Redirect } from 'react-router-dom';
import { getRegisterStatus } from '../../../models/selectors/register';
import {
  resetUserRegisterStatusAction,
  resetUserRegisterStatusActionImediately,
} from '../../../models/actions/userActions';
import { isUserLoggedIn } from '../UserUtils';

const RegSuccessMessage = () => {
  window.scroll(0, 0);
  return (
    <div className="success">
      <div className="row mt-2 mb-2">
        <p>
          Mulțumim că te-ai înscris! Ți-am trimis pe email un link către platformă, să fim siguri că ai înregistrat
          adresa corectă.
        </p>
      </div>
    </div>
  );
};

class Login extends Component {
  componentDidMount() {
    const { userRegisterStatus, resetRegisterStatus } = this.props;
    if (userRegisterStatus === 'success') {
      resetRegisterStatus();
    }
  }
  componentWillUnmount() {
    this.props.resetRegisterStatusImediately();
  }

  render() {
    const { userRegisterStatus } = this.props;
    const isUserAuthorized = isUserLoggedIn();

    if (isUserAuthorized && this.props.location.state && this.props.location.state.tagId) {
      this.props.history.push(`/register-problem?category=${this.props.location.state.tagId}`);
    } else if (isUserAuthorized) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <div className="login-wrapper">
        <div className="force-push-menu-space"></div>
        {userRegisterStatus && (
          <div className="row">
            <div className="offset-md-3 col-md-6 col-sm-12 offset-sm-0">
              <RegSuccessMessage />
            </div>
          </div>
        )}

        <LoginForm />
      </div>
    );
  }
}
export default connect(
  (state) => ({
    userRegisterStatus: getRegisterStatus(state),
    userStatus: getUserStatus(state),
  }),
  (dispatch) => ({
    resetRegisterStatus: () => dispatch(resetUserRegisterStatusAction()),
    resetRegisterStatusImediately: () => dispatch(resetUserRegisterStatusActionImediately()),
  }),
)(Login);
