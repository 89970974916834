import React from 'react';
import { NavLink } from 'react-router-dom';
import { NotFoundIcon } from '../../../assets/images/images';

import './NotFoundPage.scss';

const TermsAndConditions = () => {
  return (
    <div className="not-found-page container mt-5 pt-5">
      <div className="row">
        <div className="col-lg-5 offset-lg-1 col-sm-12 offset-sm-0 mt-5">
          <p className="mt-5 font-s-20 font-weight-bold">
            Hei, ne bucurăm să întâlnim un ieșean implicat! Ești pe-aproape, dar nu tocmai acolo...{' '}
          </p>
          <p className="font-s-16">
            De fapt nici noi nu știm bine pe ce pagină ai vrut să intri. Hai pe Soluții colaborative și alegi de acolo
            destinația. Te ducem cât ai zice “implicare”.
          </p>
          <p className="font-s-16">
            {' '}
            Ne vedem pe <NavLink to="/">Homepage.</NavLink>
          </p>
        </div>
        <div className="col-5">
          <NotFoundIcon />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
