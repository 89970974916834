import createReducer from '../store/createReducer';
const initialState = {
  registerStatus: '',
  isEmailAvailable: true,
  validateStatus: '',
};

function ACCOUNT_REGISTER_REQUEST(nextState) {
  nextState.registerStatus = 'loading';
}
function ACCOUNT_REGISTER_SUCCESS(nextState, { registerStatus }) {
  nextState.registerStatus = registerStatus;
}
function ACCOUNT_REGISTER_FAILURE(nextState, { registerStatus }) {
  nextState.registerStatus = registerStatus;
}

function CHECK_EMAIL_EXISTENCE(nextState, { isEmailAvailable }) {
  nextState.isEmailAvailable = isEmailAvailable;
}

function ACCOUNT_ACTIVATION_SUCCESS(nextState, { validateStatus }) {
  nextState.validateStatus = validateStatus;
}

export default createReducer(initialState, {
  ACCOUNT_REGISTER_REQUEST,
  ACCOUNT_REGISTER_SUCCESS,
  ACCOUNT_REGISTER_FAILURE,
  CHECK_EMAIL_EXISTENCE,
  ACCOUNT_ACTIVATION_SUCCESS,
});
