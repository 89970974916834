import React, { useEffect } from 'react';
import './About_project.scss';
import aboutImages from '../../../assets/images/about';

const About_project = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-wrapper">
      <div className="about-project container pt-5 pt-md-0">
        <div className="row center">
          <div className="col-12 px-3 px-md-0">
            <h1 className="title-mobile">
              Un proiect despre cum ne
              <br /> implicăm și rezolvăm împreună
              <br /> problemele prioritare ale
              <br />
              orașului.
            </h1>
          </div>
        </div>

        <div className="row center bottom-mid-margin">
          <div className="col-lg-3 col-md-12 col-sm-12"> </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <p>
              <strong>Soluții colaborative în comunitate​</strong> este ​primul proiect prin care ieșenii hotărăsc
              prioritățile orașului în<strong> 7 domenii strategice de </strong>
              <strong>dezvoltare </strong> și imaginează, în echipe multidisciplinare, răspunsuri și
              <strong> soluții inovatoare, centrate pe cetățeni.</strong> Soluțiile vor fi implementate, după caz, în
              formule mixte de <strong>organizații, actori privați și autorități locale.</strong>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src={aboutImages.img_about_02} alt="" className="about-01 img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="rune-wrapper">
              <h2 className="rune">Cum a pornit totul?</h2>
            </div>
            <p>
              Proiectul face parte din{' '}
              <a target="_blank" href="https://www.opengovpartnership.org/members/iasi-romania/">
                măsurile
              </a>{' '}
              pe care Municipiul Iași și le-a asumat atunci când a devenit, în 2018, membru al inițiativei
              internaționale Parteneriatul pentru Guvernare Deschisă{' '}
              <a target="_blank" href="https://www.opengovpartnership.org/">
                (Open Government Partnership/OGP).
              </a>
            </p>
            <p>
              Ca urmare a aderării la principiile OGP, Primăria Iași își asumă să promoveze transparenţa actului
              administrativ, să încurajeze participarea societății civile la viaţa publică și să folosească noile
              tehnologii în serviciile publice locale.
            </p>
          </div>
        </div>
        <div className="row bottom-mid-margin">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="rune-wrapper">
              <h2 className="rune">
                Cum se încadrează proiectul
                <br /> nostru în acest context?
              </h2>
            </div>
            <p>
              Asociația CIVICA, împreună cu mai mulți reprezentanți ai comunității preocupați de Guvernarea Deschisă, a
              propus Primăriei Iași dezvoltarea unui mecanism de participare publică și co-crearea de soluții pentru
              probleme relevante din comunitate.
            </p>
            <p>
              Încă din 2014, de la înființare, CIVICA susține principiile guvernării deschise. 2020 este anul în care a
              demarat testarea unui mecanism de dezvoltare participativă a soluțiilor pentru oraș cu susținerea
              Primăriei Iași, a Open Society Foundations și a partenerilor din mediul privat.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src={aboutImages.img_about_01} alt="" className="about-02 img-fluid" />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src={aboutImages.img_about_03} alt="" className="about-03 img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="rune-wrapper">
              <h2 className="rune">Așadar, ce presupune proiectul ​Soluții colaborative în comunitate​?</h2>
            </div>
            <p>
              De la bun început vrem să precizăm că acest proiect este un exercițiu de activare a comunității (cetățeni,
              mediu academic, mediu de afaceri, ONG-uri, administrație) pentru a identifica problemele relevante și
              profunde ale orașului și pentru a găsi, împreună, soluțiile cele mai potrivite și de durată.
            </p>
            <p>
              Pornind de la probleme semnalate de ieșeni, vrem să le abordăm pe cele mai ”dureroase”. Le vom analiza și
              vom căuta, cu ajutorul cetățenilor și organizațiilor care au cunoștințe, experiență și expertiză în
              domeniile respective, soluțiile cele mai potrivite și eficiente.
            </p>
            <h3>Pentru aceasta, vom aplica principiile Design Thinking.</h3>
            <p>
              Este o abordare creativă centrată pe oameni, utilizată pentru a rezolva probleme complexe pentru un grup
              bine definit și pentru a obține soluții inovatoare.
            </p>
          </div>
        </div>
        <div className="bottom-mid-margin"></div>
        <div className="half center bottom-mid-margin">
          <p>
            Scopul proiectului, în acest prim an, este să familiarizeze pe ieșeni cu această abordare, să testăm cum pot
            fi aplicate, la noi, principiile guvernării deschise și gândirea de tip Design Thinking provocărilor
            orașului. Ne dorim ca prin acest proiect pilot să căpătăm încredere, cu toții, că ”se poate”, prin implicare
            și colaborare, să avem un oraș în care ne place să trăim.
          </p>
        </div>
        <div className="spacer"></div>
        <div className="half">
          <h1>Concret, iată ce ne propunem să facem în următorul an</h1>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="one-wrapper">
              <p className="one">
                Să aflăm de la ieșeni care sunt problemele relevante cu care se confruntă, pe 7 domenii strategice ale
                vieții comunității. Acestea sunt înscrise pe platforma soluțiicolaborative.ro pentru a fi consultate și
                votate de publicul larg.
              </p>
            </div>
            <div className="two-wrapper">
              <p className="two">
                Să prioritizăm, pe baza votului public și a evaluării unei comisii, cele mai relevante 7 probleme. De ce
                7? Pentru că am identificat 7 domenii strategice ale vieții orașului. Problemele de abordat vor fi
                dintre cele votate de ieșeni, pentru care se pot aplica principiile Design Thinking (probleme mai
                complexe care au nevoie de o înțelegere profundă, pentru care nu există deja o soluție cunoscută,
                probleme care afectează un număr mai mare de persoane, iar implementarea soluției intră în aria de
                responsabilitate a autorităților locale).
              </p>
            </div>
            <div className="three-wrapper">
              <p className="three">
                Să formăm 7 echipe multidisciplinare care să lucreze, ghidate de organizații mentor, la dezvoltarea
                soluțiilor pentru fiecare problemă. Echipele vor avea membri atât din rândul cetățenilor care au
                semnalat problema, cât și din administrație, mediul academic, de afaceri sau non-guvernamental.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="four-wrapper">
              <p className="four">
                Să documentăm, timp de 2 luni, problemele și impactul lor asupra ieșenilor (colectare de date și
                interviuri cu cetățenii).
              </p>
            </div>
            <div className="five-wrapper">
              <p className="five">
                Să organizăm un eveniment de tip ”Hackathon” de 2 zile în care cele 7 echipe vor lucra la găsirea de
                soluții inovatoare și la planuri de implementare.
              </p>
            </div>
            <div className="six-wrapper">
              <p className="six">
                Să testăm, să rafinăm și apoi să publicăm soluțiile găsite pe platformă pentru a fi consultate, dar mai
                ales pentru a primi idei de îmbunătățire.
              </p>
            </div>
            <div className="seven-wrapper">
              <p className="seven">
                Să monitorizăm buna punere în practică a soluțiilor și să contribuim la depășirea unor eventuale
                blocaje.
              </p>
            </div>
          </div>
        </div>
        <div className="bottom-mid-margin"></div>
        <div className="row">
          <div className="half">
            <h2 className="center">Încotro după acest proiect-pilot?</h2>
            <p>
              Ne asumăm acest exercițiu tocmai pentru a ști cum pot fi făcute lucrurile mai bine pe viitor. Ne propunem
              să creștem proiectul de la an la an, astfel încât acest mecanism de consultare și colaborare pentru
              problemele orașului să devină o componentă de bază în actul administrativ.
            </p>
            <p>
              Obiceiurile și tiparele de gândire se schimbă în timp; avem nevoie de răbdare și consecvență pentru a
              ajunge să colaborăm și să co-creăm soluții pentru oraș în mod firesc, ca parte a vieții comunității.
            </p>
            <p>
              Ceea ce începem acum prin Soluții Colaborative în Comunitate ne propunem să continuăm, atât timp cât va
              exista o nevoie reală de colaborare și identificare de soluții inovatoare pentru problemele comune.
            </p>
            <div className="bottom-mid-margin"></div>
            <div className="bottom-mid-margin center">
              <a
                href="https://solutiicolaborative.ro/static_files/Solutii_Colaborative_prezentare.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="btn download-button mt-2 "
              >
                Descarcă prezentarea proiectului
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About_project;
