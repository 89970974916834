import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Contact.scss';
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-wrapper container">
      <div className="contact  ">
        <div className="row ">
          <div className="col 12">
            <h1>Ia legătura cu noi!</h1>
          </div>
        </div>
        <div className="after-lg"></div>
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>Ai multe întrebări? Trimite un e-mail.</h2>
            <br />
            <p>
              Îți vom răspunde cu plăcere la orice întrebare vei avea despre proiect sau provocările tehnice pe care le
              întâmpini pe platformă. Primim bucuroși și idei sau sugestii de cum putem face lucrurile mai bine.
            </p>
            <p>
              <a href="mailto:solutii@asociatiacivica.ro">solutii@asociatiacivica.ro</a>
            </p>
            <p className="after-lg"></p>
            <h2>Să ne conectăm, online!</h2>
            <p className="after"></p>
            <p>
              Urmărește-ne în social media pentru a afla noutăți despre proiect sau pentru a intra în conversații cu
              noi.
            </p>
          </div>
          <div className="col-lg-3 col-md-8 col-sm-12 mt-3">
            <h2>Linkuri utile</h2>
            <br />
            <NavLink to="/regulations">Regulament</NavLink> <br />
            <NavLink to="/terms-and-conditions">Termeni și condiții</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
