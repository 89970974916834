import createReducer from '../store/createReducer';

const initialState = {
  resetPasswordStatus: '',
  checkTokenPasswordResetStatus: '',
};

function ACCOUNT_RESET_PASSWORD(nextState, { resetPasswordStatus }) {
  nextState.resetPasswordStatus = resetPasswordStatus;
}
function ACCOUNT_RESET_PASSWORD_CHECK_TOKEN(nextState, { checkTokenPasswordResetStatus }) {
  nextState.checkTokenPasswordResetStatus = checkTokenPasswordResetStatus;
}

function ACCOUNT_CHANGE_PASSWORD(nextState, { changePasswordStatus }) {
  nextState.changePasswordStatus = changePasswordStatus;
}

export default createReducer(initialState, {
  ACCOUNT_RESET_PASSWORD,
  ACCOUNT_RESET_PASSWORD_CHECK_TOKEN,
  ACCOUNT_CHANGE_PASSWORD,
});
