import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getExistingProblemsAction } from '../../../models/actions/existingProblems';
import { getCurrentProblems, getIsProblemsLoading } from '../../../models/selectors/existingProblems';
import ProblemsHeader from './ProblemHeader';
import FilterTagsMulty from '../../Common/FilterTagsMulty';
import { setFilterTagsAction } from '../../../models/actions/filterTags';
import { getSelectedFilterTags } from '../../../models/selectors/filterTags';
import { FilterIcon } from '../../../assets/images/images';
import moment from 'moment';
import { config } from '../../../models/api/apiConfig';

import { CommentBubble } from '../../../assets/images/images';
import './ExistingProblems.scss';

class ExistingProblems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterTagsVisible: false,
    };
  }

  updateFilterVisibility() {
    const { isFilterTagsVisible } = this.state;
    this.setState({
      isFilterTagsVisible: !isFilterTagsVisible,
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.getProblems();
    document.body.classList.add('current-problems');
  }

  componentWillUnmount() {
    document.body.classList.remove('current-problems');
  }

  renderProblemTags(tags) {
    const { setFilterTags } = this.props;

    return tags.map((currentTag) => {
      return (
        <li className="bullet-text" key={currentTag.tagId} onClick={() => setFilterTags(currentTag.tagId)}>
          <span className="link-like">
            <span className="mr-1">&middot;</span> {currentTag.name}
          </span>
        </li>
      );
    });
  }

  redirectToDetailsPage(problemId, safeTitle) {
    // this.props.history.push(`/problems/${problemId}/${safeTitle}`);
    window.open(`/problems/${problemId}/${safeTitle}`, '_blank');
  }

  renderProblem(problem) {
    const {
      problemId,
      title,
      description,
      creationDate,
      tags,
      resources,
      safeTitle,
      proposerDisplayName,
      numberOfComments,
    } = problem;

    return (
      <div className="problem-record col-md-8 col-sm-12 mb-5 p-0" key={problemId}>
        <div className="description col-8 p-0">
          <h6 className="font-weight-bold" onClick={() => this.redirectToDetailsPage(problemId, safeTitle)}>
            {title}
          </h6>
          <p className="description-text" onClick={() => this.redirectToDetailsPage(problemId, safeTitle)}>
            {description.length > 140 ? description.substring(0, 140 - 3) + '...' : description}
          </p>
          <div className="problem-data">
            <ul>
              <li>{moment(creationDate).format('MMM DD')}</li>
              <li className="bullet-text">
                <span className="mr-1">&middot; </span>
                {proposerDisplayName}
              </li>
              {this.renderProblemTags(tags)}
              {numberOfComments !== 0 && (
                <li className="bullet-text link-like">
                  <span className="mr-1">&middot; </span>
                  <CommentBubble /> {numberOfComments} comentarii
                </li>
              )}
            </ul>
          </div>
        </div>
        {resources.length ? (
          <div className="img-wrapper-existing-problem col-4 align-self-center center">
            <img src={`${config.apiUrl}${resources[0].url}`} className="img-fluid" />
          </div>
        ) : (
          ''
        )}
        <div className="clear"></div>
      </div>
    );
  }

  renderProblems() {
    const { currentProblems } = this.props;
    return (
      (currentProblems.length &&
        currentProblems.map((problem) => {
          return this.renderProblem(problem);
        })) || <p className="p-message">Nu au fost găsite probleme după criteriile specificate</p>
    );
  }

  render() {
    const { isProblemsLoading, setFilterTags, selectedTags } = this.props;
    const { isFilterTagsVisible } = this.state;
    const filteringActive = isFilterTagsVisible ? 'active' : '';

    console.log(selectedTags);
    return (
      <div className="existing-problems container mt-5">
        <div className="force-push-menu-space"></div>
        <div className="row">
          <ProblemsHeader />
          <div className="problems-wrapper col-md-11 col-sm-12 mt-3">
            <div className="filtering col-md-8">
              <div className="btn-wrapper">
                <button type="button" className="toggle-tags" onClick={() => this.updateFilterVisibility()}>
                  <FilterIcon />
                  <span>Filtrează problemele după domeniu</span>
                </button>
              </div>
              <div className={`filtering-tags ${filteringActive}`}>
                <FilterTagsMulty onChange={setFilterTags} selectedTagsArray={selectedTags} />
              </div>
            </div>
            {!isProblemsLoading && this.renderProblems()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isProblemsLoading: getIsProblemsLoading(state),
    currentProblems: getCurrentProblems(state),
    selectedTags: getSelectedFilterTags(state),
  }),
  (dispatch) => ({
    getProblems: () => dispatch(getExistingProblemsAction()),
    setFilterTags: (tagID) => dispatch(setFilterTagsAction(tagID)),
  }),
)(ExistingProblems);
