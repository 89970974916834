import { api } from '../api/api';
import Cookies from 'js-cookie';
import { getIsEmailIsAvailable } from '../selectors/register';
import { isUserLoggedIn } from '../../components/User/UserUtils';

export function onUserLoginAction(userData) {
  return function (dispatch) {
    const { userEmail, userPassword } = userData;

    if (userEmail === '' || userPassword === '') {
      return dispatch({ type: 'AUTHENTICATION_FAIL' });
    }

    const data = { email: userEmail, password: userPassword };
    dispatch({ type: 'SET_USER_STATUS', userStatus: 'loading' });

    api
      .post('login', data)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((userData) => {
        const { token } = userData;

        Cookies.set('SESSION_TOKEN', token, {
          expires: Date.now() + 1000 * 60 * 60 * 24,
        });

        dispatch({ type: 'SET_USER_STATUS', userStatus: 'authorized' });
        dispatch({ type: 'SET_USER_DATA', userData });
      })
      .catch((error) => {
        return dispatch({ type: 'SET_USER_STATUS', userStatus: 'authfail' });
      });
  };
}

export function onUserGoogleLoginAction(tokenId) {
  return function (dispatch) {
    dispatch({ type: 'SET_USER_STATUS', userStatus: 'loading' });

    api
      .postGoogleLogin('googleLogin', tokenId)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((userData) => {
        const { token } = userData;

        localStorage.setItem('scUserLoginType', 'googleLogin');

        Cookies.set('SESSION_TOKEN', token, {
          expires: Date.now() + 1000 * 60 * 60 * 24,
        });

        dispatch({ type: 'SET_USER_STATUS', userStatus: 'authorized' });
        dispatch({ type: 'SET_USER_DATA', userData });
      })
      .catch((error) => {
        return dispatch({ type: 'SET_USER_STATUS', userStatus: 'googleAuthFail' });
      });
  };
}

export function onUserFacebookLoginAction(accessToken) {
  return function (dispatch) {
    dispatch({ type: 'SET_USER_STATUS', userStatus: 'loading' });

    api
      .post('facebookLogin', { accessToken })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((userData) => {
        const { token } = userData;

        localStorage.setItem('scUserLoginType', 'facebookLogin');

        Cookies.set('SESSION_TOKEN', token, {
          expires: Date.now() + 1000 * 60 * 60 * 24,
        });

        dispatch({ type: 'SET_USER_STATUS', userStatus: 'authorized' });
        dispatch({ type: 'SET_USER_DATA', userData });
      })
      .catch((error) => {
        return dispatch({ type: 'SET_USER_STATUS', userStatus: 'facebookAuthFail' });
      });
  };
}

export function setUserStatusAction() {
  return function (dispatch, getState) {
    const isUserAuthorized = isUserLoggedIn();
    if (!isUserAuthorized) {
      return dispatch({ type: 'SET_USER_STATUS', userStatus: 'unauthenticathed' });
    }
  };
}

export function logoutUserAction() {
  return function (dispatch) {
    Cookies.set('SESSION_TOKEN', '');
    dispatch({ type: 'SET_USER_DATA', userData: null });
    dispatch({ type: 'SET_USER_STATUS', userStatus: 'unauthorized' });
  };
}

export function onUserRegisterAtion(userData) {
  return function (dispatch, getState) {
    dispatch({ type: 'ACCOUNT_REGISTER_REQUEST' });
    dispatch(checkEmailExistenceAction(userData.email));
    const isEmailAvailable = getIsEmailIsAvailable(getState());

    if (!isEmailAvailable) {
      return;
    }

    api
      .post('account/create', { ...userData })
      .then((response) => {
        if (response.ok) {
          return dispatch({ type: 'ACCOUNT_REGISTER_SUCCESS', registerStatus: 'success' });
        }

        return response;
      })
      .catch((err) => {
        return dispatch({ type: 'ACCOUNT_REGISTER_FAILURE', registerStatus: 'emailTaken' });
      });
  };
}

export function resetUserRegisterStatusAction() {
  return function (dispatch) {
    setTimeout(function () {
      dispatch({ type: 'ACCOUNT_REGISTER_SUCCESS', registerStatus: '' });
    }, 60000);
  };
}

export function resetUserRegisterStatusActionImediately() {
  return function (dispatch) {
    dispatch({ type: 'ACCOUNT_REGISTER_SUCCESS', registerStatus: '' });
  };
}

export function checkEmailExistenceAction(email) {
  return function (dispatch) {
    api
      .post('account/checkemailavailability', { email })
      .then((response) => response.json())
      .then((result) => {
        dispatch({ type: 'CHECK_EMAIL_EXISTENCE', isEmailAvailable: result.emailIsAvailable });
      });
  };
}

export function accountConfirmAction(accountID) {
  return function (dispatch) {
    return api.post('account/validate', { token: accountID }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'ACCOUNT_ACTIVATION_SUCCESS', validateStatus: 'success' });
      }
      dispatch({ type: 'ACCOUNT_ACTIVATION_FAILURE', validateStatus: 'error' });
      return response;
    });
  };
}

export function onGoogleLoginAction(userData) {
  return function (dispatch) {
    // return console.log('soon');
    // api.post('auth/google_login', userData).then((response) => {
    //   debugger;
    //   console.log(response);
    // });
  };
}

export function resetPasswordAction(email) {
  return function (dispatch) {
    // return console.log(email);
    api.post('account/passwordresetwithtoken/createtoken', { email }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'ACCOUNT_RESET_PASSWORD', resetPasswordStatus: 'success' });
      } else {
        dispatch({ type: 'ACCOUNT_RESET_PASSWORD', resetPasswordStatus: 'error' });
      }
    });
  };
}

export function checkResetPasswordTokenAction(token) {
  return function (dispatch) {
    // return console.log(email);
    api.post('account/passwordresetwithtoken/checktoken', { token }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'ACCOUNT_RESET_PASSWORD_CHECK_TOKEN', checkTokenPasswordResetStatus: 'success' });
      } else {
        dispatch({ type: 'ACCOUNT_RESET_PASSWORD_CHECK_TOKEN', checkTokenPasswordResetStatus: 'error' });
      }
    });
  };
}

export function changePasswordAction(token, password) {
  return function (dispatch) {
    // return console.log(email);
    api.post('account/passwordresetwithtoken/changepassword', { token, password }).then((response) => {
      if (response.status === 200) {
        dispatch({ type: 'ACCOUNT_CHANGE_PASSWORD', changePasswordStatus: 'success' });
      } else {
        dispatch({ type: 'ACCOUNT_CHANGE_PASSWORD', changePasswordStatus: 'error' });
      }
    });
  };
}
