import React, { useEffect } from 'react';
import './PersonalData.scss';

const PersonalData = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="personal-data">
      <h1 className="center">
        Politica de prelucrare a datelor cu caracter personal în cadrul proiectului Soluții colaborative pentru
        comunitate
      </h1>
      <h2>Cum sunt definite datele personale?</h2>
      <p>
        Datele personale reprezintă orice informație referitoare la o persoană fizică identificată sau identificabilă,
        iar o persoană identificabilă este acea persoană care poate fi identificată direct sau indirect, în special prin
        referire la un număr de identificare sau la unul sau mai mulți factori specifici fizic, psihologic, mental, de
        identitate economică, culturală sau socială.
      </p>
      <div>
        Informațiile cu caracter personal, necesare bunei funcționări a platformei se împart în patru categorii:
        <ul>
          <li>
            <strong>Informații necesare pentru identificarea persoanei:</strong>
            <p>
              Aceste informații fac referire la nume și prenume, adresă de e-mail, cartier și sunt cerute în momentul în
              care utilizatorul dorește să se înscrie pe platformă pentru a înscrie o problemă, pentru vizualiza
              celelalte probleme înscrise, pentru a vota sau lăsa un comentariu la probleme și, ulterior, la soluțiile
              dezvoltate. La nevoie, administratorii platformei vor contacta utilizatorii în vederea înștiințării cu
              privire la problemele depuse de către aceștia, motiv pentru care se stochează următoarele date: nume,
              prenume, adresă email și telefon.
            </p>
            <p>
              Înscrierea problemei presupune acceptul pentru afișarea numelui asociat proiectului. Pentru a asigura
              conformitatea cu reglementările de protecție a datelor cu caracter personal, numele deponenților vor fi
              șterse după o perioadă de 12 luni de la înscrierea problemei. În cazul în care utilizatorii doresc ca
              numele să le fie șterse după încheierea procesului și înainte de cele 12 luni, vor trimite un e-mail cu
              solicitarea, care va conține și denumirea problemelor înscrise, la adresa contact@asociatiacivica.ro.
            </p>
          </li>
          <li>
            <strong> Informații necesare pentru asigurarea îndeplinirii criteriilor de participare:</strong>
            <p>
              Platforma solutiicolaborative.ro este destinată persoanelor care au împlinit vârsta de cel puțin 18 ani şi
              care locuiesc, muncesc sau studiază în Iași. Sunt colectate numele și prenumele, adresă de e-mail,
              cartier.
            </p>
            <p>
              Platforma solutiicolaborative.ro colectează date despre locația utilizatorilor doar în momentul
              înregistrării în platformă pentru a înscrie o problemă. Prin înscrierea proiectelor pe
              solutiicolaborative.ro, utilizatorii își asumă că au domiciliul sau reședința în aria municipiului Iași.
            </p>
          </li>
          <li>
            <strong> Informații necesare pentru a limita fraudele în etapa de vot:</strong>
            <p>
              Pentru a evita atât crearea de conturi multiple, cât și votul multiplu, platforma stochează adresa de
              email și adresa IP atât în momentul creării contului, cât și în momentul oricărei autentificări pe
              platformă. Conturile se validează printr-o dublă autentificare, prin confirmarea adresei de email și a
              numărului de telefon.
            </p>
          </li>
          <p>
            Orice încercare de a utiliza datele personale ale altui utilizator este strict interzisă, fiind considerată
            o tentativă de fraudă și va fi sancționată prin lege contravențional sau penal, după caz.
          </p>
        </ul>
      </div>
      <h2>Drepturile ale utilizatorilor platformei</h2>
      <h3>
        Dreptul de a fi informat cu privire la procedurile noastre și utilizarea datelor tale cu caracter personal
      </h3>
      <p>
        Colectăm date cu caracter personal care fac referire la: nume și prenume, adresa de e-mail, număr de telefon,
        locația logării pe platformă.
      </p>
      <p>
        Datele preluate prin intermediul platformei vor fi utilizate strict în scopul derulării proceselor de consultare
        și informare din cadrul proiectului, conform Regulamentului procesului publicat pe platforma
        solutiicolaborative.ro.
      </p>
      <p>
        În baza consimțământului tău expres și explicit, prelucrăm datele tale cu caracter personal în scopul de a te
        înștiința – prin e-mail sau telefon, pe adresa sau numărul furnizate la crearea contului – de începerea
        perioadei de înscriere a problemelor, de începerea perioadelor de vot, de lista lungă și lista scurtă a
        problemelor prioritizate, de supunerea la consultare publică a soluțiilor dezvoltate, precum și pentru orice
        notificări esențiale pentru informarea comunității în legătură cu derularea proiectului Soluții colaborative
        pentru comunitate.
      </p>
      <p>
        Platforma asigură păstrarea confidențialității datelor cu caracter personal furnizate pentru accesul la
        platformă și pentru utilizarea acesteia. Această obligație nu se aplică în cazul oricărei informații dezvăluite
        de utilizatori către terți.
      </p>
      <p>
        Colectarea datelor cu caracter personal prin intermediul platformei se face doar cu acordul utilizatorilor,
        precum și în conformitate cu legislația în vigoare și cu Regulamentul European (UE) 2016/679 privind protecția
        persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a
        acestor date.
      </p>
      <p>
        În situația în care există erori cu privire la datele care îți sunt prelucrate, ai posibilitatea de a le corecta
        sau completa. Această acțiune se poate realiza după conectarea în platformă, în secțiunea SETĂRI CONT.
      </p>
      <h3>Dreptul de a solicita transferul datelor</h3>
      <p>
        Poți solicita transferul datelor tale către un alt operator dacă prelucrarea are la bază consimțământul tău și
        prelucrarea este una automată.
      </p>
      <h3>Dreptul la securitatea datelor cu caracter personal</h3>
      <p>
        Există diverse măsuri tehnice şi organizatorice pentru a asigura securitatea datelor personale ale
        utilizatorilor împotriva manipulării, pierderii, distrugerii, accesării de către persoane neautorizate, în mod
        accidental sau/şi intenţionat. Întrebări privind prelucrarea datelor utilizatorilor cu caracter personal pot fi
        trimise la adresa contact@asociatiacivica.ro. Citiți mai multe și în Politica de cookie.
      </p>
      <h3>Dreptul de ștergere a datelor</h3>
      <p>
        Contul tău poate fi șters în orice moment de pe platformă. Cu toate acestea, datele personale furnizate vor mai
        exista în sistem, în copiile de rezervă ale bazei de date. Durata stocării datelor cu caracter personal este de
        45 zile de la data ștergerii contului. Această acțiune se poate realiza după conectarea în platformă, în
        secțiunea SETĂRI CONT.
      </p>
      <h2>Amendamente la Politica de prelucrare a datelor cu caracter personal</h2>
      <p>
        Orice măsură de protecție a persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal va
        fi publicată și afișată pe această pagină cu indicarea ultimei date actualizate.
      </p>
      <p>Prezenta Politică de utilizare cookies se aplică din data de 28.10.2020</p>
    </div>
  );
};

export default PersonalData;
