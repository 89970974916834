import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import patterns from '../../../assets/images/patterns';

import {
  getProblemDetailsLoggedIn,
  getProblemDetailsNotLoggedIn,
  followAction,
  deleteFollowAction,
  getCommentsForProblemDetails,
  likeCommentsForProblemDetails,
  deleteLikeCommentsForProblemDetails,
  postCommentsForProblemDetails,
  editCommentsForProblemDetails,
  deleteCommentsForProblemDetails,
} from '../../../models/actions/problemDetails';
import { config } from '../../../models/api/apiConfig';
import { renderTags } from '../../../utils/renderTags';
import { isUserLoggedIn } from '../../User/UserUtils';
import ModalDialog from '../../Common/ModalDialog';
import { CommentsIcons } from '../../../assets/images/images';

import './ProblemDetails.scss';
import { FollowStarEmpty, FollowStartFull, EnlargeImage, CommentBubble } from '../../../assets/images/images';

class ProblemDetails extends Component {
  constructor() {
    super();
    this.state = {
      showPhotoModal: false,
      replyTextAreaArray: [],
      editCommentArray: [],
      textAreaCommentText: '',
      principalTextAreaCommentText: '',
      commentText: '',
      showFirstTimeCommentModal: false,
      acceptedCommentsRules: false,
    };
  }
  componentDidMount() {
    const isUserAuthorized = isUserLoggedIn();

    window.scrollTo(0, 0);
    const problemId = this.getIdFromUrl();
    if (isUserAuthorized) {
      this.props.getProblemDetailsLoggedIn(problemId);
    } else {
      this.props.getProblemDetailsNotLoggedIn(problemId);
    }
    this.props.getCommentsForProblemDetails(problemId);
  }

  componentDidUpdate(nextProps) {
    const problemId = this.getIdFromUrl();
    const isUserAuthorized = isUserLoggedIn();

    if (this.props.followStatusSent !== nextProps.followStatusSent && isUserAuthorized) {
      this.props.getProblemDetailsLoggedIn(problemId);
    } else if (this.props.followStatusSent !== nextProps.followStatusSent && !isUserAuthorized) {
      this.props.getProblemDetailsNotLoggedIn(problemId);
    }

    if (this.props.followStatusDeleteSent !== nextProps.followStatusDeleteSent && isUserAuthorized) {
      this.props.getProblemDetailsLoggedIn(problemId);
    } else if (this.props.followStatusDeleteSent !== nextProps.followStatusDeleteSent && !isUserAuthorized) {
      this.props.getProblemDetailsNotLoggedIn(problemId);
    }

    if (this.props.likeSuccess !== nextProps.likeSuccess) {
      this.props.getCommentsForProblemDetails(problemId);
    }

    if (this.props.postCommentSuccess !== nextProps.postCommentSuccess) {
      this.setState({
        textAreaCommentText: '',
        principalTextAreaCommentText: '',
        replyTextAreaArray: [],
      });
      this.props.getCommentsForProblemDetails(problemId);
    }

    if (this.props.editCommentSuccess !== nextProps.editCommentSuccess) {
      this.setState({
        editCommentArray: [],
      });
      this.props.getCommentsForProblemDetails(problemId);
    }

    if (this.props.deleteCommentSuccess !== nextProps.deleteCommentSuccess) {
      this.props.getCommentsForProblemDetails(problemId);
    }
  }

  getIdFromUrl() {
    const url = window.location.href.match('problems/(.*)/');
    return url[1];
  }

  onFollowClick() {
    const { problemDetails } = this.props;
    const problemId = problemDetails.problemId;

    if (problemDetails.isFollowed) {
      this.props.deleteFollowAction(problemId);
    } else {
      this.props.followAction(problemId);
    }
  }

  showPhotoModal() {
    this.setState({
      showPhotoModal: true,
    });
  }

  hidePhotoModal() {
    this.setState({
      showPhotoModal: false,
    });
  }

  showCommentSection(id, currentUserOwnsThisComment) {
    let { replyTextAreaArray } = this.state;
    const { problemDetails } = this.props;
    const index = replyTextAreaArray.indexOf(id);

    if (!problemDetails.account.hasCommented) {
      this.setState({
        showFirstTimeCommentModal: true,
      });
    }

    if (!currentUserOwnsThisComment) {
      if (index > -1) {
        replyTextAreaArray.splice(index, 1);
      } else {
        replyTextAreaArray = [];
        replyTextAreaArray.push(id);
        this.setState({
          textAreaCommentText: '',
        });
      }
    }

    this.setState({
      replyTextAreaArray,
      editCommentArray: [],
    });
  }

  likeComment(commentId, currentUserHasLikedComment) {
    if (!currentUserHasLikedComment) {
      this.props.likeCommentsForProblemDetails(commentId);
    } else {
      this.props.deleteLikeCommentsForProblemDetails(commentId);
    }
  }

  onCommentTextAreaChange(e, commentAreaType) {
    if (commentAreaType === 'principal') {
      this.setState({
        principalTextAreaCommentText: e.target.value,
      });
    } else {
      this.setState({
        textAreaCommentText: e.target.value,
      });
    }
  }

  addCommentSecundar(parentCommentId) {
    const { problemDetails } = this.props;
    const { textAreaCommentText, acceptedCommentsRules } = this.state;
    const problemId = problemDetails.problemId;
    if (!problemDetails.account.hasCommented && !acceptedCommentsRules) {
      this.setState({ showFirstTimeCommentModal: true });
    } else {
      this.props.postCommentsForProblemDetails(problemId, parentCommentId, textAreaCommentText);
    }
  }

  addCommentPrincipal(parentCommentId) {
    const { problemDetails } = this.props;
    const { principalTextAreaCommentText, acceptedCommentsRules } = this.state;
    const problemId = problemDetails.problemId;

    if (!problemDetails.account.hasCommented && !acceptedCommentsRules) {
      this.setState({ showFirstTimeCommentModal: true });
    } else {
      this.props.postCommentsForProblemDetails(problemId, parentCommentId, principalTextAreaCommentText);
    }
  }

  deleteComment(commentId) {
    this.props.deleteCommentsForProblemDetails(commentId);
  }

  editComment(commentId, commentText) {
    let { editCommentArray } = this.state;
    const index = editCommentArray.indexOf(commentId);

    if (index > -1) {
      editCommentArray.splice(index, 1);
    } else {
      editCommentArray = [];
      editCommentArray.push(commentId);
      this.setState({
        textAreaCommentText: '',
      });
    }

    this.setState({
      replyTextAreaArray: [],
      editCommentArray,
      commentText,
    });
  }

  editCommentSubmit(commentId) {
    const { commentText } = this.state;
    this.props.editCommentsForProblemDetails(commentId, commentText);
  }

  renderComments(comments) {
    const { replyTextAreaArray, textAreaCommentText, editCommentArray, commentText } = this.state;
    const isUserAuthorized = isUserLoggedIn();

    return comments.map((comment) => (
      <li key={comment.commentId} className="mb-4">
        <h5 className="font-s-16 font-weight-bold">{comment.authorName}</h5>
        <p>{comment.text}</p>
        {!comment.currentUserOwnsThisComment && isUserAuthorized && (
          <>
            <span
              className={`${comment.numberOfLikes === 0 && 'mr-2'} font-s-16 comment-action ${
                comment.currentUserHasLikedComment && 'font-weight-bold'
              }`}
              onClick={() => this.likeComment(comment.commentId, comment.currentUserHasLikedComment)}
            >
              Like
            </span>
            {comment.numberOfLikes !== 0 && <span className="ml-1 mr-2 font-s-16">{comment.numberOfLikes}</span>}
            <span
              className="mr-2 font-s-16 comment-action"
              onClick={() => this.showCommentSection(comment.commentId, comment.currentUserOwnsThisComment)}
            >
              Reply
            </span>
          </>
        )}
        {comment.currentUserOwnsThisComment && isUserAuthorized && (
          <>
            <span
              className={`${comment.numberOfLikes === 0 && 'mr-2'} font-s-16 comment-action ${
                comment.currentUserHasLikedComment && 'font-weight-bold'
              }`}
              onClick={() => this.deleteComment(comment.commentId)}
            >
              Șterge
            </span>
            <span
              className="ml-2 font-s-16 comment-action"
              onClick={() => this.editComment(comment.commentId, comment.text)}
            >
              Editează
            </span>
          </>
        )}
        <span className="mr-2 font-s-16 comment-date">
          {moment.utc(new Date()).diff(comment.creationDate, 'minutes') - moment().utcOffset() < 60
            ? `${moment.utc(new Date()).diff(comment.creationDate, 'minutes') - moment().utcOffset()} min`
            : moment(new Date()).diff(comment.creationDate, 'hours') < 24
            ? `${moment(new Date()).diff(comment.creationDate, 'hours')}h`
            : moment(comment.creationDate).format('DD MMM YYYY')}
        </span>
        {editCommentArray.includes(comment.commentId) && (
          <>
            <textarea
              maxLength="2500"
              placeholder="Adaugă un comentariu..."
              className="comment-section col-12 font-s-12"
              value={commentText}
              onChange={(e) => this.setState({ commentText: e.target.value })}
            ></textarea>
            <button className="mt-2" onClick={() => this.editCommentSubmit(comment.commentId)}>
              Editează
            </button>
          </>
        )}
        {comment.children &&
          comment.children.map((children) => (
            <div className="ml-5 my-3" key={children.commentId}>
              <h5 className="font-s-16 font-weight-bold">{children.authorName}</h5>
              <p>{children.text}</p>
              {!children.currentUserOwnsThisComment && isUserAuthorized && (
                <>
                  <span
                    className={`${children.numberOfLikes === 0 && 'mr-2'} && font-s-16 comment-action ${
                      children.currentUserHasLikedComment && 'font-weight-bold'
                    }`}
                    onClick={() => this.likeComment(children.commentId, children.currentUserHasLikedComment)}
                  >
                    Like
                  </span>
                  {children.numberOfLikes !== 0 && (
                    <span className="ml-1 mr-2 font-s-16">{children.numberOfLikes}</span>
                  )}
                  <span
                    className="mr-2 font-s-16 comment-action"
                    onClick={() => this.showCommentSection(children.commentId, children.currentUserOwnsThisComment)}
                  >
                    Reply
                  </span>
                </>
              )}
              {children.currentUserOwnsThisComment && (
                <>
                  <span
                    className={`${comment.numberOfLikes === 0 && 'mr-2'} font-s-16 comment-action ${
                      children.currentUserHasLikedComment && 'font-weight-bold'
                    }`}
                    onClick={() => this.deleteComment(children.commentId)}
                  >
                    Șterge
                  </span>
                  <span
                    className="mr-2 font-s-16 comment-action"
                    onClick={() => this.editComment(children.commentId, children.text)}
                  >
                    Editează
                  </span>
                </>
              )}
              <span className="mr-2 font-s-16 comment-date">
                {moment.utc(new Date()).diff(children.creationDate, 'minutes') - moment().utcOffset() < 60
                  ? `${moment.utc(new Date()).diff(children.creationDate, 'minutes') - moment().utcOffset()} min`
                  : moment(new Date()).diff(children.creationDate, 'hours') < 24
                  ? `${moment(new Date()).diff(children.creationDate, 'hours')}h`
                  : moment(children.creationDate).format('DD MMM YYYY')}
              </span>
              {editCommentArray.includes(children.commentId) && (
                <>
                  <textarea
                    maxLength="2500"
                    placeholder="Adaugă un comentariu..."
                    className="comment-section col-12 font-s-12"
                    value={commentText}
                    onChange={(e) => this.setState({ commentText: e.target.value })}
                  ></textarea>
                  <button className="mt-2" onClick={() => this.editCommentSubmit(children.commentId)}>
                    Editează
                  </button>
                </>
              )}
              <textarea
                maxLength="2500"
                placeholder="Adaugă un comentariu..."
                className={`comment-section col-12 font-s-12 ${
                  replyTextAreaArray.includes(children.commentId) ? 'show-comment-section' : 'hide-comment-section'
                }`}
                value={textAreaCommentText}
                onChange={(e) => this.onCommentTextAreaChange(e, '')}
              ></textarea>
              {replyTextAreaArray.includes(children.commentId) && textAreaCommentText.length == 2500 && (
                <span className="error-text p-0 float-left comment-error">
                  Comentariile pot avea maximum 2500 de caractere
                </span>
              )}
              <button
                className={`mt-2 ${
                  replyTextAreaArray.includes(children.commentId) ? 'show-comment-section' : 'hide-comment-section'
                }`}
                onClick={() => this.addCommentSecundar(children.commentId)}
              >
                Adaugă
              </button>
              {children.children &&
                children.children.map((children) => (
                  <div className="ml-5 my-3" key={children.commentId}>
                    <h5 className="font-s-16 font-weight-bold">{children.authorName}</h5>
                    <p>{children.text}</p>
                    {!children.currentUserOwnsThisComment && isUserAuthorized && (
                      <>
                        <span
                          className={`${children.numberOfLikes === 0 && 'mr-2'} && font-s-16 comment-action ${
                            children.currentUserHasLikedComment && 'font-weight-bold'
                          }`}
                          onClick={() => this.likeComment(children.commentId, children.currentUserHasLikedComment)}
                        >
                          Like
                        </span>
                        {children.numberOfLikes !== 0 && (
                          <span className="ml-1 mr-2 font-s-16">{children.numberOfLikes}</span>
                        )}
                      </>
                    )}
                    {children.currentUserOwnsThisComment && (
                      <>
                        <span
                          className={`${comment.numberOfLikes === 0 && 'mr-2'} font-s-16 comment-action ${
                            children.currentUserHasLikedComment && 'font-weight-bold'
                          }`}
                          onClick={() => this.deleteComment(children.commentId)}
                        >
                          Șterge
                        </span>
                        <span
                          className="mr-2 font-s-16 comment-action"
                          onClick={() => this.editComment(children.commentId, children.text)}
                        >
                          Editează
                        </span>
                      </>
                    )}
                    <span className="mr-2 font-s-16 comment-date">
                      {moment.utc(new Date()).diff(children.creationDate, 'minutes') - moment().utcOffset() < 60
                        ? `${moment.utc(new Date()).diff(children.creationDate, 'minutes') - moment().utcOffset()} min`
                        : moment(new Date()).diff(children.creationDate, 'hours') < 24
                        ? `${moment(new Date()).diff(children.creationDate, 'hours')}h`
                        : moment(children.creationDate).format('DD MMM YYYY')}
                    </span>
                    {editCommentArray.includes(children.commentId) && (
                      <>
                        <textarea
                          maxLength="2500"
                          placeholder="Adaugă un comentariu..."
                          className="comment-section col-12 font-s-12"
                          value={commentText}
                          onChange={(e) => this.setState({ commentText: e.target.value })}
                        ></textarea>
                        <button className="mt-2" onClick={() => this.editCommentSubmit(children.commentId)}>
                          Editează
                        </button>
                      </>
                    )}
                    <textarea
                      maxLength="2500"
                      placeholder="Adaugă un comentariu..."
                      className={`comment-section col-12 font-s-12 ${
                        replyTextAreaArray.includes(children.commentId)
                          ? 'show-comment-section'
                          : 'hide-comment-section'
                      }`}
                      value={textAreaCommentText}
                      onChange={(e) => this.onCommentTextAreaChange(e, '')}
                    ></textarea>
                    {replyTextAreaArray.includes(children.commentId) && textAreaCommentText.length == 2500 && (
                      <span className="error-text p-0 float-left comment-error">
                        Comentariile pot avea maximum 2500 de caractere
                      </span>
                    )}
                    <button
                      className={`mt-2 ${
                        replyTextAreaArray.includes(children.commentId)
                          ? 'show-comment-section'
                          : 'hide-comment-section'
                      }`}
                      onClick={() => this.addCommentSecundar(children.commentId)}
                    >
                      Adaugă
                    </button>
                  </div>
                ))}
            </div>
          ))}
        <textarea
          maxLength="2500"
          placeholder="Adaugă un comentariu..."
          className={`comment-section col-12 font-s-12 ${
            replyTextAreaArray.includes(comment.commentId) ? 'show-comment-section' : 'hide-comment-section'
          }`}
          value={textAreaCommentText}
          onChange={(e) => this.onCommentTextAreaChange(e, '')}
        ></textarea>
        {replyTextAreaArray.includes(comment.commentId) && textAreaCommentText.length == 2500 && (
          <span className="error-text p-0 float-left comment-error">
            Comentariile pot avea maximum 2500 de caractere
          </span>
        )}
        <button
          className={`mt-2 ${
            replyTextAreaArray.includes(comment.commentId) ? 'show-comment-section' : 'hide-comment-section'
          }`}
          onClick={() => this.addCommentSecundar(comment.commentId)}
        >
          Adaugă
        </button>
      </li>
    ));
  }

  renderNotLoggedInCommentsSection() {
    return (
      <div className="col-lg-12 comments-not-logged-in mt-3 mb-3">
        <div className="comments-icon">
          <CommentsIcons />
        </div>
        <p className="mb-4">
          Ai câteva gânduri despre această problemă? Loghează-te și scrie-le aici. Toate părerile constructive sunt
          binevenite!
        </p>
        <div className="center comments-not-logged-in-buttons-wrapper">
          <span className="comments-login-button mr-3 font-s-16 font-s-sm-12">
            <NavLink to="/login">Loghează-te</NavLink>
          </span>
          <span className="comments-create-account font-s-16 font-s-sm-12">
            <NavLink to="/create-account">Creează un cont</NavLink>
          </span>
        </div>
      </div>
    );
  }

  fistTimeCommentOrReplyHandle() {
    this.setState({
      showFirstTimeCommentModal: false,
      acceptedCommentsRules: true,
    });
  }

  hideFirstTimeCommentModal() {
    this.setState({
      showFirstTimeCommentModal: false,
    });
  }

  renderFirstCommentModal() {
    const { showFirstTimeCommentModal } = this.state;
    const { problemDetails } = this.props;

    const modalProps = {
      isOpen: showFirstTimeCommentModal,
      modalTitle: 'Ține cont de aceste indicații când participi la discuții:',
      children: `
      `,
      hideAbort: true,
      closeText: 'Sunt de acord',
      customStyle: {
        titleStyle: {
          fontSize: '18px',
        },
      },
      onAbort: () => this.hideFirstTimeCommentModal(),
      onConfirm: () => this.fistTimeCommentOrReplyHandle(),
    };

    return (
      <ModalDialog {...modalProps}>
        <ul className="comments-modal-list">
          <li>te adresezi cu respect celorlalți utilizatori</li>
          <li>nu instigi la discriminare, ură sau violență</li>
          <li>respecți subiectul problemei descrise</li>
          <li>
            Găsești aici{' '}
            <a href={'/terms-and-conditions'} target="_blank">
              mai multe informații despre
            </a>{' '}
            responsabilitățile utilizatorului și despre consecințele pentru neasumare.
          </li>
        </ul>
      </ModalDialog>
    );
  }

  countComments() {
    const { comments } = this.props;
    let commentsCounter = comments.comments.length;

    comments.comments.forEach((comment) => {
      if (comment.children.length !== 0) commentsCounter += comment.children.length;
    });

    return commentsCounter;
  }

  render() {
    const { problemDetails, error, comments } = this.props;
    const isUserAuthorized = isUserLoggedIn();
    const { showPhotoModal, principalTextAreaCommentText } = this.state;
    const shareUrl = `https://solutiicolaborative.ro${window.location.pathname}`;

    return (
      <div className="problem-detail container mt-5 pt-md-5 pt-0">
        {this.renderFirstCommentModal()}
        <div className="col-12 register-problem-back-button-wrapper p-0 mb-4 font-s-16" style={{ zIndex: '999' }}>
          <NavLink to={'/existing-problems'}>&#60; Înapoi</NavLink>
        </div>
        {!error ? (
          <div className="row">
            {problemDetails &&
              problemDetails.tags &&
              Boolean(problemDetails.tags.length) &&
              problemDetails.tags.map((tag, i) => {
                const patternID = `pattern${tag.tagId}`;
                return <img src={patterns[patternID]} alt="" className="pattern-image" key={i} />;
              })}
            <div className="col-md-7 col-sm-12">
              <h2 className="font-weight-bold mb-1 problem-title text-left">{problemDetails.title}</h2>
              {problemDetails &&
                problemDetails.tags &&
                Boolean(problemDetails.tags.length) &&
                problemDetails.tags.map((tag, i) => (
                  <p key={i} className={`tag ${renderTags(tag.tagId)}`}>
                    {tag.name}
                  </p>
                ))}
              <br />
              {problemDetails.description && (
                <div>
                  <h5 className="font-weight-bold">Descriere problemă</h5>
                  <br />
                  <p>{problemDetails.description}</p>
                </div>
              )}
              <br />
              {problemDetails && problemDetails.resources && Boolean(problemDetails.resources.length) && (
                <div>
                  <h5 className="font-weight-bold">Fotografii relevante pentru problemă</h5>
                  <br />
                  <div className="photo-wrapper">
                    <img src={`${config.apiUrl}${problemDetails.resources[0].url}`} className="img-fluid" />
                    <span className="photo-enlarge" onClick={() => this.showPhotoModal()}>
                      <EnlargeImage />
                    </span>
                  </div>
                </div>
              )}
              <br />
              <FacebookShareButton
                url={shareUrl}
                quote={
                  'Aceasta este una din problemele care mă împiedică să mă bucur de Iași așa cum mi-aș dori. Am înscris-o pe platforma proiectului Soluții colaborative pentru comunitate. Înscrieți și voi problemele de care vă loviți în Iași, ca împreună să le găsim rezolvări.'
                }
                hashtag="#soluțiicolaborative"
                className={'fb-share'}
              >
                <FacebookIcon size={36} round={true} /> Share
              </FacebookShareButton>
              <br />
              <div className="comments-wrapper">
                <h6 className="font-weight-bold mt-5 mb-4">
                  <span className="float-left">Discuții</span>
                  <span className="float-right font-weight-normal link-like">
                    <CommentBubble />{' '}
                    {comments && comments.comments && comments.comments.length && this.countComments()} comentarii
                  </span>
                </h6>
                <br />
                {isUserAuthorized && (
                  <>
                    <hr />
                    <textarea
                      maxLength="2500"
                      placeholder="Adaugă un comentariu..."
                      className="col-12 comment-section-top font-s-12"
                      value={principalTextAreaCommentText}
                      onChange={(e) => this.onCommentTextAreaChange(e, 'principal')}
                    ></textarea>
                    {principalTextAreaCommentText.length == 2500 && (
                      <span className="error-text p-0 float-left comment-error">
                        Comentariile pot avea maximum 2500 de caractere
                      </span>
                    )}
                    <button className="mb-4" onClick={() => this.addCommentPrincipal('')}>
                      Adaugă
                    </button>
                  </>
                )}
                {!isUserAuthorized && this.renderNotLoggedInCommentsSection()}
                {comments && comments.comments && Boolean(comments.comments.length) && (
                  <div className="comments px-2">
                    <ul>{this.renderComments(comments.comments)}</ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-1 col-sm-0" />
            <div className="col-md-4 col-sm-12 right-side mt-5 small-centered">
              {/* <div className="row mt-1 mb-3 follow-wrapper" onClick={() => this.onFollowClick()}>
                <div className="follow">
                  <span>{problemDetails.isFollowed ? <FollowStartFull /> : <FollowStarEmpty />}</span>
                  <p className="font-s-14 ml-3">Urmărește</p>
                </div>
              </div> */}
              <div className="row">
                <div className="project-status">
                  <div className="left-side-bubles">
                    <p className="font-s-12 color-mid-gray">
                      Propus pe {moment(problemDetails.creationDate).format('DD MMM YYYY')} de: <br />
                      <span className="color-black">{problemDetails.proposerDisplayName}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {problemDetails && problemDetails.resources && Boolean(problemDetails.resources.length) && (
              <div className={`photo-modal ${showPhotoModal && 'show-photo-popup'}`}>
                <span className="close" onClick={() => this.hidePhotoModal()}>
                  ×
                </span>
                <img src={`${config.apiUrl}${problemDetails.resources[0].url}`} className="modal-content" id="img01" />
              </div>
            )}
          </div>
        ) : (
          <div className="col-md-12 center">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">Ne pare rău. A apărut o eroare sau articolul nu a fost găsit.</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPending: state.problemDetails.isPending,
    isPendingComments: state.problemDetails.isPendingComments,
    errorComments: state.problemDetails.errorComments,
    comments: state.problemDetails.comments,
    problemDetails: state.problemDetails.problemDetails,
    error: state.problemDetails.error,
    followStatusSent: state.problemDetails.followStatusSent,
    followError: state.problemDetails.followError,
    followStatusDeleteSent: state.problemDetails.followStatusDeleteSent,
    followDeleteError: state.problemDetails.followDeleteError,
    likeSuccess: state.problemDetails.likeSuccess,
    postCommentSuccess: state.problemDetails.postCommentSuccess,
    editCommentSuccess: state.problemDetails.editCommentSuccess,
    deleteCommentSuccess: state.problemDetails.deleteCommentSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProblemDetailsLoggedIn: (problemId) => dispatch(getProblemDetailsLoggedIn(problemId)),
  getProblemDetailsNotLoggedIn: (problemId) => dispatch(getProblemDetailsNotLoggedIn(problemId)),
  followAction: (problemId) => dispatch(followAction(problemId)),
  deleteFollowAction: (problemId) => dispatch(deleteFollowAction(problemId)),
  getCommentsForProblemDetails: (problemId) => dispatch(getCommentsForProblemDetails(problemId)),
  likeCommentsForProblemDetails: (commentId) => dispatch(likeCommentsForProblemDetails(commentId)),
  deleteLikeCommentsForProblemDetails: (commentId) => dispatch(deleteLikeCommentsForProblemDetails(commentId)),
  postCommentsForProblemDetails: (problemId, parentCommentId, commentText) =>
    dispatch(postCommentsForProblemDetails(problemId, parentCommentId, commentText)),
  editCommentsForProblemDetails: (commentId, commentText) =>
    dispatch(editCommentsForProblemDetails(commentId, commentText)),
  deleteCommentsForProblemDetails: (commentId) => dispatch(deleteCommentsForProblemDetails(commentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemDetails);
