import {
  GET_PROBLEM_DETAILS_VOTE_LOADING,
  GET_PROBLEM_DETAILS_VOTE_SUCCESS,
  GET_PROBLEM_DETAILS_VOTE_ERROR,
  GET_PROBLEM_TO_VOTE_LOADING,
  GET_PROBLEM_TO_VOTE_SUCCESS,
  GET_PROBLEM_TO_VOTE_ERROR,
  POST_VOTE_PROBLEM_LOADING,
  POST_VOTE_PROBLEM_SUCCESS,
  POST_VOTE_PROBLEM_ERROR,
} from '../constants';

const initialState = {
  isPending: false,
  problemDetailsVote: {},
  error: false,
  problemsToVote: {},
  errorProblemsToVote: false,
  isPendingProblemsToVote: false,
  successfullyVoted: false,
  errorVoting: false,
  isPendingVoting: false,
};

function problemDetailsVoteReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROBLEM_DETAILS_VOTE_SUCCESS:
      return {
        ...state,
        problemDetailsVote: action.payload,
        error: false,
        isPending: false,
      };
    case GET_PROBLEM_DETAILS_VOTE_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
        problemDetailsVote: {},
      };
    case GET_PROBLEM_DETAILS_VOTE_LOADING:
      return {
        ...state,
        isPending: action.isPending,
        error: false,
        problemDetailsVote: {},
      };

    case GET_PROBLEM_TO_VOTE_SUCCESS:
      return {
        ...state,
        problemsToVote: action.payload,
        errorProblemsToVote: false,
        isPendingProblemsToVote: false,
      };
    case GET_PROBLEM_TO_VOTE_ERROR:
      return {
        ...state,
        errorProblemsToVote: action.error,
        isPendingProblemsToVote: false,
        problemsToVote: {},
      };
    case GET_PROBLEM_TO_VOTE_LOADING:
      return {
        ...state,
        isPendingProblemsToVote: action.isPending,
        errorProblemsToVote: false,
        problemsToVote: {},
      };

    case POST_VOTE_PROBLEM_SUCCESS:
      return {
        ...state,
        successfullyVoted: true,
        errorVoting: false,
        isPendingVoting: false,
      };
    case POST_VOTE_PROBLEM_ERROR:
      return {
        ...state,
        successfullyVoted: false,
        errorVoting: true,
        isPendingVoting: false,
      };
    case POST_VOTE_PROBLEM_LOADING:
      return {
        ...state,
        successfullyVoted: false,
        errorVoting: false,
        isPendingVoting: true,
      };

    default:
      return state;
  }
}

export default problemDetailsVoteReducer;
