import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accountConfirmAction } from '../../../models/actions/userActions';
import { getAccountValidationStatus } from '../../../models/selectors/register';
import { NavLink } from 'react-router-dom';

class AccountConfirm extends Component {
  componentDidMount() {
    const { location, accountConfirm } = this.props;
    const accountID = location.pathname.split('/')[3];
    accountConfirm(accountID);
  }
  render() {
    const { validateStatus } = this.props;

    return validateStatus === 'success' ? (
      <div className="validate-account container center">
        <h3>Contul tau a fost activat cu success</h3>
        <br />
        <NavLink to="/login">Inapoi la pagina de autentificare</NavLink>
      </div>
    ) : (
      <div className="validate-account container center">
        <h3>Tokenul a fost folosit sau este invalid</h3>
        <br />
        <NavLink to="/login">Inapoi la pagina de autentificare</NavLink>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    validateStatus: getAccountValidationStatus(state),
  }),
  (dispatch) => ({
    accountConfirm: (accountID) => dispatch(accountConfirmAction(accountID)),
  }),
)(AccountConfirm);
