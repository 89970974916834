import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class ModalDialog extends Component {
  render() {
    const {
      onConfirm,
      onAbort,
      isOpen,
      children,
      modalTitle,
      hideClose,
      closeText,
      customStyle,
      abortText,
      hideAbort,
    } = this.props;

    return (
      <Modal show={isOpen} onHide={onAbort}>
        <Modal.Header closeButton>
          <Modal.Title style={customStyle && customStyle.titleStyle}>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {!hideAbort && (
            <button type="button" onClick={onAbort}>
              {closeText ? closeText : 'Inchide'}
            </button>
          )}
          {!hideClose && (
            <button type="button" onClick={onConfirm}>
              {abortText ? abortText : 'Continua'}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalDialog;
