import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';

import { getIsUserAdmin, isUserLoggedIn } from '../../User/UserUtils';
import { config } from '../../../models/api/apiConfig';

import { Logo } from '../../../assets/images/images';

import './Header.scss';

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownRight, setShowDropdownRight] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const isUserAdmin = getIsUserAdmin();
  const isUserAuthorized = isUserLoggedIn();
  const loginType = localStorage.getItem('scUserLoginType');

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const showHideNavigation = () => {
    setShowMenu(!showMenu);
  };

  const googleLogoutSuccess = () => {
    localStorage.removeItem('scUserLoginType');
    setShowMenu(false);

    props.history.push('/logout');
  };

  const onClickShowDropdown = () => {
    setShowDropdownRight(false);
    setShowDropdown(!showDropdown);
  };

  const onClickShowDropdownRight = () => {
    setShowDropdown(false);
    setShowDropdownRight(!showDropdownRight);
  };

  const hideNavigation = () => {
    setShowMenu(false);
  };

  return (
    <>
      <nav className={`${scrollTop > 20 && 'grey-bg'} header navbar navbar-expand-lg navbar-light navbar-fixed-top`}>
        <span className="logo-wrapper">
          <NavLink to="/">
            <Logo />
          </NavLink>
        </span>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => showHideNavigation()}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${showMenu && 'show'}`} id="navbarNavDropdown">
          <ul className="navigation navbar-nav ml-auto">
            {isUserAdmin && (
              <li className="nav-item">
                <NavLink to="/dashboard">Administrare</NavLink>
              </li>
            )}
            <li className="nav-item active p-0 nav-item-dropdown">
              {/* <NavLink to="/about">Despre</NavLink> */}
              <div className={`dropdown ${showDropdown && 'show'}`} onClick={() => onClickShowDropdown()}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Despre
                </button>
                <div className={`dropdown-menu ${showDropdown && 'show'}`} aria-labelledby="dropdownMenuButton">
                  <NavLink to="/about-project" onClick={() => hideNavigation()}>
                    <span className="dropdown-item">Proiect</span>
                  </NavLink>
                  <NavLink to="/team">
                    <span className="dropdown-item" onClick={() => hideNavigation()}>
                      Echipă
                    </span>
                  </NavLink>
                  <NavLink to="/partners">
                    <span className="dropdown-item" onClick={() => hideNavigation()}>
                      Parteneri
                    </span>
                  </NavLink>
                  {/* <span className="dropdown-item">
                    <NavLink to="/">Parteneri</NavLink>
                  </span>
                  <span className="dropdown-item">
                    <NavLink to="/">Transparență</NavLink>
                  </span> */}
                </div>
              </div>
            </li>
            <li className="nav-item mr-2">
              <NavLink to="/existing-problems" onClick={() => hideNavigation()}>
                Problemele comunității
              </NavLink>
            </li>
            <li className="nav-item mr-2">
              <NavLink to="/vote-results" onClick={() => hideNavigation()}>
                Rezultate vot
              </NavLink>
            </li>
            <li className="nav-item mr-2">
              <a href="https://afla.solutiicolaborative.ro/">Noutăți</a>
            </li>
            {!isUserAuthorized && (
              <>
                <li className="nav-item">
                  <NavLink to="/create-account" onClick={() => hideNavigation()}>
                    Creează cont
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/login" onClick={() => hideNavigation()}>
                    Login
                  </NavLink>
                </li>
              </>
            )}
            {/* <li className="nav-item mr-2 button-like-design">
              <NavLink to="/vote-problem-categories" onClick={() => hideNavigation()}>
                Votează
              </NavLink>
            </li> */}
            <li className="nav-item mr-2 button-like-design mb-1">
              <NavLink to="/solutionare" onClick={() => hideNavigation()}>
                Soluționare
              </NavLink>
            </li>
            {isUserAuthorized && props.userData && (
              <div className={`dropdown ${showDropdownRight && 'show'}`} onClick={() => onClickShowDropdownRight()}>
                <div className="avatar-circle">
                  <span className="initials">
                    {props.userData.firstName.charAt(0)}
                    {props.userData.lastName.charAt(0)}
                  </span>
                </div>
                <div
                  className={`dropdown-menu dropdown-menu-right ${showDropdownRight && 'show'}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  <NavLink to="/my-profile">
                    <span className="dropdown-item">Profil</span>
                  </NavLink>
                  {loginType === 'googleLogin' ? (
                    <GoogleLogout
                      clientId={config.googleId}
                      buttonText="Logout"
                      onLogoutSuccess={googleLogoutSuccess}
                      render={(renderProps) => (
                        <span onClick={renderProps.onClick} disabled={renderProps.disabled}>
                          <span className="dropdown-item">Logout</span>
                        </span>
                      )}
                    ></GoogleLogout>
                  ) : (
                    <NavLink to="/logout">
                      <span className="dropdown-item">Logout</span>
                    </NavLink>
                  )}
                </div>
              </div>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default withRouter(Header);
