export const renderTags = (id) => {
  switch (id.toString()) {
    case '1':
      return 'spatii';
    case '2':
      return 'mobilitate';
    case '3':
      return 'educatie';
    case '4':
      return 'sanatate';
    case '5':
      return 'guvernare';
    case '6':
      return 'ordine';
    case '7':
      return 'cultura';

    default:
      return '';
  }
};
