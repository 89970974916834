import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import { CollapseArrow, EllapseArrow } from '../../../assets/images/images';
import { isUserLoggedIn } from '../../../components/User/UserUtils';
import { getProblemsToVote, voteCategory, getProblemDetailsVote } from '../../../models/actions/problemDetailsVote';
import {
  SpatiiPubliceLogo,
  MobilitateLogo,
  EducatieLogo,
  SanatateLogo,
  GuvernareLogo,
  OrdineSiSigurantaLogo,
  CulturaLogo,
} from '../../../assets/images/images';

import './ProblemDetailsVote.scss';

class ProblemDetailsVote extends Component {
  constructor() {
    super();
    this.state = {
      category: '',
      ellapseId: '',
      ellapse: false,
      alreadyVotedOnThisCategory: false,
      showVotedPopup: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const category = window.location.href.split('/')[4];
    this.setState({
      category,
    });

    this.props.getProblemsToVote();
    this.props.getProblemDetailsVote();
  }

  componentDidUpdate(prevProps) {
    if (this.props.successfullyVoted !== prevProps.successfullyVoted) {
      this.props.getProblemsToVote();
    }
  }

  returnTagIdFromCategory(category) {
    switch (category) {
      case 'spatii-publice':
        return 1;
      case 'educatie':
        return 3;
      case 'sanatate-si-mediu':
        return 4;
      case 'buna-guvernare-si-digitalizare':
        return 5;
      case 'ordine-si-siguranta-publica':
        return 6;
      case 'cultura':
        return 7;
      case 'mobilitate-accesibilitate-si-siguranta-circulatiei':
        return 2;
      default:
        return '';
    }
  }

  redirectToProblemDetails(url) {
    window.open(url, '_blank');
  }

  switchCollapse(i) {
    this.setState({
      ellapseId: this.state.ellapseId === i ? '' : i,
      ellapse: !this.state.ellapse,
    });
  }

  disableAllButtonsBecauseAlreadyVotedThisCategory() {
    if (!this.state.alreadyVotedOnThisCategory) {
      this.setState({
        alreadyVotedOnThisCategory: true,
      });
    }
  }

  voteCategory(categoryId) {
    this.props.voteCategory(categoryId);
    setTimeout(() => {
      this.props.getProblemDetailsVote();
    }, 500);
    this.setState({
      showVotedPopup: true,
    });
    setTimeout(() => {
      this.setState({
        showVotedPopup: false,
      });
    }, 3000);
  }

  redirectToProblemDetailsVotePage(category) {
    window.open(`/vote/${category}`, '_self');
  }

  render() {
    const isUserAuthorized = isUserLoggedIn();
    const { category, ellapseId, alreadyVotedOnThisCategory, showVotedPopup } = this.state;
    const { errorVoting } = this.props;
    const { problemsToVoteByTag } = this.props.problemsToVote;
    const categoryId = this.returnTagIdFromCategory(category) - 1;
    const problemsToVote = problemsToVoteByTag && problemsToVoteByTag[categoryId];
    const { voteStatsPerCategory } = this.props.problemDetailsVote;

    return (
      <div className="problem-details-vote container mt-5 pt-md-5 pt-0">
        <div className="row mb-5">
          <div className="col-md-8 offset-md-2 offset-sm-0 col-sm-12 text-center">
            <h1>
              <b>Rezultate Vot</b>
            </h1>
            <br />
            {category === 'spatii-publice' && <p className={`tag spatii`}>Spații publice</p>}
            {category === 'educatie' && <p className={`tag educatie`}>Educație</p>}
            {category === 'sanatate-si-mediu' && <p className={`tag sanatate`}>Sănătate și mediu</p>}
            {category === 'buna-guvernare-si-digitalizare' && (
              <p className={`tag guvernare`}>Bună guvernare și digitalizare</p>
            )}
            {category === 'ordine-si-siguranta-publica' && <p className={`tag ordine`}>Ordine și siguranță publică</p>}
            {category === 'cultura' && <p className={`tag cultura`}>Cultură</p>}
            {category === 'mobilitate-accesibilitate-si-siguranta-circulatiei' && (
              <p className="tag mobilitate">Mobilitate, accesibilitate și siguranța circulației</p>
            )}
          </div>
        </div>
        {problemsToVote &&
          problemsToVote.problemsToVote.map((problem, i) => (
            <div className="row" key={problem.problemToVoteId}>
              <div className="col-md-8 offset-md-2 offset-sm-0 col-sm-12 problem mb-3">
                <div className="left-side float-left">
                  <h4>
                    <b>{problem.title}</b>
                  </h4>
                  <br />
                  <p>{problem.description}</p>
                </div>
                <div className="right-side center float-right">
                  <div className="existing-votes">
                    <p>
                      <b>{problem.voteCount}</b>
                      <br />
                      <span>voturi</span>
                    </p>
                  </div>
                </div>
                <div className="extra-problems">
                  <p className="extra-problems-title" onClick={() => this.switchCollapse(i)}>
                    {problem.linkedProblems && problem.linkedProblems.length} probleme incluse{' '}
                    <span>
                      {problem.linkedProblems && problem.linkedProblems.length !== 0 && ellapseId === i ? (
                        <EllapseArrow />
                      ) : (
                        <CollapseArrow />
                      )}
                    </span>
                  </p>
                  {ellapseId === i && (
                    <ul>
                      {problem.linkedProblems.map((linkedProblem) => (
                        <li
                          key={linkedProblem.creationDate}
                          onClick={() => this.redirectToProblemDetails(linkedProblem.url)}
                        >
                          {linkedProblem.title} &middot;{' '}
                          <span> {moment(linkedProblem.creationDate).format('MMM DD')} </span> &middot;{' '}
                          <span> {linkedProblem.proposerDisplayName} </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          ))}
        <div className="row my-5">
          <div className="col-md-4 offset-md-4">
            <hr />
          </div>
        </div>
        <div className="row center">
          <div className="col-md-12">
            <h1>
              <b>Explorează și celelalte domenii</b>
            </h1>
            <br />
          </div>
        </div>
        <div className="row my-5 problem-cards">
          {voteStatsPerCategory &&
            voteStatsPerCategory.map((category, i) => (
              <div
                className={`${
                  voteStatsPerCategory.length - 1 !== i
                    ? 'col-md-4 col-sm-12 selectable-card mb-5 p-4'
                    : 'offset-md-4 col-md-4 col-sm-12 selectable-card mb-5 px-5 mb-5'
                }`}
                key={category.problemTagId}
                onClick={() =>
                  this.redirectToProblemDetailsVotePage(
                    category.problemTagId === 1
                      ? 'spatii-publice'
                      : category.problemTagId === 2
                      ? 'mobilitate-accesibilitate-si-siguranta-circulatiei'
                      : category.problemTagId === 3
                      ? 'educatie'
                      : category.problemTagId === 4
                      ? 'sanatate-si-mediu'
                      : category.problemTagId === 5
                      ? 'buna-guvernare-si-digitalizare'
                      : category.problemTagId === 6
                      ? 'ordine-si-siguranta-publica'
                      : 'cultura',
                  )
                }
              >
                <div className="left-side float-left">
                  <p>
                    <span>
                      <b>{category.voteCount}</b>
                    </span>{' '}
                    voturi în total
                  </p>
                  <p className="font-s-13 smaller-font">
                    <span>
                      <b>{category.problemsToVoteCount}</b>
                    </span>{' '}
                    teme pentru vot
                  </p>
                  {category.problemTagId === 1 && <p className="tag spatii mt-3">Spații publice</p>}
                  {category.problemTagId === 2 && (
                    <p className="tag mobilitate mt-3">Mobilitate, accesibilitate și siguranța circulației</p>
                  )}
                  {category.problemTagId === 3 && <p className="tag educatie mt-3">Educație</p>}
                  {category.problemTagId === 4 && <p className="tag sanatate mt-3">Sănătate și mediu</p>}
                  {category.problemTagId === 5 && <p className="tag guvernare mt-3">Bună guvernare și digitalizare</p>}
                  {category.problemTagId === 6 && <p className="tag ordine mt-3">Ordine și siguranță publică</p>}
                  {category.problemTagId === 7 && <p className="tag cultura mt-3">Cultură</p>}
                </div>
                <div className="right-side">
                  {category.problemTagId === 1 ? (
                    <SpatiiPubliceLogo />
                  ) : category.problemTagId === 2 ? (
                    <MobilitateLogo />
                  ) : category.problemTagId === 3 ? (
                    <EducatieLogo />
                  ) : category.problemTagId === 4 ? (
                    <SanatateLogo />
                  ) : category.problemTagId === 5 ? (
                    <GuvernareLogo />
                  ) : category.problemTagId === 6 ? (
                    <OrdineSiSigurantaLogo />
                  ) : (
                    <CulturaLogo />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPendingProblemsToVote: state.problemDetailsVote.isPendingProblemsToVote,
    problemsToVote: state.problemDetailsVote.problemsToVote,
    errorProblemsToVote: state.problemDetailsVote.errorProblemsToVote,
    successfullyVoted: state.problemDetailsVote.successfullyVoted,
    errorVoting: state.problemDetailsVote.errorVoting,
    problemDetailsVote: state.problemDetailsVote.problemDetailsVote,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProblemsToVote: () => dispatch(getProblemsToVote()),
  voteCategory: (categoryId) => dispatch(voteCategory(categoryId)),
  getProblemDetailsVote: (problemId) => dispatch(getProblemDetailsVote(problemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemDetailsVote);
