import { api } from '../api/api';
import {
  GET_PROBLEM_DETAILS_LOADING,
  GET_PROBLEM_DETAILS_SUCCESS,
  GET_PROBLEM_DETAILS_ERROR,
  GET_COMMENTS_PROBLEM_DETAILS_LOADING,
  GET_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  GET_COMMENTS_PROBLEM_DETAILS_ERROR,
  LIKE_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  POST_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  EDIT_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  DELETE_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  POST_FOLLOW_ERROR,
  POST_FOLLOW_SUCCESS,
  DELETE_FOLLOW_ERROR,
  DELETE_FOLLOW_SUCCESS,
} from '../constants';

export function getProblemDetailsLoggedIn(problemId) {
  return (dispatch) => {
    dispatch(getProblemDetailsPending(true));
    api
      .get(`account`)
      .then((account) => {
        api
          .get(`problem/${problemId}`)
          .then((response) => {
            const problemDetails = {
              ...response,
              account,
            };
            dispatch(getProblemDetailsPending(false));
            dispatch(getProblemDetailsSuccess(problemDetails));
          })
          .catch((error) => {
            dispatch(getProblemDetailsPending(false));
            dispatch(getProblemDetailsError(error));
          });
      })
      .catch((error) => {
        console.log(error);
        api
          .get(`problem/${problemId}`)
          .then((response) => {
            dispatch(getProblemDetailsPending(false));
            dispatch(getProblemDetailsSuccess(response));
          })
          .catch((error) => {
            dispatch(getProblemDetailsPending(false));
            dispatch(getProblemDetailsError(error));
          });
      });
  };
}

export function getProblemDetailsNotLoggedIn(problemId) {
  return (dispatch) => {
    dispatch(getProblemDetailsPending(true));
    api
      .get(`problem/${problemId}`)
      .then((response) => {
        dispatch(getProblemDetailsPending(false));
        dispatch(getProblemDetailsSuccess(response));
      })
      .catch((error) => {
        dispatch(getProblemDetailsPending(false));
        dispatch(getProblemDetailsError(error));
      });
  };
}

export function getCommentsForProblemDetails(problemId) {
  return (dispatch) => {
    dispatch(getCommentsForProblemDetailsPending(true));
    api
      .get(`comment?problemId=${problemId}`)
      .then((response) => {
        dispatch(getCommentsForProblemDetailsPending(false));
        dispatch(getCommentsForProblemDetailsSuccess(response));
      })
      .catch((error) => {
        dispatch(getCommentsForProblemDetailsPending(false));
        dispatch(getCommentsForProblemDetailsError(error));
      });
  };
}

export function likeCommentsForProblemDetails(commentId) {
  return (dispatch) => {
    dispatch(likeCommentsForProblemDetailsSuccess(false));
    api
      .post(`commentLike?commentId=${commentId}`)
      .then((response) => {
        dispatch(likeCommentsForProblemDetailsSuccess(true));
      })
      .catch((error) => {
        dispatch(likeCommentsForProblemDetailsSuccess(false));
      });
  };
}

export function deleteLikeCommentsForProblemDetails(commentId) {
  return (dispatch) => {
    dispatch(likeCommentsForProblemDetailsSuccess(false));
    api
      .delete(`commentLike?commentId=${commentId}`)
      .then((response) => {
        dispatch(likeCommentsForProblemDetailsSuccess(true));
      })
      .catch((error) => {
        dispatch(likeCommentsForProblemDetailsSuccess(false));
      });
  };
}

export function postCommentsForProblemDetails(problemId, parentCommentId, commentText) {
  return (dispatch) => {
    if (commentText !== '') {
      dispatch(postCommentsForProblemDetailsSuccess(false));
      api
        .post(`comment?problemId=${problemId}&parentCommentId=${parentCommentId}`, { text: commentText })
        .then((response) => {
          dispatch(postCommentsForProblemDetailsSuccess(true));
        })
        .catch((error) => {
          dispatch(postCommentsForProblemDetailsSuccess(false));
        });
    }
  };
}

export function deleteCommentsForProblemDetails(commentId) {
  return (dispatch) => {
    dispatch(deleteCommentsForProblemDetailsSuccess(false));
    api
      .delete(`comment/${commentId}`)
      .then((response) => {
        dispatch(deleteCommentsForProblemDetailsSuccess(true));
      })
      .catch((error) => {
        dispatch(deleteCommentsForProblemDetailsSuccess(false));
      });
  };
}

export function editCommentsForProblemDetails(commentId, commentText) {
  return (dispatch) => {
    if (commentText !== '') {
      dispatch(editCommentsForProblemDetailsSuccess(false));
      api
        .put(`comment/${commentId}`, { text: commentText })
        .then((response) => {
          dispatch(editCommentsForProblemDetailsSuccess(true));
        })
        .catch((error) => {
          dispatch(editCommentsForProblemDetailsSuccess(false));
        });
    }
  };
}

export function followAction(problemId) {
  return (dispatch) => {
    api
      .post(`problem/follow/${problemId}`)
      .then((response) => {
        dispatch(postFollowSuccess());
      })
      .catch((error) => {
        dispatch(postFollowError());
      });
  };
}

export function deleteFollowAction(problemId) {
  return (dispatch) => {
    api
      .delete(`problem/follow/${problemId}`)
      .then((response) => {
        dispatch(deleteFollowSuccess());
      })
      .catch((error) => {
        dispatch(deleteFollowError());
      });
  };
}

const getProblemDetailsPending = (status) => ({
  type: GET_PROBLEM_DETAILS_LOADING,
  isPending: status,
});

const getProblemDetailsSuccess = (response) => ({
  type: GET_PROBLEM_DETAILS_SUCCESS,
  payload: response,
});

const getProblemDetailsError = (error) => ({
  type: GET_PROBLEM_DETAILS_ERROR,
  error,
});

const getCommentsForProblemDetailsPending = (status) => ({
  type: GET_COMMENTS_PROBLEM_DETAILS_LOADING,
  isPendingComments: status,
});

const getCommentsForProblemDetailsSuccess = (comments) => ({
  type: GET_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  comments,
});

const getCommentsForProblemDetailsError = (error) => ({
  type: GET_COMMENTS_PROBLEM_DETAILS_ERROR,
  error,
});

const likeCommentsForProblemDetailsSuccess = (status) => ({
  type: LIKE_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  likeSuccess: status,
});

const postCommentsForProblemDetailsSuccess = (status) => ({
  type: POST_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  postCommentSuccess: status,
});

const editCommentsForProblemDetailsSuccess = (status) => ({
  type: EDIT_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  editCommentSuccess: status,
});

const deleteCommentsForProblemDetailsSuccess = (status) => ({
  type: DELETE_COMMENTS_PROBLEM_DETAILS_SUCCESS,
  deleteCommentSuccess: status,
});

const postFollowError = () => ({
  type: POST_FOLLOW_ERROR,
});

const postFollowSuccess = () => ({
  type: POST_FOLLOW_SUCCESS,
});

const deleteFollowError = () => ({
  type: DELETE_FOLLOW_ERROR,
});

const deleteFollowSuccess = () => ({
  type: DELETE_FOLLOW_SUCCESS,
});
